export const SET_IS_PRIVACY_ACCEPTED = 'vmmv-ui/privacy-policy/SET_IS_PRIVACY_ACCEPTED'

export interface SetIsPrivacyAccepted {
  type: typeof SET_IS_PRIVACY_ACCEPTED
}

export const setIsPrivacyAccepted = (): SetIsPrivacyAccepted => {
  return {
    type: SET_IS_PRIVACY_ACCEPTED,
  }
}

export type PrivacyPolicyActionTypes = SetIsPrivacyAccepted
