export const TOGGLE_WISHLIST = 'vmmv-ui/wishlist/TOGGLE_WISHLIST'
export const SET_WISHLIST = 'vmmv-ui/wishlist/SET_WISHLIST'

export interface SetWishlist {
  type: typeof SET_WISHLIST
  payload: { wishlist: string[] }
}

export interface ToggleWishlist {
  type: typeof TOGGLE_WISHLIST
  payload: { upc: string }
}

export const setWishlist = (wishlist: string[]): SetWishlist => {
  return {
    type: SET_WISHLIST,
    payload: {
      wishlist,
    },
  }
}

export const toggleWishlist = (upc: string): ToggleWishlist => {
  return {
    type: TOGGLE_WISHLIST,
    payload: {
      upc,
    },
  }
}

export type WishlistActionTypes = ToggleWishlist | SetWishlist
