import { trackErrorComponentData } from './componentTracking'
import { trackClientError, trackUserError, trackServerError, sources } from './helpers'

//* Virtual Mirror
// onPrivacyFail
export const trackVmPrivacyFail = () => {
  const code = 'Vm - Fail'
  const details = 'Privacy process failed due to network errors'
  trackClientError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.Client, code, details)
}

// onPrivacyReject
export const trackVmPrivacyReject = () => {
  const code = 'Vm - Reject'
  const details = 'Biometric data consent rejected'
  trackUserError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.User, code, details)
}

// onInitializeError - Client
export const trackVmInitClientError = () => {
  const code = 'Vm - Initialize'
  const details = 'Smth went wrong'
  trackClientError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.Client, code, details)
}

// onWebcamUnavailable
export const trackVmWebcamUnavailable = () => {
  const code = 'Vm - Webcam'
  const details = 'Webcam not found'
  trackUserError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.User, code, details)
}
// onWebcamDenied
export const trackVmWebcamUnaccessible = () => {
  const code = 'Vm - Webcam'
  const details = 'Webcam access was rejected by user'
  trackUserError({
    Error_Code: 'Vm - Webcam',
    Error_Details: 'webcam access was rejected by user',
  })
  trackErrorComponentData(sources.User, code, details)
}
// onMirrorWasmFailure
export const trackVmWasmUnsupported = () => {
  const code = 'Vm - Render'
  const details = 'Smth went wrong'
  trackClientError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.Client, code, details)
}

//* Picture mode
// on Upload fail
export const trackPicktureUploadFail = () => {
  const code = 'Vm - PicUpload'
  const details = 'Failed to upload picture'
  trackServerError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.Server, code, details)
}

// on taken picture fail
export const trackPicktureTakeFail = () => {
  const code = 'Vm - Picture'
  const details = 'Failed taking a picture'
  trackServerError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.Server, code, details)
}

//* Video mode
// on taken video fail
export const trackVideoTakeFail = () => {
  const code = 'Vm - Video'
  const details = 'Failed taking a video'
  trackServerError({
    Error_Code: code,
    Error_Details: details,
  })
  trackErrorComponentData(sources.Server, code, details)
}

//* Generic error
export const trackGenericError = (code: string, message: string) => {
  trackClientError({
    Error_Code: code,
    Error_Details: message,
  })
  trackErrorComponentData(sources.Client, code, message)
}
