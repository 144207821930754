import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { Button } from '../core/Button'
import React from 'react'
import { setVisibilityTermsConditionsModal } from '@store/actions/ui'
import { useTranslation } from 'react-i18next'
import { ButtonsWrapper, StyledContent } from './styles'
import { PrivacyPolicyWrapper } from './PrivacyPolicyWrapper'

export const TermsConditionsModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTermsConditionsVisible = useSelector((s: AppState) => s.ui.isTermsConditionsVisible)

  if (!isTermsConditionsVisible) {
    return null
  }

  const renderCtaContent = () => (
    <ButtonsWrapper>
      <Button
        onClick={() => {
          dispatch(setVisibilityTermsConditionsModal(false))
        }}
      >
        {t('RETURN')}
      </Button>
    </ButtonsWrapper>
  )

  return (
    <PrivacyPolicyWrapper
      title={t('vm-master-version.termsOfUseTitle')}
      ctaContent={renderCtaContent()}
      content={<StyledContent>{t('vm-master-version.termsOfUse')}</StyledContent>}
      className="vmmv-terms-conditions"
    />
  )
}
