import { ProductPrice, Product } from '@customTypes/product'
import {
  liveTryOn,
  pictureModeView,
  pictureMode,
  videoMode,
  videoModeView,
  uploadInstructions,
} from '@utils/routes'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { trackClickAddToCart, trackClickAddToWishlist, trackClickShareSocial } from './clicks'
import {
  trackTakePhoto,
  trackSharePhoto,
  trackAddToWishlist,
  trackFitGlasses,
  trackAddToCart,
} from './events'
import { takeMode } from './helpers'
import { trackInitVM } from './impressions'

const pathToTakeMode = {
  [liveTryOn()]: takeMode.vto,
  [pictureMode(pictureModeView())]: takeMode.picture,
  [videoMode(videoModeView())]: takeMode.video,
  [pictureMode(uploadInstructions())]: takeMode.pictureUpload,
}

type State = {
  from?: string
}

const useTakeMode = () => {
  const location = useLocation()
  const from = (location?.state as State)?.from
  const path = from ? from : location.pathname
  const mode = pathToTakeMode[path]

  return useMemo(() => ({ takeMode: mode }), [mode])
}

export const useFromPage = () => {
  const location = useLocation()
  const from = (location?.state as State)?.from

  return from
}

export const useAddToCart = (product: Product, price?: ProductPrice) => {
  const { takeMode } = useTakeMode()
  return useMemo(
    () => () => {
      trackClickAddToCart()
      trackAddToCart(product, takeMode, price)
    },
    [product, price, takeMode]
  )
}

export const useAddToWishlist = (product: Product) => {
  const { takeMode } = useTakeMode()
  return useMemo(
    () => () => {
      trackClickAddToWishlist()
      trackAddToWishlist(product, takeMode)
    },
    [product, takeMode]
  )
}

export const useTakePhoto = (product: Product) => {
  const { takeMode } = useTakeMode()
  return useMemo(
    () => () => {
      trackTakePhoto(product, takeMode)
    },
    [product, takeMode]
  )
}

export const useSharePhoto = (product: Product) => {
  const { takeMode } = useTakeMode()
  return useMemo(
    () => () => {
      trackClickShareSocial()
      trackSharePhoto(product, takeMode)
    },
    [product, takeMode]
  )
}

export const useFitGlasses = (product: Product) => {
  const { takeMode } = useTakeMode()
  return useMemo(
    () => () => {
      trackFitGlasses(product, takeMode)
    },
    [product, takeMode]
  )
}

export const useTrackInitVM = (products: Product[]) => {
  const { takeMode } = useTakeMode()
  return useMemo(
    () => () => {
      trackInitVM(products, takeMode)
    },
    [products, takeMode]
  )
}
