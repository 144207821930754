export class ApiError extends Error {
  code: string | undefined
  description: string | undefined
  constructor(message: string, code?: string, description?: string) {
    super(message)
    this.code = code
    this.description = description
  }
}

export default class ApiService {
  private apiUrl: string | undefined
  private headers: HeadersInit = {
    'Content-Type': 'application/json',
    accept: 'application/json',
  }
  private error: typeof ApiError

  constructor(apiUrl: string, headers?: HeadersInit, error = ApiError) {
    this.apiUrl = apiUrl
    this.headers = { ...this.headers, ...headers }
    this.error = error
  }

  private _retrieveData = async <I, O>(
    url: string,
    method = 'get',
    data?: I,
    headers?: HeadersInit
  ): Promise<O> => {
    const body = JSON.stringify(data)
    const res = await fetch(url, {
      method,
      headers: { ...this.headers, ...headers },
      body,
    })
    if (!res.ok) {
      const body = await res.text()
      throw new this.error(
        `ApiError: Error on retrieving data ${res.statusText}`,
        res.status.toString(),
        body
      )
    }
    try {
      return await res.json()
    } catch {
      const body = await res.text()
      throw new this.error('ApiError: Failed during JSON parsing', res.status.toString(), body)
    }
  }

  public post = <T, I>(path: string, data: I, headers = {}) => {
    const url = `${this.apiUrl}${path}`
    return this._retrieveData<unknown, T>(url, 'post', data, headers)
  }

  public get = <T>(path: string, headers?: HeadersInit) => {
    const url = `${this.apiUrl}${path}`
    return this._retrieveData<unknown, T>(url, 'get', undefined, headers)
  }
}
