import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const DrawerContainer = styled.div`
  z-index: 4;
  position: absolute;
  top: ${pxToEm(20)};
  bottom: 0;
  left: 0;
  min-height: calc(100% - ${pxToEm(15)});
  height: auto;
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white[100]};
  transition: transform 300ms ease-in-out;
  border-top-left-radius: ${pxToEm(15)};
  border-top-right-radius: ${pxToEm(15)};

  &,
  &.visible-enter {
    transform: translateY(100%);
  }

  &.visible-enter-active,
  &.visible-enter-done {
    transform: translateY(0%);
  }

  ${({ theme }) => theme.media.large} {
    width: ${pxToEm(768)};
    left: calc(50% - ${pxToEm(384)});
  }
`
export const DrawerOverlay = styled.div<{ showOverlay: boolean }>`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.25);
  transition: 0.3s opacity ease-in-out;
  font-size: 16px;

  &,
  &.visible-enter {
    z-index: ${({ showOverlay }) => (showOverlay ? 1 : -1)};
    opacity: ${({ showOverlay }) => (showOverlay ? 1 : 0)};
    width: ${({ showOverlay }) => (showOverlay ? '100%' : 0)};
    height: ${({ showOverlay }) => (showOverlay ? '100%' : 0)};
    min-height: ${({ showOverlay }) => (showOverlay ? '100%' : 'none')};

    ${({ theme }) => theme.media.large} {
      width: ${({ showOverlay }) => (showOverlay ? pxToEm(768) : 0)};
    }
  }

  &.visible-enter-active,
  &.visible-enter-done {
    opacity: 1;
  }

  ${({ theme }) => theme.media.large} {
    width: ${pxToEm(768)};
    left: calc(50% - ${pxToEm(384)});
  }
`
