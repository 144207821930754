import { Screenshot } from '@customTypes/screenshot'
import { useState } from 'react'

export const useScreenshot = () => {
  const [image, setImage] = useState<Screenshot>()

  return {
    image,
    setImage,
  }
}
