import { IconProps } from '@customTypes/icons'
import React from 'react'

export const WishlistIcon: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = 'none',
  stroke = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="2 0 30 30"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#zjcqv0x0ea)">
        <path
          d="M26.196 7.773A6.194 6.194 0 0 0 21.842 6a6.194 6.194 0 0 0-4.354 1.773L17 8.24l-.488-.467A6.194 6.194 0 0 0 12.158 6a6.194 6.194 0 0 0-4.354 1.773 5.98 5.98 0 0 0 0 8.56l.488.48 8.06 7.923c.18.176.413.264.648.264a.92.92 0 0 0 .648-.264l8.06-7.923.488-.48a5.98 5.98 0 0 0 0-8.56zm-.648 7.923-.488.48L17 24.099l-8.06-7.923-.488-.48a5.074 5.074 0 0 1-1.535-3.643c0-1.376.545-2.67 1.535-3.643A5.252 5.252 0 0 1 12.158 6.9c1.4 0 2.716.536 3.715 1.517l.488.468.639.612.64-.612.496-.476A5.252 5.252 0 0 1 21.842 6.9c1.4 0 2.716.536 3.706 1.509a5.074 5.074 0 0 1 1.535 3.643c0 1.376-.545 2.67-1.535 3.643z"
          fill={fill}
        />
        <path
          d="M26.196 7.773A6.194 6.194 0 0 0 21.842 6a6.194 6.194 0 0 0-4.354 1.773L17 8.24l-.488-.467A6.194 6.194 0 0 0 12.158 6a6.194 6.194 0 0 0-4.354 1.773 5.98 5.98 0 0 0 0 8.56l.488.48 8.06 7.923c.18.176.413.264.648.264a.92.92 0 0 0 .648-.264l8.06-7.923.488-.48a5.98 5.98 0 0 0 0-8.56zm-.648 7.923-.488.48L17 24.099l-8.06-7.923-.488-.48a5.074 5.074 0 0 1-1.535-3.643c0-1.376.545-2.67 1.535-3.643A5.252 5.252 0 0 1 12.158 6.9c1.4 0 2.716.536 3.715 1.517l.488.468.639.612.64-.612.496-.476A5.252 5.252 0 0 1 21.842 6.9c1.4 0 2.716.536 3.706 1.509a5.074 5.074 0 0 1 1.535 3.643c0 1.376-.545 2.67-1.535 3.643z"
          stroke={stroke}
          strokeWidth=".7"
        />
      </g>
      <defs>
        <filter
          id="zjcqv0x0ea"
          x=".66"
          y=".66"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_505" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_505" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
