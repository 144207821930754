import { pxToEm } from '@libs/styled'
import styled, { keyframes } from 'styled-components'

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 30%;
`

export const LoaderContent = styled.div`
  color: ${({ theme }) => theme.palette.black};
  display: inline-block;
  position: relative;
  visibility: visible;
  width: ${pxToEm(200)};
  height: ${pxToEm(200)};
  z-index: 10;
  transform: translate(${pxToEm(70)});

  @keyframes motion {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  div {
    transform-origin: ${pxToEm(30)} ${pxToEm(100)};
    animation: motion 15.1s linear infinite;
  }

  div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: ${pxToEm(3)};
    left: ${pxToEm(29)};
    width: ${pxToEm(1)};
    height: ${pxToEm(25)};
    border-radius: 20%;
    background: ${({ theme }) => theme.palette.black};
  }
`

export const Dash = styled.div<{ position: number }>`
  &.vmmv-loader-dash {
    animation-delay: ${({ position }) => `calc(-15s + (${position}*0.1s))`};
    transform: ${({ position }) => `rotate(calc(${position} * 2.416deg))`};
  }
`

export const LoadingText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: ${({ theme }) => theme.fonts.heading};
  font-size: ${pxToEm(20)};
  text-align: center;
  z-index: 10;
`

// from - twitter bootstrap with love.
// https://getbootstrap.com/docs/4.4/components/spinners/#growing-spinner
const spinnerGrow = keyframes`
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
`

export const Spin = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  opacity: 0;
  vertical-align: text-bottom;
  background-color: ${({ theme }) => theme.palette.black};
  animation: ${spinnerGrow} 1.5s linear infinite;
`
