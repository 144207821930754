import { combineReducers } from 'redux'
import ui from './ui'
import animationApp from './animationApp'
import privacyPolicy from './privacyPolicy'
import products from './products'
import wishlist from './wishlist'

const rootReducer = combineReducers({
  ui,
  animationApp,
  privacyPolicy,
  products,
  wishlist,
})

export default rootReducer
