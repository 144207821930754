import { DrawerContainer, DrawerOverlay } from './styles'

import { CSSTransition } from 'react-transition-group'
import React from 'react'
import ReactDOM from 'react-dom'
import { usePortalDiv } from '@hooks/usePortalDiv'

export interface DrawerProps {
  visible?: boolean
  onOverlayClick?: () => void
  className?: string
  unmountOnExit?: boolean
  showOverlay?: boolean
  children: React.ReactNode
}

export const DrawerBaseContainer: React.FC<DrawerProps> = ({
  children,
  visible = false,
  onOverlayClick,
  className,
  unmountOnExit = true,
  showOverlay = true,
}) => {
  return (
    <>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        classNames="visible"
        in={visible}
        appear={visible}
        timeout={300}
      >
        <DrawerOverlay
          showOverlay={showOverlay}
          onClick={() => {
            if (onOverlayClick) {
              onOverlayClick()
            }
          }}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        classNames="visible"
        in={visible}
        appear={visible}
        timeout={300}
      >
        <DrawerContainer className={className}>{children}</DrawerContainer>
      </CSSTransition>
    </>
  )
}

const drawerClassName = 'drawer__container'

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const divEl = usePortalDiv(drawerClassName)

  if (!divEl) return null

  return ReactDOM.createPortal(
    <DrawerBaseContainer {...props}>{children}</DrawerBaseContainer>,
    divEl
  )
}
