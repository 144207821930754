import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledContent = styled.p`
  flex-shrink: 1;
  min-height: 0;
  overflow-y: auto;
  white-space: pre-line;
  text-align: left;
  table {
    width: 100%;
    td,
    th {
      border: 1px solid ${({ theme }) => theme.palette.black};
      margin: 0;
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    tr {
      margin: 0;
    }
  }
`
