import { CatalogContainer, CatalogItemWrapper } from '../styles'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import { SwiperOptions } from 'swiper'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  StyledCatalogItem,
  StyledCatalogItemInner,
  StyledLensDescriptionWrapper,
  CarouselWrapperStyled,
} from './styles'
import { getTransitionLensLabel } from '@libs/transition'
import { trackClickLensCarousel } from '@libs/analytics'

const TIME_TO_HIDE_TOOLTIP = 5000

const swiperParams: SwiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 5,
  centeredSlides: true,
  slideToClickedSlide: true, // see: LVM-283
}

interface LensCatalogProps {
  lenses: string[]
  selectedLens: string
  onChangeLens: (lens: string) => void
}

// description field from translations
const LENSES_MAP = [
  { type: 'SIGNATURE', description: 'signature-gen8-description' },
  { type: 'XTRACTIVE', description: 'xtractive-description' },
  { type: 'VANTAGE', description: 'xtractive-polarized-description' },
]

const formatTranslationsByMap = (
  obj: Record<string, string>,
  map = LENSES_MAP
): { type: string; description: string }[] => {
  return map.map(el => ({
    ...el,
    description: obj[el.description],
  }))
}

export const LensCatalog: FC<LensCatalogProps> = ({ lenses, selectedLens, onChangeLens }) => {
  const { t } = useTranslation()

  const lensTypes = formatTranslationsByMap(t('transitions', { returnObjects: true }))

  const selectedIndex = lenses.findIndex(el => el === selectedLens)
  const selectedLensType = lensTypes.find(l => l.type.toLowerCase() === selectedLens.toLowerCase())
  const selectedLensDescription = selectedLensType?.description

  const [currentSlide, setCurrentSlide] = useState(selectedIndex !== -1 ? selectedIndex : 0)
  const [isTooltipVisible, setTooltipVisibility] = useState(true)
  const swiper = useRef<SwiperInstance>(null)

  const setSwiper = (swiperInstance: SwiperInstance) => {
    swiper.current = swiperInstance
  }

  const onLensChange = debounce((color: string) => {
    trackClickLensCarousel()
    onChangeLens(color)
  }, 800)

  const handleClick = useCallback(
    (lens: string) => {
      if (lens === lenses[currentSlide]) {
        setTooltipVisibility(c => !c)
      }
    },
    [lenses, currentSlide]
  )

  useEffect(() => {
    if (selectedLens !== lenses[currentSlide]) {
      onLensChange(lenses[currentSlide])
      setTooltipVisibility(true)
    }
  }, [lenses, selectedLens, currentSlide, onLensChange])

  useEffect(() => {
    if (swiper.current) {
      swiper.current.on('slideChange', () => {
        if (swiper.current) {
          const realIndex = swiper.current.realIndex
          setCurrentSlide(realIndex)
        }
      })
    }
  }, [swiper, lenses])

  useEffect(() => {
    if (isTooltipVisible) {
      const timer = setTimeout(() => {
        setTooltipVisibility(false)
      }, TIME_TO_HIDE_TOOLTIP)
      return () => clearTimeout(timer)
    }
  }, [isTooltipVisible])

  return (
    <CarouselWrapperStyled>
      <CatalogContainer>
        <Swiper {...swiperParams} initialSlide={currentSlide} getSwiper={setSwiper}>
          {lenses.map((lens, index) => (
            <CatalogItemWrapper key={`${lens}-${index}`} onClick={() => handleClick(lens)}>
              <StyledCatalogItemInner>
                <StyledCatalogItem isActive={lens === selectedLens}>
                  {getTransitionLensLabel(lens)}
                </StyledCatalogItem>
              </StyledCatalogItemInner>
            </CatalogItemWrapper>
          ))}
        </Swiper>
        {selectedLensDescription && (
          <StyledLensDescriptionWrapper>{selectedLensDescription}</StyledLensDescriptionWrapper>
        )}
      </CatalogContainer>
    </CarouselWrapperStyled>
  )
}
