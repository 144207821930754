import { IconProps } from '@customTypes/icons'
import React from 'react'
export const CloseIcon: React.FC<IconProps & { filter?: boolean }> = ({
  width = '1em',
  height = '1em',
  fill = '#fff',
  stroke = '#fff',
  filter = true,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_27)">
        <g filter={filter ? 'url(#dlofwdnq9a)' : ''}>
          <path
            d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z"
            fill="black"
            stroke="black"
            strokeWidth="0.8"
          />
          <path
            d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z"
            fill="black"
            stroke="black"
            strokeWidth="0.8"
          />
        </g>
        <path
          d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z"
          fill="white"
          stroke={stroke}
          strokeWidth="0.8"
        />
        <path
          d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z"
          fill="white"
          stroke={stroke}
          strokeWidth="0.8"
        />
      </g>
      <defs>
        <filter
          id="dlofwdnq9a"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_1_27" />
        </filter>
      </defs>
    </svg>
  )
}
