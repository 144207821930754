import { Config } from '@customTypes/config'

const BIPA_REGION_VALUES = ['us', 'na']

export const checkIsRegionUS = (config: Config): boolean => {
  const {
    store: { region },
    store: { countryId },
  } = config

  let where: Array<string> = [region || '', countryId || '']

  if (config.analytics) {
    where = where.concat(config.analytics.storeRegion || '')
  }

  if (where.every(item => item === '')) {
    where = where.concat('us')
  }

  return where.some(current => BIPA_REGION_VALUES.includes(current.toLowerCase()))
}

export const checkIsRegionANZ = (config: Config): boolean => {
  const {
    store: { region },
    store: { countryId },
  } = config

  let where: Array<string> = [region || '', countryId || '']

  if (config.analytics) {
    where = where.concat(config.analytics.storeRegion || '')
  }

  return where.some(current => current.toLowerCase() === 'anz')
}
