import { Product } from '@customTypes/product'
import { trackEventComponentData } from './componentTracking'
import {
  sections,
  trackOnload,
  valueTypes,
  DefaultProduct,
  TakeMode,
  formatAnalyticsProducts,
} from './helpers'

const trackImpressionInitVM = (products: DefaultProduct) => {
  trackOnload({
    Page_Section1: sections.VirtualMirror,
    Page_Section2: 'Render',
    Events_VMRender: valueTypes.Positive,
    Products: products,
  })
}

export const trackInitVM = (products: Product[], takeMode: TakeMode) => {
  const formattedProducts = formatAnalyticsProducts(products)
  trackImpressionInitVM(formattedProducts)
  trackEventComponentData(products, takeMode, 'Render')
}
