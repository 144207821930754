import React from 'react'

export const ArrowIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Livello_1"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 56 45.3"
    >
      <g>
        <g>
          <path
            id="SVGID_5_"
            fill="#ffffff"
            d="M35.8,8.1c-2.1,0.2-4.2,0.5-6.2,1c-2.5,0.6-4.9,1.5-7.2,2.6c-4.6,2.2-8.7,5.2-12.1,8.9
        s-6,8-7.6,12.7c-0.8,2.3-1.4,4.7-1.8,7.1c-0.2,1.3-0.3,2.6-0.4,3.8c0,0.4,0.3,0.7,0.6,0.7h4.8c0.3,0,0.6-0.2,0.6-0.5
        C7,41.5,7.9,38.7,9.2,36c1.7-3.6,4.2-6.8,7.1-9.3c2.9-2.6,6.3-4.5,9.9-5.8c3.1-1.1,6.4-1.6,9.7-1.5v7.8l9.8-6.7l9.8-6.7l-9.8-6.7
        l-9.8-6.7v7.7H35.8z"
          />
        </g>
      </g>
    </svg>
  )
}
