import { CloseIcon, MenuClose, MenuOpen } from '@components/core/Icons'
import { useAnimation } from '@hooks/useAnimationApp'
import { useConfig } from '@providers/configProvider'
import React, { useCallback, useState } from 'react'
import { closeVirtualMirror, closeVto, MenuList } from './MenuList'
import { MenuButton, MenuWrapper } from './styles'
import { Info } from '@components/Info'
import { trackClickButtonClose } from '@libs/analytics'
import { OverlayWrapper } from '@components/core/Overlay'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { setMenuVisibility, setVtoPage } from '@store/actions/ui'
import { CONTAINER_ID_VIDEO_MODE } from '@components/VideoMode/VideoModeView'
import { CONTAINER_ID_PICTURE_MODE } from '@components/PictureMode/PictureModeView'

interface MenuProps {
  hasMirrorRendered?: boolean
  fromStore?: boolean
}

export const Menu: React.FC<MenuProps> = ({ hasMirrorRendered, fromStore }) => {
  const [isInfoVisible, setIsInfoVisible] = useState(false)

  const isMenuVisible = useSelector((s: AppState) => s.ui.isMenuVisible)
  const vtoPage = useSelector((s: AppState) => s.ui.vtoPage)
  const dispatch = useDispatch()

  const { onClose } = useConfig()

  const cbOnEnd = useCallback(() => {
    dispatch(setMenuVisibility(false))
  }, [dispatch])

  const cbOnStart = useCallback(() => {
    dispatch(setMenuVisibility(true))
  }, [dispatch])

  useAnimation(!!hasMirrorRendered, isMenuVisible, cbOnStart, cbOnEnd, 'menu')

  const onMenuBtnClick = useCallback(() => {
    dispatch(setMenuVisibility(!isMenuVisible))
  }, [dispatch, isMenuVisible])

  const onCloseBtnClick = useCallback(() => {
    trackClickButtonClose()

    switch (vtoPage) {
      case 'live-try-on': {
        closeVirtualMirror()
        break
      }
      case 'picture-mode': {
        closeVto(() => {
          return
        }, CONTAINER_ID_PICTURE_MODE)
        break
      }
      case 'video-mode': {
        closeVto(() => {
          return
        }, CONTAINER_ID_VIDEO_MODE)
      }
    }

    dispatch(setVtoPage('off'))

    onClose && onClose()
  }, [dispatch, onClose, vtoPage])

  const toggleInfoVisibility = useCallback(() => {
    setIsInfoVisible(c => !c)
  }, [])

  const onOverlayClick = useCallback(() => {
    if (isMenuVisible || isInfoVisible) {
      setIsInfoVisible(false)
      dispatch(setMenuVisibility(false))
    }
  }, [isMenuVisible, isInfoVisible, dispatch])

  return (
    <MenuWrapper
      className="vmmv-menu-wrapper"
      active={isMenuVisible || isInfoVisible}
      onClick={onOverlayClick}
    >
      <MenuButton onClick={onMenuBtnClick}>
        {isMenuVisible ? <MenuOpen /> : <MenuClose />}
      </MenuButton>

      {fromStore && onClose && (
        <MenuButton onClick={onCloseBtnClick}>
          <CloseIcon width="2em" height="2em" />
        </MenuButton>
      )}
      {!fromStore && <Info isVisible={isInfoVisible} toggleVisibility={toggleInfoVisibility} />}
      {isMenuVisible && <OverlayWrapper />}
      <MenuList visible={isMenuVisible} />
    </MenuWrapper>
  )
}
