import React from 'react'

export const ArrowRightIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3535 1.64648L12.6465 2.35348L21.793 11.5H0V12.5H21.793L12.6465 21.6465L13.3535 22.3535L23.707 12L13.3535 1.64648Z"
        fill="white"
      />
    </svg>
  )
}
