import { ColorValue } from '@customTypes/product'
import { pxToEm } from '@libs/styled'
import styled, { css } from 'styled-components'

export const CatalogContainer = styled.div`
  position: relative;
  .swiper-container {
    width: 100%;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`

export const CatalogItem = styled.div<{ backgroundColor?: ColorValue; isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 70%;
  height: 70%;
  background-color: white;
  overflow: hidden;
  border: ${({ theme, isActive }) => isActive && `0.275em solid ${theme.palette.white[100]}`};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.75')};
  padding: ${pxToEm(4)};
  background-clip: content-box;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-image: linear-gradient(
        to right,
        rgb(${backgroundColor.red}, ${backgroundColor.green}, ${backgroundColor.blue}),
        rgba(${backgroundColor.red}, ${backgroundColor.green}, ${backgroundColor.blue}, 0.4)
      );
    `}
`

export const CatalogItemInner = styled.div`
  width: ${pxToEm(80)};
  height: ${pxToEm(80)};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CatalogItemWrapper = styled.div`
  &.swiper-slide-active {
    padding: 0;
  }
`
export const CatalogInfoContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

export const StyledLabel = styled.span`
  color: white;
  position: absolute;
  width: max-content;
  transition: opacity 0.3s ease;
`

export const StyledLabelColor = styled(StyledLabel)`
  top: -${pxToEm(40)};
  left: 0;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  font: ${({ theme }) => theme.fonts.span};

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
`

export const CarouselWrapper = styled.div`
  position: absolute;
  bottom: ${pxToEm(85)};
  padding-top: ${pxToEm(40)};
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 2;
`
