import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { setModalVisibility } from '@store/actions/ui'

export const useModal = () => {
  const dispatch = useDispatch()

  const isModalVisible = useSelector((s: AppState) => s.ui.isModalVisible)

  const toggleModalVisibility = () => {
    dispatch(setModalVisibility(!isModalVisible))
  }

  return {
    isModalVisible,
    toggleModalVisibility,
  }
}
