import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const GlassesIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.125 8.125c-3.05 0-5.64 1.998-6.536 4.753a3.905 3.905 0 0 0-3.178 0c-.897-2.755-3.486-4.753-6.536-4.753A6.883 6.883 0 0 0 0 15a6.883 6.883 0 0 0 6.875 6.875A6.883 6.883 0 0 0 13.75 15c0-.294-.025-.583-.061-.868a2.584 2.584 0 0 1 2.622 0c-.036.284-.061.573-.061.868a6.883 6.883 0 0 0 6.875 6.875A6.883 6.883 0 0 0 30 15a6.883 6.883 0 0 0-6.875-6.875zm-16.25 12.5A5.631 5.631 0 0 1 1.25 15a5.631 5.631 0 0 1 5.625-5.625A5.631 5.631 0 0 1 12.5 15a5.631 5.631 0 0 1-5.625 5.625zm16.25 0A5.631 5.631 0 0 1 17.5 15a5.631 5.631 0 0 1 5.625-5.625A5.631 5.631 0 0 1 28.75 15a5.631 5.631 0 0 1-5.625 5.625z"
        fill={fill}
      />
    </svg>
  )
}
