import styled from 'styled-components'
import { pxToEm } from '@libs/styled'
import { resetButtonStyles } from '@components/Menu/styles'

export const RightSideActionsWrapper = styled.div`
  position: absolute;
  top: ${pxToEm(70)};
  right: ${pxToEm(10)};
  display: flex;
  flex-direction: column;
  z-index: 1;
`

export const ActionBtn = styled.button`
  ${resetButtonStyles}
  margin-bottom: ${pxToEm(35)}; ;
`
