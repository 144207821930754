import React, { useCallback } from 'react'
import { useDeviceOrientation } from '@hooks/useDeviceOrientation'
import { ArrowIcon, CloseIcon } from '@components/core/Icons'
import { useSetDocumentHeight } from '@hooks/useSetDocumentHeight'
import { useTranslation } from 'react-i18next'
import { isDesktopView } from '@libs/utils'
import {
  ScreenDeviceWrapper,
  ScreenDeviceShapeWrapper,
  ScreenDeviceArrowWrapper,
  ScreenDeviceShapeHorizontal,
  ScreenDeviceShapeVertical,
  ScreenDeviceText,
  IconWrapper,
} from './styles'
import { closeVirtualMirror } from '@components/Menu/MenuList'
import { trackClickButtonClose } from '@libs/analytics'
import { useConfig } from '@providers/configProvider'

const DocHeight: React.FC = () => {
  useSetDocumentHeight()
  return null
}

export const OrientationSreen: React.FC = () => {
  const orientation = useDeviceOrientation()
  const { t } = useTranslation()
  const { onClose } = useConfig()

  const onCloseBtnClick = useCallback(() => {
    onClose && onClose()
    trackClickButtonClose()
    closeVirtualMirror()
  }, [onClose])

  if (!orientation.isLandscape || isDesktopView()) return null

  return (
    <>
      {onClose && (
        <IconWrapper onClick={onCloseBtnClick}>
          <CloseIcon width="1.5em" height="1.5em" stroke="#515c62" />
        </IconWrapper>
      )}
      <ScreenDeviceWrapper className="vmmv-orientation-screen-wrapper">
        <ScreenDeviceShapeWrapper>
          <ScreenDeviceArrowWrapper>
            <ArrowIcon />
          </ScreenDeviceArrowWrapper>
          <ScreenDeviceShapeHorizontal />
          <ScreenDeviceShapeVertical />
        </ScreenDeviceShapeWrapper>
        <ScreenDeviceText>{t('general.turn-your-device')}</ScreenDeviceText>
        <DocHeight />
      </ScreenDeviceWrapper>
    </>
  )
}
