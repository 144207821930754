import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const QRCodeWrapper = styled.div`
  z-index: 20;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`

export const QRCodeModalContent = styled.div`
  z-index: 1;
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.white[100]};
  padding: 0 ${pxToEm(24)};
`

export const CloseBtn = styled.div`
  position: absolute;
  right: 0;
  padding: ${pxToEm(30)};
`

export const QRCodeModalTitle = styled.div`
  font: ${({ theme }) => theme.fonts.qrCodeTitle};
  margin-top: ${pxToEm(88)};
  padding: 0 ${pxToEm(80)};
  text-align: center;
`

export const ProductName = styled.div`
  text-align: center;
  margin: ${pxToEm(24)} 0 ${pxToEm(40)};
  font: ${({ theme }) => theme.fonts.paragraph};
`

export const QRCode = styled.div`
  width: ${pxToEm(160)};
  height: ${pxToEm(160)};
  background: ${({ theme }) => theme.palette.black};
  margin: 0 auto ${pxToEm(60)};
`

export const Instructions = styled.div`
  margin: ${pxToEm(50)} auto 0;
  margin-bottom: ${pxToEm(30)};
  padding: 0 ${pxToEm(24)};
  display: flex;
`

export const InstructionStep = styled.div`
  padding: ${pxToEm(16)} ${pxToEm(24)};
  width: 50%;
  display: flex;

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.palette.silver};
  }
`

export const InstructionStepNumber = styled.div`
  margin-right: ${pxToEm(8)};
  min-width: ${pxToEm(16)};
  width: ${pxToEm(16)};
  height: ${pxToEm(16)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white[100]};
  background-color: ${({ theme }) => theme.palette.black};
  font: ${({ theme }) => theme.fonts.span};
`

export const FittingRoom = styled.div`
  padding: ${pxToEm(24)} ${pxToEm(80)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.silver};

  svg {
    margin-right: ${pxToEm(24)};
  }
`

export const Text = styled.div`
  font: ${({ theme }) => theme.fonts.span};
`
