import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const FaceShapeIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.817 22.683c-1.232-1.232-1.691-6.516-1.692-9.558C28.125 5.888 22.238 0 15 0 7.763 0 1.875 5.888 1.875 13.125c0 3.042-.46 8.326-1.692 9.558L0 22.866v.259A6.883 6.883 0 0 0 6.875 30a6.844 6.844 0 0 0 4.758-1.924 8.741 8.741 0 0 0 6.735 0A6.845 6.845 0 0 0 23.124 30 6.883 6.883 0 0 0 30 23.125v-.259l-.183-.183zM15 27.5a7.424 7.424 0 0 1-6.677-4.127 13.587 13.587 0 0 1-1.2-3.567l-1.376-7.339c3.529-.31 6.087-2.782 7.005-5.086 1.25 1.54 4.265 3.766 11.515 5.007l-1.39 7.418a13.586 13.586 0 0 1-1.2 3.568A7.423 7.423 0 0 1 15 27.5zm8.125 1.25a5.596 5.596 0 0 1-3.566-1.279 8.7 8.7 0 0 0 3.236-3.538 14.833 14.833 0 0 0 1.31-3.896l1.51-8.047-.517-.732c-11.112-1.755-11.974-5.695-11.98-5.722l-1.243.089c0 2.213-2.953 5.625-6.875 5.625l-.614.74 1.508 8.047a14.804 14.804 0 0 0 1.311 3.896 8.697 8.697 0 0 0 3.236 3.538 5.596 5.596 0 0 1-3.566 1.279 5.632 5.632 0 0 1-5.62-5.394c1.816-2.272 1.87-9.4 1.87-10.231C3.125 6.577 8.452 1.25 15 1.25s11.875 5.327 11.875 11.875c0 .83.054 7.959 1.87 10.231a5.632 5.632 0 0 1-5.62 5.394z"
        fill={fill}
      />
      <path
        d="M16.521 14.41h-1.25v4.965h-1.53v1.25h2.78V14.41zM17.07 23.08 15 22.476l-2.07.606.744.745c.354.355.825.55 1.326.55.501 0 .972-.195 1.326-.55l.744-.745z"
        fill={fill}
      />
    </svg>
  )
}
