import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const ErrorBoundaryWrapper = styled.div`
  padding: ${pxToEm(40)} ${pxToEm(32)};
  background: ${({ theme }) => theme.palette.whiteSmoke};
  height: 100%;
  display: flex;
  justify-content: center;
  font: ${({ theme }) => theme.fonts.headingSmall};
  text-align: center;
  ${({ theme }) => theme.media.medium} {
    padding: ${pxToEm(80)} ${pxToEm(155)};
  }
`
