import { Overlay } from './styles'

export const OverlayWrapper: React.FC = () => {
  return (
    <>
      <Overlay className="vmmv-menu-overlay--horizontal" />
      <Overlay toTop className="vmmv-menu-overlay--vertical-to-top" />
      <Overlay toBottom className="vmmv-menu-overlay--vertical-to-bottom" />
    </>
  )
}
