import styled from 'styled-components'
import { pxToEm } from '@libs/styled'

export const PrivacyContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => theme.palette.white[100]};
  color: ${({ theme }) => theme.palette.black};
  width: 100%;
  z-index: 1001;
`

export const TitleWrapper = styled.div`
  margin: ${pxToEm(24)} 0;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.media.medium} {
    margin: ${pxToEm(70)} 0 ${pxToEm(56)};
  }
  h1 {
    font: ${({ theme }) => theme.fonts.titleSmall};
  }
`

export const CtaItems = styled.div<{ withShadow: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.white[100]};
  padding: ${pxToEm(24)} ${pxToEm(32)} ${pxToEm(32)};
  ${({ withShadow }) =>
    withShadow && `box-shadow: 0 -${pxToEm(14)} ${pxToEm(10)} -${pxToEm(8)} rgba(0, 0, 0, 0.12);`}

  button {
    padding: 0 ${pxToEm(38)};
    height: ${pxToEm(48)};
    border-radius: ${pxToEm(66)};
    background: ${({ theme }) => theme.palette.black};

    &.decline {
      background: ${({ theme }) => theme.palette.white[100]};
      border: solid 1px ${({ theme }) => theme.palette.black};
      color: ${({ theme }) => theme.palette.black};
    }
  }
`

export const PrivacyPolicyWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.white[100]};
  text-align: center;
  padding: 0 ${pxToEm(21)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  height: 100%;
  max-height: 100%;

  p {
    text-align: left;
    flex-grow: 1;
    margin: 0;
  }
`

export const PrivacyPolicyContentWrapper = styled.div<{ withLabel: boolean }>`
  ${({ theme }) => theme.media.medium} {
    max-width: ${pxToEm(568)};
  }
  max-width: ${pxToEm(400)};
  margin: 0 auto;
  overflow: auto;
  text-align: left;
  white-space: pre-line;
  margin-bottom: ${({ withLabel }) => (withLabel ? pxToEm(130) : pxToEm(100))};
  font: ${({ theme }) => theme.fonts.paragraph};
`

export const CloseIconWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.media.medium} {
    display: block;
    position: absolute;
    top: ${pxToEm(35)};
    right: ${pxToEm(35)};
  }
`
