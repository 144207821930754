import { InitializeOptions, VtoCoreEnvironment } from '@luxottica/vto-core'
import { Config } from '@customTypes/config'
import { trackVideoTakeFail } from '@libs/analytics'
import { default as appConfig } from '@config/index'

export const useVtoInitParams = (
  config: Config,
  setUserID: (value: string) => void,
  onRefresh: () => void
) => {
  const { store, vmInit, locale = 'en-US' } = config

  const initOptions: InitializeOptions = {
    // userId: '',
    environment: (vmInit.environment?.toLowerCase() ||
      appConfig.vmEnv?.toLowerCase()) as VtoCoreEnvironment,
    locale,
    brand: vmInit.brand,
    storeId: store.id,
    analyticsConfig: {
      onRenderFailure() {
        onRefresh()
      },
    },
  }

  return {
    options: initOptions,
    onSuccess: (userId: string) => setUserID(userId),
    onError: trackVideoTakeFail,
  }
}
