import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const ActionContainer = styled.div`
  position: absolute;
  left: ${pxToEm(10)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

export const OptionsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const OptionStyled = styled.li<{ isActive?: boolean; expand?: boolean }>`
  padding: ${pxToEm(8)};
  border: ${({ isActive, theme }) => isActive && `1px solid ${theme.palette.white[100]}`};
  margin-bottom: ${pxToEm(10)};
  border-radius: ${pxToEm(30)};
  line-height: 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0);
  padding-right: ${({ expand }) => expand && '12px'};
  transition: padding-right 0.4s;
  backdrop-filter: ${({ isActive }) => (isActive ? 'blur(3px)' : 'none')};
  background-color: ${({ isActive }) => (isActive ? 'rgba(255,255,255,0.1)' : 'none')};

  ${({ theme }) => theme.media.small} {
    margin-bottom: ${pxToEm(22)};
  }

  svg {
    g {
      transform: scale(1.5) translate(-5px, -5px);
    }
  }
`

export const Label = styled.span<{ toggleText?: boolean }>`
  color: ${({ theme }) => theme.palette.white[100]};
  font: ${({ theme }) => theme.fonts.span};
  margin-left: ${({ toggleText }) => (toggleText ? pxToEm(10) : 0)};
  opacity: ${({ toggleText }) => (toggleText ? '1' : '0')};
  transition: opacity 0.3s;
  display: ${({ toggleText }) => (toggleText ? 'inline' : 'none')};
`
