import { InfoIcon } from '@components/core/Icons'
import {
  setVisibilityTermsConditionsModal,
  setVisibilityPrivacyPolicyModal,
  setVisibilityBipaModal,
} from '@store/actions/ui'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { MenuButton, MenuOverlay, MenuList, ListItem } from './styles'

interface InfoProps {
  toggleVisibility: () => void
  isVisible: boolean
}

export const Info: React.FC<InfoProps> = ({ toggleVisibility, isVisible }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onTermsAndConditions = useCallback(() => {
    dispatch(setVisibilityTermsConditionsModal(true))
  }, [dispatch])

  const onPrivacyPolicy = useCallback(() => {
    dispatch(setVisibilityPrivacyPolicyModal(true))
  }, [dispatch])

  const onBipa = useCallback(() => {
    dispatch(setVisibilityBipaModal(true))
  }, [dispatch])

  return (
    <>
      <MenuButton onClick={toggleVisibility}>
        <InfoIcon width="1.5em" height="1.5em" />
      </MenuButton>
      {isVisible && <MenuOverlay />}
      <MenuList visible={isVisible}>
        <ListItem onClick={onPrivacyPolicy}>{t('PRIVACY_POLICY')}</ListItem>
        <ListItem onClick={onBipa}>{t('NOTICE_OF_BIOMETRIC_USE')}</ListItem>
        <ListItem onClick={onTermsAndConditions}>
          {t('PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS')}
        </ListItem>
      </MenuList>
    </>
  )
}
