import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const EyeglassesOffIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.503 22.5a.622.622 0 0 1-.611-.489l-.974-4.385.73-1.097a.623.623 0 0 1 .52-.278H27.5v3.598l1.25-1.25v-2.348H30V15h-1.294L23.618 3.483a4.388 4.388 0 0 0-2.045-2.145L19.03.066l-.56 1.119 2.543 1.27a3.13 3.13 0 0 1 1.46 1.533L27.34 15h-9.17c-.628 0-1.212.312-1.56.835l-.413.62a3.87 3.87 0 0 0-2.392 0l-.413-.62A1.869 1.869 0 0 0 11.83 15h-9.17L7.526 3.988a3.131 3.131 0 0 1 1.462-1.532l2.542-1.27-.56-1.119-2.542 1.271a4.384 4.384 0 0 0-2.046 2.145L1.295 15.001H0v1.25h1.25v5.625c0 1.034.841 1.875 1.875 1.875h8.373c.885 0 1.638-.604 1.83-1.469l1.033-4.646c.003-.013-.002-.026 0-.039.419-.108.86-.108 1.278 0 .002.013-.002.026.001.039l1.032 4.646a1.864 1.864 0 0 0 1.831 1.469h2.179l-1.25-1.25h-.93zm-6.396-.489a.621.621 0 0 1-.61.49H3.126a.626.626 0 0 1-.625-.625V16.25h9.331c.21 0 .404.104.52.278l.73 1.097-.973 4.385z"
        fill="#222"
      />
      <path
        d="m25.676 25.21 3.725-3.725-.885-.883-3.724 3.724-3.725-3.724-.884.883 3.725 3.725-3.725 3.725.884.883 3.725-3.724 3.724 3.724.885-.883-3.725-3.725z"
        fill="#222"
      />
    </svg>
  )
}
