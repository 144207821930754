import { IconProps } from '@customTypes/icons'
import React from 'react'

export const MenuOpen: React.FC<IconProps> = ({ width = '35px', height = '28px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#dilf8fn07a)" transform="rotate(-90 17.362 9.787)">
        <circle cx="17.362" cy="9.787" r="1.787" fill="#fff" />
        <circle cx="17.362" cy="9.787" r="1.787" stroke="#fff" strokeWidth=".7" />
      </g>
      <g filter="url(#m1cg719inb)" transform="rotate(-90 7.787 18.786)">
        <circle cx="7.787" cy="18.786" r="1.787" fill="#fff" />
        <circle cx="7.787" cy="18.786" r="1.787" stroke="#fff" strokeWidth=".7" />
      </g>
      <g filter="url(#o6jcyhmjwc)" transform="rotate(-90 26.937 18.787)">
        <circle cx="26.937" cy="18.787" r="1.787" fill="#fff" />
        <circle cx="26.937" cy="18.787" r="1.787" stroke="#fff" strokeWidth=".7" />
      </g>
      <defs>
        <filter
          id="dilf8fn07a"
          x="10.225"
          y="2.65"
          width="14.275"
          height="14.275"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_526" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_526" result="shape" />
        </filter>
        <filter
          id="m1cg719inb"
          x=".65"
          y="11.649"
          width="14.275"
          height="14.275"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_526" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_526" result="shape" />
        </filter>
        <filter
          id="o6jcyhmjwc"
          x="19.799"
          y="11.65"
          width="14.275"
          height="14.275"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_526" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_526" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
