import { IconProps } from '@customTypes/icons'
import React from 'react'

export const WishlistFilledIcon: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = 'none',
  stroke = '#fff',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 31" fill={fill} stroke={stroke}>
      <g filter="url(#rsb29cc6oa)">
        <path
          d="M26.196 7.773A6.194 6.194 0 0 0 21.842 6a6.194 6.194 0 0 0-4.354 1.773L17 8.24l-.488-.467A6.194 6.194 0 0 0 12.158 6a6.194 6.194 0 0 0-4.354 1.773 5.98 5.98 0 0 0 0 8.56l8.548 8.403c.179.176.413.264.648.264a.92.92 0 0 0 .648-.264l8.548-8.403a5.98 5.98 0 0 0 0-8.56zm-.648 7.923L17 24.099l-8.548-8.403a5.074 5.074 0 0 1-1.535-3.643c0-1.376.545-2.67 1.535-3.643A5.252 5.252 0 0 1 12.158 6.9c1.4 0 2.716.536 3.714 1.517L17 9.498l1.136-1.088A5.252 5.252 0 0 1 21.842 6.9c1.4 0 2.716.536 3.706 1.509a5.074 5.074 0 0 1 1.535 3.643c0 1.376-.545 2.67-1.535 3.643z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.558 7.523a6.544 6.544 0 0 1 4.6-1.873c1.662 0 3.327.623 4.598 1.872l.244.233.244-.234a6.544 6.544 0 0 1 4.598-1.871c1.663 0 3.329.624 4.6 1.873a6.33 6.33 0 0 1 0 9.06l-8.548 8.402a1.272 1.272 0 0 1-.894.365c-.322 0-.646-.12-.893-.365l-8.549-8.402a6.33 6.33 0 0 1 0-9.06zm4.6-1.173a5.844 5.844 0 0 0-4.11 1.672 5.63 5.63 0 0 0 0 8.061l8.55 8.403c.11.109.255.164.402.164a.57.57 0 0 0 .402-.163l8.549-8.404a5.63 5.63 0 0 0 0-8.06 5.844 5.844 0 0 0-4.109-1.673 5.844 5.844 0 0 0-4.109 1.672l-.003.004-.73.698-.734-.702a5.844 5.844 0 0 0-4.108-1.672zM8.206 8.16a5.602 5.602 0 0 1 3.952-1.609c1.49 0 2.894.57 3.958 1.616l.884.846.892-.854a5.602 5.602 0 0 1 3.95-1.608c1.49 0 2.895.571 3.952 1.61a5.424 5.424 0 0 1 1.64 3.892 5.424 5.424 0 0 1-1.64 3.892L17 24.59l-8.794-8.645a5.424 5.424 0 0 1-1.64-3.892c0-1.47.584-2.854 1.64-3.893z"
          fill="#fff"
        />
        <path
          d="M26.196 7.773A6.194 6.194 0 0 0 21.842 6a6.194 6.194 0 0 0-4.354 1.773L17 8.24l-.488-.467A6.194 6.194 0 0 0 12.158 6a6.194 6.194 0 0 0-4.354 1.773 5.98 5.98 0 0 0 0 8.56l8.548 8.403c.179.176.413.264.648.264a.92.92 0 0 0 .648-.264l8.548-8.403a5.98 5.98 0 0 0 0-8.56zm-.648 7.923L17 24.099l-8.548-8.403a5.074 5.074 0 0 1-1.535-3.643c0-1.376.545-2.67 1.535-3.643A5.252 5.252 0 0 1 12.158 6.9c1.4 0 2.716.536 3.714 1.517L17 9.498l1.136-1.088A5.252 5.252 0 0 1 21.842 6.9c1.4 0 2.716.536 3.706 1.509a5.074 5.074 0 0 1 1.535 3.643c0 1.376-.545 2.67-1.535 3.643z"
          stroke="#fff"
          strokeWidth=".7"
        />
        <path
          clipRule="evenodd"
          d="M7.558 7.523a6.544 6.544 0 0 1 4.6-1.873c1.662 0 3.327.623 4.598 1.872l.244.233.244-.234a6.544 6.544 0 0 1 4.598-1.871c1.663 0 3.329.624 4.6 1.873a6.33 6.33 0 0 1 0 9.06l-8.548 8.402a1.272 1.272 0 0 1-.894.365c-.322 0-.646-.12-.893-.365l-8.549-8.402a6.33 6.33 0 0 1 0-9.06zm4.6-1.173a5.844 5.844 0 0 0-4.11 1.672 5.63 5.63 0 0 0 0 8.061l8.55 8.403c.11.109.255.164.402.164a.57.57 0 0 0 .402-.163l8.549-8.404a5.63 5.63 0 0 0 0-8.06 5.844 5.844 0 0 0-4.109-1.673 5.844 5.844 0 0 0-4.109 1.672l-.003.004-.73.698-.734-.702a5.844 5.844 0 0 0-4.108-1.672zM8.206 8.16a5.602 5.602 0 0 1 3.952-1.609c1.49 0 2.894.57 3.958 1.616l.884.846.892-.854a5.602 5.602 0 0 1 3.95-1.608c1.49 0 2.895.571 3.952 1.61a5.424 5.424 0 0 1 1.64 3.892 5.424 5.424 0 0 1-1.64 3.892L17 24.59l-8.794-8.645a5.424 5.424 0 0 1-1.64-3.892c0-1.47.584-2.854 1.64-3.893z"
          stroke="#fff"
          strokeWidth=".7"
        />
      </g>
      <defs>
        <filter
          id="rsb29cc6oa"
          x=".3"
          y=".3"
          width="33.4"
          height="30.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_507" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_507" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
