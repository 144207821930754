import ApiService from './apiService'
import config from '@config/index'
import { VMData, VmHashData } from '@customTypes/vmData'

const apiService = new ApiService(config.vmApiUrl)

class VMApiService {
  private api: ApiService
  constructor(api: ApiService) {
    this.api = api
  }

  public generateHash = (data: VMData) => {
    return this.api.post<VmHashData, VMData>('/api/virtualmirror', data)
  }
}

export const vmApiService = new VMApiService(apiService)
