import { isPrivacyCookieAccepted } from '@utils/cookies'
import { SET_IS_PRIVACY_ACCEPTED, PrivacyPolicyActionTypes } from '../actions/privacyPolicy'

export interface UIState {
  isPrivacyAccepted: boolean
}

const initialState: UIState = {
  isPrivacyAccepted: isPrivacyCookieAccepted(),
}

export default (state = initialState, action: PrivacyPolicyActionTypes) => {
  switch (action.type) {
    case SET_IS_PRIVACY_ACCEPTED: {
      return {
        ...state,
        isPrivacyAccepted: true,
      }
    }
    default:
      return state
  }
}
