import { ActionBtn } from '@components/RightSIdeActions/styles'
import { useConfig } from '@providers/configProvider'
import { Product } from '@customTypes/product'
import { WishlistIcon } from '@components/core/Icons'
import { WishlistFilledIcon } from '@components/core/Icons/WishlistFilledIcon'
import { useWishlist } from '@hooks/useWishlist'
import { useAddToWishlist } from '@libs/analytics'

interface WishlistProps {
  currentProduct: Product
}

export const Wishlist: React.FC<WishlistProps> = ({ currentProduct }) => {
  const { onToggleWishlist } = useConfig()
  const { wishlisted, toggleWishlist, pending } = useWishlist(currentProduct)
  const trackAddToWishlist = useAddToWishlist(currentProduct)

  const handleToggleWishlist = () => {
    toggleWishlist()
    !wishlisted && trackAddToWishlist()
  }

  if (!onToggleWishlist) return null

  return (
    <ActionBtn onClick={handleToggleWishlist} className="vmmv-wishlist-button" disabled={pending}>
      {wishlisted ? (
        <WishlistFilledIcon width="2em" height="2em" className="vmmv-wishlisted-icon" />
      ) : (
        <WishlistIcon width="2em" height="2em" className="vmmv-non-wishlisted-icon" />
      )}
    </ActionBtn>
  )
}
