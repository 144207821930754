import {
  InitializationParams,
  PrivacyOptions,
  InitializationOptions,
  BipaFormData,
  MicroserviceEnvironment,
} from '@luxottica/virtual-mirror'
import { Config } from '@customTypes/config'
import {
  trackVmInitClientError,
  trackVmPrivacyFail,
  trackVmPrivacyReject,
  trackVmWebcamUnaccessible,
  trackVmWebcamUnavailable,
  trackVmWasmUnsupported,
} from '@libs/analytics'
import { checkIsRegionUS } from '@utils/region'
import { useDispatch } from 'react-redux'
import { setVisibilityTermsConditionsModal } from '@store/actions/ui'
import { setIsPrivacyAccepted } from '@store/actions/privacyPolicy'
import { useNavigate } from 'react-router'
import { liveTryOn } from '@utils/routes'
import { useEffect } from 'react'
import { default as appConfig } from '@config/index'

export const useVmInitParams = (
  config: Config,
  setIsReady: (isReady: boolean) => void
): InitializationParams => {
  const {
    store,
    vmInit,
    fromStore,
    onPrivacyReject,
    onPrivacyAgree,
    onPrivacyFail,
    onTermsAndConditionsClick,
    termsAndConditions,
    bipaPrefill,
  } = config
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (termsAndConditions && !termsAndConditions.match(/https?:\/\//))
      throw Error('Terms and conditions link must start with http(s):// prefix')
  }, [termsAndConditions])

  const openTermsConditions = () => dispatch(setVisibilityTermsConditionsModal(true))
  const privacyOptions: PrivacyOptions = {
    bipaActive: true,
    privacyPolicy: 'javascript:void()',
    termsAndConditions: termsAndConditions || 'javascript:void()',
    region: checkIsRegionUS(config) ? 'US' : 'EU',
    source: vmInit.source,
    bipaPrefill,
  }

  const analyticsConfig = {
    onGlassesOn: () => setIsReady(true),
    onGlassesOff: () => setIsReady(false),
    onInitializeError: trackVmInitClientError,
    onWebcamDenied: trackVmWebcamUnaccessible,
    onWebcamUnavailable: trackVmWebcamUnavailable,
    onMirrorWasmFailure: trackVmWasmUnsupported,
    // eslint-disable-next-line no-console
    onError: (e: string) => console.error(e),
  }

  const initOptions: InitializationOptions = {
    key: vmInit.key,
    channel: vmInit.channel,
    locale: config.locale || 'en-US',
    brand: vmInit.brand,
    sessionTimeout: !fromStore,
    storeId: store.id,
    storeRegion: store.region,
    storeCompany: store.type,
    globalStoreId: store.globalStoreId,
    environment: (vmInit.environment || appConfig.vmEnv) as MicroserviceEnvironment,
    analyticsConfig,
  }
  return {
    options: initOptions,
    privacy: privacyOptions,
    privacyCallbacks: {
      onPrivacyFail: (error: Error) => {
        trackVmPrivacyFail()
        if (onPrivacyFail) {
          onPrivacyFail(error)
        }
      },
      onPrivacyReject: () => {
        navigate(liveTryOn())
        trackVmPrivacyReject()
        if (onPrivacyReject) {
          onPrivacyReject()
        }
      },
      onTermsAndConditionsClick:
        !termsAndConditions && !onTermsAndConditionsClick
          ? openTermsConditions
          : onTermsAndConditionsClick
          ? onTermsAndConditionsClick
          : undefined,
      onPrivacyAgree: (bipaFormData?: BipaFormData) => {
        dispatch(setIsPrivacyAccepted())
        if (onPrivacyAgree) {
          onPrivacyAgree(bipaFormData)
        }
      },
    },
  }
}
