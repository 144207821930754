import styled, { css } from 'styled-components'
import { pxToEm } from '@libs/styled'

export const Overlay = styled.div<{ toTop?: boolean; toBottom?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  opacity: 0.55;
  background-image: linear-gradient(to right, #000 10%, rgba(0, 0, 0, 0) 96%);

  ${({ toTop }) =>
    toTop &&
    css`
      top: auto;
      height: ${pxToEm(250)};
      right: 0;
      background-image: linear-gradient(to top, #000 10%, rgba(0, 0, 0, 0) 96%);
    `}

  ${({ toBottom }) =>
    toBottom &&
    css`
      bottom: auto;
      height: ${pxToEm(250)};
      right: 0;
      background-image: linear-gradient(to bottom, #000 10%, rgba(0, 0, 0, 0) 96%);
    `}
`
