import { VirtualMirror } from '@luxottica/virtual-mirror'

const addQueryParamsToUrl = (url: string, params: string[]) => {
  const [origin, query] = url.split('?')
  if (!query) {
    return `${url}?${params.join('&')}`
  }
  return `${origin}?${[query, ...params].join('&')}`
}

export const getVmVersion = () => {
  const ver = VirtualMirror.version()
  return ver.replace('v', '')
}

export const addAnalyticsQueryParam = (url: string) => addQueryParamsToUrl(url, ['CID=IT-WVM'])

/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
export function executeOnce<Args extends unknown[], Return>(
  cb: (...args: Args) => Return,
  id: string
) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}
