import { ActivePanelName } from '@customTypes/transition'
import { VtoPage } from '@customTypes/vtoPage'
export const SET_VISIBILITY_TERMS_CONDITIONS_MODAL =
  'vmmv-ui/ui/SET_VISIBILITY_TERMS_CONDITIONS_MODAL'
export const SET_VISIBILITY_PRIVACY_POLICY_MODAL = 'vmmv-ui/ui/SET_VISIBILITY_PRIVACY_POLICY_MODAL'
export const SET_VISIBILITY_BIPA_MODAL = 'vmmv-ui/ui/SET_VISIBILITY_BIPA_MODAL'
export const SET_QR_CODE_MODAL_VISIBILITY = 'vmmv-ui/ui/SET_QR_CODE_MODAL_VISIBILITY'
export const SET_TRANSITION_VIEW_CAROUSEL = 'vmmv-ui/ui/SET_TRANSITION_VIEW_CAROUSEL'
export const SET_MENU_VISIBILITY = 'vmmv-ui/ui/SET_MENU_VISIBILITY'
export const SET_MODAL_VISIBILITY = 'vmmv-ui/ui/SET_MODAL_VISIBILITY'
export const SET_VTO_PAGE = 'vmmv-ui/ui/SET_VTO_PAGE'

export interface SetVisibilityBipaModal {
  type: typeof SET_VISIBILITY_BIPA_MODAL
  payload: { visible: boolean }
}

export const setVisibilityBipaModal = (visible: boolean): SetVisibilityBipaModal => {
  return {
    type: SET_VISIBILITY_BIPA_MODAL,
    payload: {
      visible,
    },
  }
}

export interface SetVisibilityPrivacyPolicyModal {
  type: typeof SET_VISIBILITY_PRIVACY_POLICY_MODAL
  payload: { visible: boolean }
}

export const setVisibilityPrivacyPolicyModal = (
  visible: boolean
): SetVisibilityPrivacyPolicyModal => {
  return {
    type: SET_VISIBILITY_PRIVACY_POLICY_MODAL,
    payload: {
      visible,
    },
  }
}

export interface SetVisibilityTermsConditionsModal {
  type: typeof SET_VISIBILITY_TERMS_CONDITIONS_MODAL
  payload: { visible: boolean }
}

export const setVisibilityTermsConditionsModal = (
  visible: boolean
): SetVisibilityTermsConditionsModal => {
  return {
    type: SET_VISIBILITY_TERMS_CONDITIONS_MODAL,
    payload: {
      visible,
    },
  }
}

export interface SetQRCodeModalVisibility {
  type: typeof SET_QR_CODE_MODAL_VISIBILITY
  payload: { visible: boolean }
}

export const setQRCodeModalVisibility = (visible: boolean): SetQRCodeModalVisibility => {
  return {
    type: SET_QR_CODE_MODAL_VISIBILITY,
    payload: {
      visible,
    },
  }
}

export interface SetTransitionViewCarousel {
  type: typeof SET_TRANSITION_VIEW_CAROUSEL
  payload: { activeName: ActivePanelName }
}

export const setTransitionViewCarousel = (
  activeName: ActivePanelName
): SetTransitionViewCarousel => {
  return {
    type: SET_TRANSITION_VIEW_CAROUSEL,
    payload: {
      activeName,
    },
  }
}
export interface SetModalVisibility {
  type: typeof SET_MODAL_VISIBILITY
  payload: { visible: boolean }
}

export const setModalVisibility = (visible: boolean): SetModalVisibility => {
  return {
    type: SET_MODAL_VISIBILITY,
    payload: {
      visible,
    },
  }
}

export interface SetMenuVisibility {
  type: typeof SET_MENU_VISIBILITY
  payload: { visible: boolean }
}

export const setMenuVisibility = (visible: boolean): SetMenuVisibility => {
  return {
    type: SET_MENU_VISIBILITY,
    payload: {
      visible,
    },
  }
}

export interface SetVtoPage {
  type: typeof SET_VTO_PAGE
  payload: { vtoPage: VtoPage }
}

export const setVtoPage = (vtoPage: VtoPage): SetVtoPage => {
  return {
    type: SET_VTO_PAGE,
    payload: { vtoPage },
  }
}

export type UIActionTypes =
  | SetVisibilityTermsConditionsModal
  | SetVisibilityBipaModal
  | SetVisibilityPrivacyPolicyModal
  | SetQRCodeModalVisibility
  | SetTransitionViewCarousel
  | SetMenuVisibility
  | SetModalVisibility
  | SetVtoPage
