import { IconProps } from '@customTypes/icons'
import React from 'react'

export const RotateIconDesktop: React.FC<IconProps> = ({
  width = '32px',
  height = '36px',
  fill = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 42"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#zqntxeaf1a)">
        <g filter="url(#hj2wgtuafb)">
          <path
            d="m22.31 33.45.027-6.015 3.978.017a1.06 1.06 0 0 0 .962-1.513l-6.045-12.782a1.069 1.069 0 0 0-.208-.299 1.062 1.062 0 0 0-1.705.29l-6.155 12.73a1.057 1.057 0 0 0 .95 1.521l3.977.017-.026 6.016a1.059 1.059 0 0 0 1.056 1.064l2.126.01a1.06 1.06 0 0 0 1.064-1.056zm-3.184-.013.025-6.016.005-1.06-1.06-.004-3.978-.017 6.16-12.723 6.042 12.776-3.978-.017-1.06-.005-.005 1.06-.026 6.015-2.125-.01z"
            fill="#fff"
          />
          <path
            d="m22.31 33.45.027-6.015 3.978.017a1.06 1.06 0 0 0 .962-1.513l-6.045-12.782a1.069 1.069 0 0 0-.208-.299 1.062 1.062 0 0 0-1.705.29l-6.155 12.73a1.057 1.057 0 0 0 .95 1.521l3.977.017-.026 6.016a1.059 1.059 0 0 0 1.056 1.064l2.126.01a1.06 1.06 0 0 0 1.064-1.056zm-3.184-.013.025-6.016.005-1.06-1.06-.004-3.978-.017 6.16-12.723 6.042 12.776-3.978-.017-1.06-.005-.005 1.06-.026 6.015-2.125-.01z"
            stroke="#fff"
            strokeWidth=".7"
          />
        </g>
        <path
          d="M31.734 9.52H8.266l1.5 1.5-.837.838L6 8.928 8.93 6l.837.837-1.501 1.5h9.367a3954.209 3954.209 0 0 0 4.734 0h9.367l-1.5-1.5L31.07 6 34 8.929l-2.93 2.928-.837-.836 1.501-1.5z"
          fill="#fff"
          stroke="#fff"
          strokeWidth=".7"
        />
      </g>
      <defs>
        <filter
          id="zqntxeaf1a"
          x=".505"
          y=".505"
          width="38.99"
          height="39.35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_644_690" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_644_690" result="shape" />
        </filter>
        <filter
          id="hj2wgtuafb"
          x="9.706"
          y="9.2"
          width="21.025"
          height="28.655"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_644_690" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_644_690" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
