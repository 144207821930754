import { Queue } from '@customTypes/animationApp'
import {
  AnimationAppActionTypes,
  SET_CURRENT_ANIMATION,
  SET_COMPLETE_ANIMATION,
} from '@store/actions/animationApp'

interface AnimationAppState {
  animationQueue: Queue[]
  currentAnimation: Queue | null
  isComplete: boolean
}

// Ordered queue
const queue: Queue[] = [
  'menu',
  'transitionBtn',
  'transitionLabel',
  'decreaseOpacity',
  'increaseOpacity',
]

// Glasses on -> menu visible -> 3sec -> menu close -> -> transition view btn is expand -> 3 sec -> transition btn short
// -> click transition btn -> labels expand -> 3 sec -> labels short -> 4sec -> decrease transition opacity -> 4sec -> increase transition opacity

const initialState: AnimationAppState = {
  animationQueue: queue,
  currentAnimation: null,
  isComplete: false,
}

const animationApp = (state = initialState, action: AnimationAppActionTypes) => {
  switch (action.type) {
    case SET_CURRENT_ANIMATION: {
      const currentAnimation = action.payload.currentAnimation
      const queue = state.animationQueue.filter(q => q !== currentAnimation)
      return {
        ...state,
        animationQueue: queue,
        currentAnimation,
      }
    }
    case SET_COMPLETE_ANIMATION: {
      return {
        ...state,
        isComplete: true,
      }
    }
    default:
      return state
  }
}

export default animationApp
