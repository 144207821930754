import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const StyledQRCode = styled.div<{ size: number; url: string }>`
  margin: 0 auto;
  height: ${({ size }) => pxToEm(size)};
  width: ${({ size }) => pxToEm(size)};
  background-color: ${({ theme }) => theme.palette.whiteSmoke};
  animation-name: ${({ url }) => (url ? 'none' : 'color')};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  border-radius: ${pxToEm(20)};

  @keyframes color {
    0% {
      background-color: ${({ theme }) => theme.palette.whiteSmoke};
    }
    100% {
      background-color: ${({ theme }) => theme.palette.whiteSmoke};
    }
  }

  canvas {
    border-radius: ${pxToEm(20)};
  }
`
