import { Product } from '@customTypes/product'
import { useConfig } from '@providers/configProvider'
import { AppState } from '@store/index'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleWishlist as toggleWishlistAction } from '@store/actions/wishlist'

export const useWishlist = (currentProduct: Product) => {
  const { onToggleWishlist } = useConfig()
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)

  const wishlist = useSelector((s: AppState) => s.wishlist.wishlist)
  const wishlisted = useMemo(
    () => wishlist.includes(currentProduct.upc),
    [currentProduct.upc, wishlist]
  )

  const toggleWishlist = useCallback(async () => {
    try {
      setPending(true)
      onToggleWishlist && (await onToggleWishlist(currentProduct))
      dispatch(toggleWishlistAction(currentProduct.upc))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      setPending(false)
    }
  }, [currentProduct, dispatch, onToggleWishlist, setPending])

  return {
    toggleWishlist,
    wishlist,
    wishlisted,
    pending,
  }
}
