import { CloseIcon } from '@components/core/Icons'
import { AppState } from '@store/index'
import { setQRCodeModalVisibility } from '@store/actions/ui'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { QRCode } from './QRCode'
import {
  QRCodeModalContent,
  CloseBtn,
  QRCodeModalTitle,
  ProductName,
  Instructions,
  InstructionStep,
  InstructionStepNumber,
  Text,
  QRCodeWrapper,
} from './styles'

interface QRCodeModalProps {
  productName: string
}

export const QRCodeModal: React.FC<QRCodeModalProps> = ({ productName }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isOpen = useSelector((s: AppState) => s.ui.isQRCodeModalVisible)

  if (!isOpen) {
    return null
  }

  return (
    <QRCodeWrapper className="vmmv-qr-code-wrapper">
      <QRCodeModalContent>
        <CloseBtn onClick={() => dispatch(setQRCodeModalVisibility(false))}>
          <CloseIcon fill="#000" stroke="#000" width="32px" height="32px" filter={false} />
        </CloseBtn>
        <QRCodeModalTitle>{t('tablet-store-qrcode-reader.scan-the-code')}</QRCodeModalTitle>
        <ProductName>{productName}</ProductName>
        <QRCode />
        <Instructions className="vmmv-qr-code-instructions">
          <InstructionStep>
            <InstructionStepNumber>1</InstructionStepNumber>
            <Text>{t('tablet-store-qrcode-reader.take-your-phone')}</Text>
          </InstructionStep>
          <InstructionStep>
            <InstructionStepNumber>2</InstructionStepNumber>
            <Text>{t('tablet-store-qrcode-reader.enjoy-and-download')}</Text>
          </InstructionStep>
        </Instructions>
      </QRCodeModalContent>
    </QRCodeWrapper>
  )
}
