import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  right: ${pxToEm(-8)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

export const SliderWrapper = styled.div<{ isEvolve: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ isEvolve }) => (isEvolve ? pxToEm(176) : pxToEm(194))};

  ${({ theme }) => theme.media.medium} {
    height: ${pxToEm(244)};
  }

  ${({ theme }) => theme.media.large} {
    height: ${pxToEm(300)};
  }

  svg {
    &:first-child {
      margin-bottom: ${pxToEm(10)};
    }
    &:last-child {
      margin-top: ${pxToEm(10)};
      margin-bottom: ${pxToEm(15)};
    }
  }
`

export const RangeTrackStyled = styled.div`
  display: flex;
  height: 100%;
`

export const RangeTrackSelectedStyled = styled.div`
  height: 100%;
  width: ${pxToEm(2)};
  border-radius: ${pxToEm(5)};
  align-self: center;
  background-color: ${({ theme }) => theme.palette.white[100]};
  margin: 0 ${pxToEm(30)};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
`

export const RangeThumbStyled = styled.div`
  height: ${pxToEm(20)};
  width: ${pxToEm(20)};
  position: relative;
  padding: ${pxToEm(30)};
  outline: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    height: ${pxToEm(14)};
    width: ${pxToEm(14)};
    background-color: ${({ theme }) => theme.palette.white[100]};
  }

  &::after {
    height: ${pxToEm(22)};
    width: ${pxToEm(22)};
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.palette.white[100]};
  }
`

export const LabelStyled = styled.span`
  color: ${({ theme }) => theme.palette.white[100]};
  font: ${({ theme }) => theme.fonts.span};
`
