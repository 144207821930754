import { useConfig } from '@providers/configProvider'
import { vtoApiService } from '@services/vtoApiService'
import { useEffect } from 'react'
import { useAsync } from './useAsync'
import { BipaContentRes } from '@customTypes/vtoData'

const fetchData = async (url: string): Promise<BipaContentRes> => {
  const raw = await fetch(url)
  return raw.json()
}

export const useBipaContent = (skip: boolean) => {
  const { locale } = useConfig()

  const {
    run: getAuthData,
    data: authData,
    loading: isAuthLoading,
  } = useAsync(vtoApiService.generateAuthToken)

  const {
    run: getBipaContentUrls,
    data: bipaContent,
    loading: isBipaContentLoading,
  } = useAsync(vtoApiService.getBipaContentUrls)

  const { run: getBipa, data: bipa, loading: isBipaLoading } = useAsync(fetchData)

  useEffect(() => {
    if (skip) return
    if (!authData) {
      getAuthData({ rememberMe: true })
    }
  }, [authData, getAuthData, skip])

  useEffect(() => {
    if (skip) return
    if (!bipaContent && authData) {
      getBipaContentUrls({ Authorization: `Bearer ${authData.access_token}` })
    }
  }, [getBipaContentUrls, bipaContent, authData, skip])

  const jsonUrl = (bipaContent && JSON.parse(bipaContent?.jsonUrl)) || {}
  const bipaUrl = jsonUrl[locale || 'en-US'] || jsonUrl['en-US']

  useEffect(() => {
    if (skip) return
    if (!bipa && bipaUrl) {
      getBipa(bipaUrl)
    }
  }, [bipa, bipaUrl, getBipa, skip])

  return {
    bipa,
    loading: isAuthLoading || isBipaContentLoading || isBipaLoading,
  }
}
