import { IconProps } from '@customTypes/icons'
import React from 'react'
export const ShareIcon: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.66 13.82c-.908 0-1.71.456-2.194 1.15L5.21 10.842a2.666 2.666 0 0 0 0-1.682l8.256-4.128a2.671 2.671 0 0 0 4.867-1.524c0-1.474-1.199-2.674-2.673-2.674a2.677 2.677 0 0 0-2.674 2.674c0 .294.05.576.138.84L4.867 8.478A2.671 2.671 0 0 0 0 10a2.672 2.672 0 0 0 4.867 1.524l8.257 4.128a2.667 2.667 0 0 0-.138.84c0 1.475 1.2 2.674 2.674 2.674s2.673-1.2 2.673-2.673c0-1.474-1.199-2.674-2.673-2.674zm0-12.222c1.053 0 1.91.857 1.91 1.91s-.857 1.91-1.91 1.91a1.912 1.912 0 0 1-1.91-1.91c0-1.053.857-1.91 1.91-1.91zM2.674 11.91A1.912 1.912 0 0 1 .764 10c0-1.052.857-1.91 1.91-1.91s1.91.858 1.91 1.91c0 1.054-.857 1.91-1.91 1.91zm12.986 6.493a1.912 1.912 0 0 1-1.91-1.91c0-1.052.857-1.909 1.91-1.909s1.91.857 1.91 1.91-.857 1.91-1.91 1.91z"
        fill={fill}
      />
    </svg>
  )
}
