import React, { useCallback } from 'react'
import {
  PrivacyPolicyWrapperStyled,
  CtaItems,
  TitleWrapper,
  PrivacyPolicyContentWrapper,
  CloseIconWrapper,
  PrivacyContainer,
} from './styles'
import { CloseIcon } from '@components/core/Icons'
import { pxToEm } from '@libs/styled'
import { useConfig } from '@providers/configProvider'

interface PrivacyWrapperProps {
  title: string
  content: React.ReactNode
  ctaContent: React.ReactNode
  className: string
  withLabel?: boolean
  withShadow?: boolean
}

export const PrivacyPolicyWrapper: React.FC<PrivacyWrapperProps> = ({
  title,
  content,
  ctaContent,
  withLabel = false,
  withShadow = true,
  className,
}) => {
  const { onClose } = useConfig()

  const onCloseBtnClick = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  return (
    <PrivacyContainer className={className}>
      <CloseIconWrapper onClick={onCloseBtnClick}>
        <CloseIcon fill="black" stroke="black" width={pxToEm(40)} height={pxToEm(40)} />
      </CloseIconWrapper>
      <PrivacyPolicyWrapperStyled>
        <PrivacyPolicyContentWrapper withLabel={withLabel}>
          <TitleWrapper>
            <h1>{title}</h1>
          </TitleWrapper>
          {content}
        </PrivacyPolicyContentWrapper>
        <CtaItems withShadow={withShadow}>{ctaContent}</CtaItems>
      </PrivacyPolicyWrapperStyled>
    </PrivacyContainer>
  )
}
