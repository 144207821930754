import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderWrapper, Dash, LoadingText, LoaderContent, Spin } from './styles'

export const Loader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <LoaderWrapper className="vmmv-loader-wrapper">
      <LoaderContent>
        {Array(150)
          .fill('')
          .map((value: string, index: number) => (
            <Dash className="vmmv-loader-dash" key={index} position={index}></Dash>
          ))}
      </LoaderContent>
      <LoadingText>{t('picture-mode.loading')}</LoadingText>
    </LoaderWrapper>
  )
}

export const Loading: React.FC<{ className?: string }> = ({ className }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 200px)',
  }

  return (
    <div style={style} className={className}>
      <Spin />
    </div>
  )
}
