import { resetButtonStyles } from '@components/Menu/styles'
import { pxToEm } from '@libs/styled'
import styled, { css } from 'styled-components'

export const ToggleBtn = styled.button<{ expand?: boolean }>`
  position: absolute;
  left: ${pxToEm(10)};
  top: ${pxToEm(100)};
  width: ${({ expand }) => (expand ? 'auto' : pxToEm(32))};
  height: ${pxToEm(32)};
  border-radius: ${pxToEm(32)};
  z-index: 1;
  transition: width 0.5s ease;
  box-sizing: content-box;
  ${({ theme }) => theme.media.medium} {
    top: ${pxToEm(120)};
  }
  ${resetButtonStyles}
`
export const IconWrapper = styled.div`
  position: absolute;
  width: ${pxToEm(20)};
  height: ${pxToEm(20)};
  top: -${pxToEm(10)};
  right: -${pxToEm(5)};
  border-radius: 50%;
  display: flex;
  line-height: 0;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(${pxToEm(50)});
  border: solid 1px ${({ theme }) => theme.palette.white[70]};
  background-image: linear-gradient(
    153deg,
    ${({ theme }) => theme.palette.white[70]} 26%,
    ${({ theme }) => theme.palette.white[40]} 97%
  );
`

export const TransitionIconWrapper = styled.div<{ withText?: boolean }>`
  width: ${pxToEm(40)};
  height: ${pxToEm(40)};
  border-radius: ${pxToEm(20)};
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ withText }) =>
    withText &&
    css`
      width: auto;
      color: white;
      font-size: ${pxToEm(12)};
      justify-content: flex-start;
      padding-left: ${pxToEm(20)};
      padding-right: ${pxToEm(20)};
      font: ${({ theme }) => theme.fonts.span};
      white-space: nowrap;
    `}
`
