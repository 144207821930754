import { IconProps } from '@customTypes/icons'
import React from 'react'

export const MenuClose: React.FC<IconProps> = ({ width = '35px', height = '28px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#aghtj5jxba)" transform="rotate(-90 17.362 13.787)">
        <circle cx="17.362" cy="13.787" r="1.787" fill="#fff" />
        <circle cx="17.362" cy="13.787" r="1.787" stroke="#fff" strokeWidth=".7" />
      </g>
      <g filter="url(#7n083f79wb)" transform="rotate(-90 7.787 13.787)">
        <circle cx="7.787" cy="13.787" r="1.787" fill="#fff" />
        <circle cx="7.787" cy="13.787" r="1.787" stroke="#fff" strokeWidth=".7" />
      </g>
      <g filter="url(#g5y69hhu6c)" transform="rotate(-90 26.937 13.787)">
        <circle cx="26.937" cy="13.787" r="1.787" fill="#fff" />
        <circle cx="26.937" cy="13.787" r="1.787" stroke="#fff" strokeWidth=".7" />
      </g>
      <defs>
        <filter
          id="aghtj5jxba"
          x="10.225"
          y="6.65"
          width="14.274"
          height="14.273"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2882_3112" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2882_3112" result="shape" />
        </filter>
        <filter
          id="7n083f79wb"
          x=".65"
          y="6.65"
          width="14.274"
          height="14.273"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2882_3112" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2882_3112" result="shape" />
        </filter>
        <filter
          id="g5y69hhu6c"
          x="19.799"
          y="6.65"
          width="14.274"
          height="14.273"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2882_3112" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2882_3112" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
