import styled, { css } from 'styled-components'
import { pxToEm } from '@libs/styled'

export const resetButtonStyles = css`
  border: none;
  background: none;
  outline: none;
  stroke: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
`

export const MenuButton = styled.button`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.palette.white[100]};
  ${resetButtonStyles}
`

export const MenuWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${pxToEm(10)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ active }) => (active ? 2 : 1)};
  height: ${({ active }) => (active ? '100%' : 'auto')};
  background-image: ${({ active }) =>
    !active ? 'linear-gradient(to bottom, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0) 96%)' : 'none'};
`

export const MenuListWrapper = styled.ul<{ visible?: boolean }>`
  position: absolute;
  padding: 0;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  top: ${pxToEm(65)};
  transition: opacity 0.3s;
  width: ${({ visible }) => (visible ? 'auto' : '0')};
  height: ${({ visible }) => (visible ? 'auto' : '0')};
  overflow: ${({ visible }) => (visible ? 'auto' : 'hidden')};
`

export const MenuOption = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToEm(20)};
`

export const MenuLogo = styled.div`
  padding: ${pxToEm(10)};
  line-height: 0;
  border-radius: 50%;
  margin-right: ${pxToEm(10)};
  border: ${({ theme }) => `1px solid ${theme.palette.white[100]}`};
  backdrop-filter: blur(40px);
  background-color: ${({ theme }) => theme.palette.white[40]};
`

export const ListText = styled.span<{ active?: boolean }>`
  padding: ${pxToEm(5)} ${pxToEm(10)};
  font: ${({ theme }) => theme.fonts.headingSmall};
  font-size: 15px;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  color: ${({ theme }) => theme.palette.white[100]};
  backdrop-filter: blur(20px);
  background-color: ${({ active, theme }) => (active ? theme.palette.white[40] : 'none')};
  border-radius: ${pxToEm(15)};
`
