import styled from 'styled-components'
import { pxToEm } from '@libs/styled'
import { CatalogItem, CatalogItemInner, CarouselWrapper } from '../styles'

export const StyledCatalogItemInner = styled(CatalogItemInner)`
  width: auto;
  height: auto;
`

export const StyledCatalogItem = styled(CatalogItem)<{ isActive: boolean }>`
  margin-right: ${pxToEm(20)};
  background-color: ${({ isActive, theme }) =>
    isActive ? `${theme.palette.black}` : `${theme.palette.white[70]}`};
  width: max-content;
  color: ${({ isActive, theme }) =>
    isActive ? `${theme.palette.white[100]}` : `${theme.palette.black}`};
  border-radius: ${pxToEm(100)};
  padding: ${pxToEm(15)} ${pxToEm(25)};
  background-clip: border-box;
  text-transform: capitalize;
  border: none;
  font: ${({ theme }) => theme.fonts.paragraph};
`

export const StyledLensDescriptionWrapper = styled.div`
  text-align: center;
  padding: ${pxToEm(10)} ${pxToEm(20)};
  margin-top: ${pxToEm(15)};
  height: ${pxToEm(110)};
  background-color: ${({ theme }) => theme.palette.white[100]};
  font: ${({ theme }) => theme.fonts.span};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.medium} {
    height: ${pxToEm(117)};
    padding: ${pxToEm(25)} ${pxToEm(70)};
  }
`

export const CarouselWrapperStyled = styled(CarouselWrapper)`
  bottom: 0;
  z-index: 2;
`
