import { IconProps } from '@customTypes/icons'
import React from 'react'
export const NavBarActionScan: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.833 1.25c0-.23.187-.417.417-.417h2.917V0H1.25C.56 0 0 .56 0 1.25v2.917h.833V1.25zM.833 18.75v-2.916H0v2.917C0 19.44.56 20 1.25 20h2.917v-.834H1.25a.417.417 0 0 1-.417-.416zM19.166 18.75c0 .23-.187.417-.416.417h-2.917v.834h2.917c.689 0 1.25-.561 1.25-1.25v-2.917h-.834v2.917zM18.75 0h-2.917v.833h2.917c.23 0 .416.187.416.417v2.917H20V1.25C20 .56 19.439 0 18.75 0zM15.83 8.329v-.05h.003l-.002-.035.002-.654c0-3.266-2.616-5.924-5.833-5.924S4.167 4.324 4.167 7.59v.688l.158 1.305H0v.833h20v-.833h-4.324l.154-1.254zM10 2.499c2.435 0 4.467 1.783 4.908 4.134-.836-.49-2.014-.492-2.538-.492-3.34 0-4.01-1.45-4.014-1.457l-.804.152c0 1.52-.855 2.3-2.532 2.331C5.233 4.557 7.383 2.5 10 2.5zM5 8.253v-.249c2.211-.027 2.997-1.181 3.26-2.175.652.542 1.879 1.145 4.11 1.145 2.306 0 2.623.682 2.63 1.285l-.164 1.324H5.164L5 8.253zM5.708 13.203a.416.416 0 1 0-.25.533.421.421 0 0 0 .254-.533h-.004zM5.054 12.096a.42.42 0 0 0 .363-.463.417.417 0 0 0-.467-.362.411.411 0 0 0-.363.462A.414.414 0 0 0 5 12.1c.017 0 .033 0 .054-.004zM15.046 11.283a.416.416 0 1 0-.1.825.416.416 0 0 0 .1-.825zM5.896 14.484a.42.42 0 0 0-.033.591c.079.092.196.138.308.138.1 0 .2-.034.28-.1a.419.419 0 0 0-.554-.63zM14.82 12.963a.42.42 0 0 0-.533.254.417.417 0 0 0 .392.558.42.42 0 0 0 .391-.279v.004a.419.419 0 0 0-.25-.537zM11.258 17.012v-.005a.421.421 0 0 0-.03.592.426.426 0 0 0 .593.025.42.42 0 0 0 .025-.587.412.412 0 0 0-.588-.025zM7.03 15.745a.42.42 0 0 0 .279.729.415.415 0 0 0 .308-.696.42.42 0 0 0-.587-.033zM13.508 14.534v-.005a.42.42 0 0 0 .034.592.415.415 0 0 0 .587-.037.415.415 0 1 0-.62-.55zM12.375 15.787a.415.415 0 1 0 .616.559.415.415 0 0 0-.616-.559zM8.146 17.029a.412.412 0 0 0 .025.587.4.4 0 0 0 .279.109.415.415 0 0 0 .283-.72.412.412 0 0 0-.587.024zM9.996 17.5a.421.421 0 0 0-.42.417.418.418 0 0 0 .837 0 .418.418 0 0 0-.417-.417z"
        fill={fill}
      />
    </svg>
  )
}
