import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const QRCodeIcon: React.FC<IconProps> = ({
  width = pxToEm(32),
  height = pxToEm(32),
  fill = '#fff',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        filter="url(#3qackqikra)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        stroke={fill}
        strokeWidth=".3"
      >
        <path d="M17.216 4.2h-6.563v3.248h3.303v3.293h3.303L17.216 4.2zM10.653 10.74H4.2v3.249h6.453v-3.248z" />
        <path d="M14 10.74H4.2v3.249h3.194v3.292l3.28-.043-.02-3.249H14v-3.248z" />
        <path d="M23.8 10.74h-6.54v3.249h3.237v3.292h-3.238V13.99H14l-.066 3.248h-3.259v3.314h3.26V23.8h3.324V20.55l3.238-.021v3.27H23.8V10.74zM8.947 18.916H4.2V23.8h4.747v-4.884zM8.947 4.2H4.2v4.884h4.747V4.2zM23.734 4.2h-4.747v4.884h4.747V4.2z" />
      </g>
      <defs>
        <filter
          id="3qackqikra"
          x=".05"
          y=".05"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_644_597" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_644_597" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
