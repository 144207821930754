import { ActivePanelName } from '@customTypes/transition'
import { VtoPage } from '@customTypes/vtoPage'
import {
  SET_MENU_VISIBILITY,
  SET_MODAL_VISIBILITY,
  SET_QR_CODE_MODAL_VISIBILITY,
  SET_TRANSITION_VIEW_CAROUSEL,
  SET_VISIBILITY_BIPA_MODAL,
  SET_VISIBILITY_PRIVACY_POLICY_MODAL,
  SET_VISIBILITY_TERMS_CONDITIONS_MODAL,
  SET_VTO_PAGE,
  UIActionTypes,
} from '../actions/ui'

export interface UIState {
  isPrivacyPolicyModalVisible: boolean
  isBipaModalVisible: boolean
  isTermsConditionsVisible: boolean
  isQRCodeModalVisible: boolean
  activeName: ActivePanelName | null
  isMenuVisible: boolean
  isModalVisible: boolean
  vtoPage: VtoPage
}

const initialState: UIState = {
  isTermsConditionsVisible: false,
  isPrivacyPolicyModalVisible: false,
  isBipaModalVisible: false,
  isQRCodeModalVisible: false,
  activeName: null,
  isMenuVisible: false,
  isModalVisible: false,
  vtoPage: 'off',
}

export default (state = initialState, action: UIActionTypes) => {
  switch (action.type) {
    case SET_VISIBILITY_TERMS_CONDITIONS_MODAL: {
      return {
        ...state,
        isTermsConditionsVisible: action.payload.visible,
      }
    }
    case SET_VISIBILITY_PRIVACY_POLICY_MODAL: {
      return {
        ...state,
        isPrivacyPolicyModalVisible: action.payload.visible,
      }
    }
    case SET_VISIBILITY_BIPA_MODAL: {
      return {
        ...state,
        isBipaModalVisible: action.payload.visible,
      }
    }
    case SET_QR_CODE_MODAL_VISIBILITY: {
      return {
        ...state,
        isQRCodeModalVisible: action.payload.visible,
      }
    }
    case SET_TRANSITION_VIEW_CAROUSEL: {
      return {
        ...state,
        activeName: action.payload.activeName,
      }
    }
    case SET_MENU_VISIBILITY: {
      return {
        ...state,
        isMenuVisible: action.payload.visible,
      }
    }
    case SET_MODAL_VISIBILITY: {
      return {
        ...state,
        isModalVisible: action.payload.visible,
      }
    }
    case SET_VTO_PAGE: {
      return {
        ...state,
        vtoPage: action.payload.vtoPage,
      }
    }
    default:
      return state
  }
}
