import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const UploadIcon: React.FC<IconProps> = ({
  width = pxToEm(32),
  height = pxToEm(32),
  fill = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
    >
      <path
        d="M32 15.333H16.667V0h-1.334v15.333H0v1.334h15.333V32h1.334V16.667H32v-1.334z"
        fill={fill}
      />
    </svg>
  )
}
