import { IconProps } from '@customTypes/icons'
import React from 'react'

export const ChooseFrameIcon: React.FC<IconProps> = ({ width = '1em', height = '1em' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M21.417 7.556c-2.034 0-3.76 1.291-4.358 3.072a2.679 2.679 0 0 0-2.118 0c-.598-1.78-2.324-3.072-4.358-3.072C8.056 7.556 6 9.55 6 12s2.056 4.445 4.583 4.445S15.167 14.45 15.167 12c0-.19-.017-.377-.041-.561a1.77 1.77 0 0 1 1.748 0c-.024.184-.04.37-.04.561 0 2.45 2.056 4.445 4.583 4.445S26 14.45 26 12c0-2.45-2.056-4.444-4.583-4.444zm-10.834 8.08c-2.067 0-3.75-1.63-3.75-3.636 0-2.005 1.683-3.636 3.75-3.636 2.068 0 3.75 1.63 3.75 3.636 0 2.005-1.682 3.636-3.75 3.636zm10.834 0c-2.068 0-3.75-1.63-3.75-3.636 0-2.005 1.682-3.636 3.75-3.636 2.067 0 3.75 1.63 3.75 3.636 0 2.005-1.683 3.636-3.75 3.636z"
          fill="#fff"
        />
        <path
          d="M21.417 7.556c-2.034 0-3.76 1.291-4.358 3.072a2.679 2.679 0 0 0-2.118 0c-.598-1.78-2.324-3.072-4.358-3.072C8.056 7.556 6 9.55 6 12s2.056 4.445 4.583 4.445S15.167 14.45 15.167 12c0-.19-.017-.377-.041-.561a1.77 1.77 0 0 1 1.748 0c-.024.184-.04.37-.04.561 0 2.45 2.056 4.445 4.583 4.445S26 14.45 26 12c0-2.45-2.056-4.444-4.583-4.444zm-10.834 8.08c-2.067 0-3.75-1.63-3.75-3.636 0-2.005 1.683-3.636 3.75-3.636 2.068 0 3.75 1.63 3.75 3.636 0 2.005-1.682 3.636-3.75 3.636zm10.834 0c-2.068 0-3.75-1.63-3.75-3.636 0-2.005 1.682-3.636 3.75-3.636 2.067 0 3.75 1.63 3.75 3.636 0 2.005-1.683 3.636-3.75 3.636z"
          stroke="#fff"
          strokeWidth=".3"
        />
      </g>
    </svg>
  )
}
