import { VtoCore } from '@luxottica/vto-core'
import { useCallback, useEffect } from 'react'
import { useConfig } from '@providers/configProvider'
import { useDispatch } from 'react-redux'
import { Menu } from '@components/Menu'
import { FooterActions } from '@components/FooterActions'
import { useVirtualMirrorApp } from '@hooks/useVirtualMirrorApp'
import { TransitionView } from '@components/TransitionView'
import { useScreenshot } from '@hooks/useScreenshot'
import { useDimensions } from '@hooks/useDimensions'
import { RightSideActions } from '@components/RightSIdeActions'
import { useNavigate } from 'react-router'
import { VtoInitialize } from '@components/VtoInitialize'
import styled from 'styled-components'
import { QRCodeModal } from '@components/QRCodeModal'
import { setQRCodeModalVisibility, setVtoPage } from '@store/actions/ui'
import { useFitGlasses } from '@libs/analytics'
import { ScreenshotPreview } from '@components/ScreenshotPreview'
import { Drawer } from '@components/core/Drawer'
import { isDesktopView } from '@libs/utils'
import { getPictureId, clearPictureId } from '@utils/sessionStorage'
import { AddPrescriptionModal } from '@components/AddPrescriptionModal'

export const CONTAINER_ID_PICTURE_MODE = 'vm-picture-mode'
const nosePositionTouches = isDesktopView() ? 1 : 2

const PhotoModeContainer = styled.div`
  .vto-swipeable-container .render-container-multipose {
    cursor: unset;
  }
`

export const closePictureMode = () => {
  return new Promise<void>((resolve, reject) => {
    VtoCore.closeRender({ divId: CONTAINER_ID_PICTURE_MODE }, resolve, reject)
  })
}

export const PictureModeView = () => {
  const { fromStore } = useConfig()

  const pictureId = getPictureId()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onRefresh = useCallback(() => {
    clearPictureId()
    VtoCore.closeRender({ divId: CONTAINER_ID_PICTURE_MODE }, () => {
      dispatch(setVtoPage('off'))
      navigate(-1)
    })
  }, [dispatch, navigate])

  const {
    isReady,
    isPreparingScreenshot,
    setIsPreparingScreenshot,
    currentProduct,
    currentColor,
    changeUpc,
    colorsCatalog,
    currentLens,
    changeLens,
    lensCatalog,
    lensActivation,
    setIsReady,
    setColors,
    setCurrentColor,
    setLensActivation,
    setIsVMInitialized,
    setUserID,
    isVMInitialized,
    isTransitionActive,
    toggleTransitionActive,
    vmProducts,
  } = useVirtualMirrorApp()

  const { image, setImage } = useScreenshot()

  const dimensions = useDimensions()

  const trackFitGlasses = useFitGlasses(currentProduct)

  useEffect(() => {
    if (isReady) {
      trackFitGlasses()
    }
  }, [isReady, trackFitGlasses])

  const isEvolveProduct = currentProduct.transitionLens?.isEvolve
  const renderVto = useCallback(() => {
    VtoCore.render(
      {
        divId: CONTAINER_ID_PICTURE_MODE,
        videoId: pictureId,
        upc: currentProduct.upc,
        showRotateBar: false,
        width: dimensions.width,
        height: dimensions.height,
        nosePositionTouches,
      },
      () => {
        setIsReady(true)
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    )
  }, [currentProduct.upc, dimensions.height, dimensions.width, pictureId, setIsReady])

  useEffect(() => {
    if (!currentProduct.upc && !isVMInitialized) {
      return
    }
    renderVto()
  }, [currentProduct, isVMInitialized, renderVto])

  useEffect(() => {
    if (isTransitionActive || isEvolveProduct) {
      VtoCore.setTransitionOpacity(lensActivation)
      renderVto()
    }
  }, [isTransitionActive, lensActivation, renderVto, isEvolveProduct])

  useEffect(() => {
    if (!isTransitionActive && !isEvolveProduct) {
      VtoCore.setTransitionColorName('')
      VtoCore.setTransitionOpacity(0)
      renderVto()
    }
  }, [isTransitionActive, renderVto, isEvolveProduct])

  useEffect(() => {
    if (isTransitionActive || isEvolveProduct) {
      VtoCore.setTransitionColorName(currentColor)
      renderVto()
    }
  }, [isTransitionActive, isEvolveProduct, currentColor, renderVto])

  useEffect(() => {
    if (isVMInitialized) {
      dispatch(setVtoPage('picture-mode'))
    }
  }, [dispatch, isVMInitialized])

  const getScreenshot = useCallback(async () => {
    try {
      setIsPreparingScreenshot(true)
      const img = await VtoCore.getScreenshot()
      setImage({
        imgURI: img.dataUrl,
        width: img.w,
        height: img.h,
      })
      setIsPreparingScreenshot(false)
    } catch (error) {
      setIsPreparingScreenshot(false)
      // eslint-disable-next-line no-console
      console.error(`Error generating the image: ${error}`)
    }
  }, [setIsPreparingScreenshot, setImage])

  return (
    <VtoInitialize
      setIsVMInitialized={setIsVMInitialized}
      setUserID={setUserID}
      setColors={setColors}
      onRefresh={onRefresh}
    >
      <>
        <Menu fromStore={fromStore} />
        {isReady && (
          <RightSideActions
            product={currentProduct}
            enableWishlistCb={!isTransitionActive && fromStore}
            onRefresh={onRefresh}
            onQRCb={() => dispatch(setQRCodeModalVisibility(true))}
          />
        )}
        <PhotoModeContainer className="vmmv__picture" id={CONTAINER_ID_PICTURE_MODE} />
        <TransitionView
          isTransitionActive={isTransitionActive}
          toggleTransitionActive={toggleTransitionActive}
          isReady={isReady}
          isTransition={currentProduct.isTransition || false}
          products={vmProducts || []}
          selectedUpc={currentProduct.upc}
          onChangeUpc={changeUpc}
          colors={colorsCatalog}
          onChangeColor={setCurrentColor}
          selectedColor={currentColor}
          lenses={lensCatalog}
          selectedLens={currentLens || ''}
          onChangeLens={changeLens}
          lensActivation={lensActivation}
          onChangeLensOpacity={setLensActivation}
        />
        <FooterActions
          lensType={currentLens}
          isTransitionActive={isTransitionActive}
          isPreparingScreenshot={isPreparingScreenshot}
          isReady={isReady}
          product={currentProduct}
          getScreenshot={getScreenshot}
        />

        <Drawer visible={!!image} className={'vmmv-drawer-screenshot'}>
          <ScreenshotPreview image={image} setImage={setImage} product={currentProduct} />
        </Drawer>

        <QRCodeModal productName={currentProduct.name} />
        <AddPrescriptionModal product={currentProduct} />
      </>
    </VtoInitialize>
  )
}
