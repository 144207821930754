import { useState, useEffect, useRef } from 'react'
import { debounce } from 'lodash'

export const LAYOUT_ID = 'vm-layout'
const RESIZE_DELAY = 300

const getSelectorDimensions = (container: HTMLElement | null) => {
  if (!container) {
    return {
      width: undefined,
      height: undefined,
    }
  }

  const { offsetWidth: width, offsetHeight: height } = container

  return {
    width,
    height,
  }
}

export const useDimensions = (elem?: HTMLElement | null) => {
  const container = useRef<HTMLElement | null>(document.getElementById(LAYOUT_ID))

  useEffect(() => {
    if (elem) {
      container.current = elem
    }
  }, [elem])

  const [selectorDimensions, setSelectorDimensions] = useState(
    getSelectorDimensions(container?.current)
  )

  useEffect(() => {
    function handleResize() {
      setSelectorDimensions(getSelectorDimensions(container?.current))
    }
    const debouncedHandleResize = debounce(handleResize, RESIZE_DELAY)

    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [])
  return selectorDimensions
}
