import ApiService from './apiService'
import config from '@config/index'
import { VtoAuthDataReq, VtoAuthDataRes, VtoBipaRes } from '@customTypes/vtoData'

const apiService = new ApiService(config.vtoApiUrl)

class VtoApiService {
  private api: ApiService
  constructor(api: ApiService) {
    this.api = api
  }

  public generateAuthToken = (data: VtoAuthDataReq) => {
    return this.api.post<VtoAuthDataRes, VtoAuthDataReq>('/auth/vm/token', data)
  }

  public getBipaContentUrls = (headers?: HeadersInit) => {
    return this.api.get<VtoBipaRes>('/services/vtobipams/public/v2/bipa/version', headers)
  }
}

export const vtoApiService = new VtoApiService(apiService)
