import styled from 'styled-components'

export const ModalWindowOverlay = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.25);
  font-size: 16px;
`
