import { Queue } from '@customTypes/animationApp'

export const SET_CURRENT_ANIMATION = 'vmmv-ui/animation/SET_CURRENT_ANIMATION'
export const SET_COMPLETE_ANIMATION = 'vmmv-ui/animation/SET_COMPLETE_ANIMATION'

export interface SetCompleteAnimation {
  type: typeof SET_COMPLETE_ANIMATION
}

export interface SetCurrentAnimation {
  type: typeof SET_CURRENT_ANIMATION
  payload: { currentAnimation: Queue | null }
}

export const setCurrentAnimation = (currentAnimation: Queue | null): SetCurrentAnimation => {
  return {
    type: SET_CURRENT_ANIMATION,
    payload: {
      currentAnimation,
    },
  }
}

export const setCompleteAnimation = (): SetCompleteAnimation => {
  return {
    type: SET_COMPLETE_ANIMATION,
  }
}

export type AnimationAppActionTypes = SetCurrentAnimation | SetCompleteAnimation
