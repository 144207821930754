import { Product } from '@customTypes/product'
import { category, frameTypes, TakeMode } from './helpers'
import { getVmVersion } from './utils'

// URL for all ENV
const ROOT_URL = 'https://collect.tealiumiq.com/event'

const sendtrackData = <I extends Record<string, unknown>>(data: I) => {
  const json = JSON.stringify(data)
  fetch(ROOT_URL, { headers: { 'Content-type': 'application/json' }, method: 'post', body: json })
}

const getCommonData = (event: string) => {
  return {
    tealium_account: 'luxottica', // Value from analytics
    tealium_profile: 'vmmaster', // Value from analytics
    tealium_datasource: '6dcmo7', // Value from analytics
    tealium_event: event,
    tealium_visitor_id: window?.utag_data?.tealium_visitor_id || '',
    tealium_session_id: window?.utag_data?.tealium_session_id || '',
    page_environment: window?.utag_data?.Page_Environment || window?.utag_data?.utag_env || '',
    page_platform: window?.utag_data?.Page_Platform || window?.utag_data?.utag_plt || '',
    page_brand: window?.utag_data?.Page_Brand || window?.utag_data?.utag_brnd || '',
    country_code: window?.utag_data?.Page_Country || window?.utag_data?.utag_strrgn || '',
    url: location.href || '',
    domain: location.hostname || '',
    store_id: window?.utag_data?.Store_Id || window?.utag_data?.utag_strid || '',
  }
}

const getClickData = (label: string) => {
  const commonData = getCommonData('Click')
  return { ...commonData, click_label: label }
}

const formatEventData = (products: Product[]) => {
  return products.reduce(
    (acc, pr) => {
      acc.product_id.push(pr.upc)
      acc.product_category.push(pr.category || category.Sun)
      acc.product_frametype.push(pr.isCustom ? frameTypes.Cp : frameTypes.Std)
      return acc
    },
    { product_id: [], product_category: [], product_frametype: [] } as {
      product_id: string[]
      product_category: string[]
      product_frametype: string[]
    }
  )
}

const getEventData = (products: Product[], takeMode: TakeMode, event: string) => {
  const formattedEventData = formatEventData(products)
  const commonData = getCommonData(event)
  return {
    ...commonData,
    ...formattedEventData,
    take_mode: takeMode,
    vm_version: getVmVersion(),
  }
}

const getErrorData = (source: string, code: string, details: string) => {
  const commonData = getCommonData('error')
  return {
    ...commonData,
    error_source: source,
    error_code: code,
    error_details: details,
    vm_version: getVmVersion(),
  }
}

export const trackClickComponentData = (label: string) => {
  const clickData = getClickData(label)
  sendtrackData(clickData)
}

export const trackEventComponentData = (products: Product[], takeMode: TakeMode, event: string) => {
  const eventData = getEventData(products, takeMode, event)
  sendtrackData(eventData)
}

export const trackErrorComponentData = (source: string, code: string, details: string) => {
  const errorData = getErrorData(source, code, details)
  sendtrackData(errorData)
}
