import { QRCodeIcon, RefreshIcon } from '@components/core/Icons'
import { Product } from '@customTypes/product'
import { isMobileView } from '@libs/utils'
import { useConfig } from '@providers/configProvider'
import { RightSideActionsWrapper, ActionBtn } from './styles'
import { Wishlist } from '@components/Wishlist'

interface RightSideActionsProps {
  product: Product
  className?: string
  onRefresh?: () => void
  enableWishlistCb?: boolean
  onQRCb?: () => void
}

export const RightSideActions: React.FC<RightSideActionsProps> = ({
  className,
  onRefresh,
  product,
  enableWishlistCb,
  onQRCb,
}) => {
  const { isQrCodeEnabled } = useConfig()

  return (
    <RightSideActionsWrapper className={`vmmv-right-side-actions ${className}`}>
      {enableWishlistCb && <Wishlist currentProduct={product} />}
      {onRefresh && (
        <ActionBtn onClick={onRefresh}>
          <RefreshIcon width="2em" height="2em" />
        </ActionBtn>
      )}
      {isQrCodeEnabled && !isMobileView() && onQRCb && (
        <ActionBtn onClick={onQRCb}>
          <QRCodeIcon width="2em" height="2em" />
        </ActionBtn>
      )}
    </RightSideActionsWrapper>
  )
}
