import styled from 'styled-components'
import { pxToEm } from '@libs/styled'
import { resetButtonStyles } from '@components/Menu/styles'

export const MenuButton = styled.button`
  position: relative;
  z-index: 1;
  ${resetButtonStyles}
`

export const MenuOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.55;
  background-image: linear-gradient(to left, #000 10%, rgba(0, 0, 0, 0) 96%);
`

export const MenuList = styled.ul<{ visible?: boolean }>`
  position: absolute;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  width: ${({ visible }) => (visible ? 'auto' : '0')};
  height: ${({ visible }) => (visible ? 'auto' : '0')};
  overflow: ${({ visible }) => (visible ? 'auto' : 'hidden')};
  top: ${pxToEm(65)};
  right: ${pxToEm(20)};
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  list-style: none;
`

export const ListItem = styled.li`
  color: ${({ theme }) => theme.palette.white[100]};
  font: ${({ theme }) => theme.fonts.span};
  margin-bottom: ${pxToEm(32)};
`
