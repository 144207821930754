import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const RelaxFaceIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.54 10.658C30.416 5.33 25.868 1.7 21.696.582 17.783-.468 14.29.504 12.4 3.113c-2.08-1.096-4.3-1.047-6.176.157C3.34 5.122 1.81 9.252 2.326 13.752c.01.206.292 5.043 2.864 7.299.121.106.252.188.38.28.932 5.297 5.493 9.335 10.977 9.335 5.72 0 10.443-4.393 11.082-10.03 1.214-1.071 3.06-3.733 2.912-9.978zM3.635 13.64c-.46-4.036.832-7.664 3.292-9.243 1.625-1.043 3.518-.983 5.333.168l.91-.222c1.757-2.971 5.382-3.225 8.19-2.472 3.713.995 7.759 4.18 7.87 8.82.096 4.108-.71 6.46-1.52 7.778v-5.803l-.554-.658C15.48 10.136 14.576 5.934 14.57 5.905L13.264 6c0 2.36-3.102 6-7.223 6l-.656.666V19.3c-1.537-2.104-1.746-5.584-1.75-5.66zm12.913 15.693c-5.431 0-9.85-4.486-9.85-10v-6.025c3.774-.28 6.513-2.95 7.49-5.436 1.317 1.65 4.51 4.04 12.208 5.361v6.1c0 5.514-4.418 10-9.848 10z"
        fill="#000"
      />
      <path
        d="M17.868 24h-2.644v1.333h2.644V24zM16.832 20.666h-1.608V22h2.92V15.37h-1.312v5.295z"
        fill="#000"
      />
    </svg>
  )
}
