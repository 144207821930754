import { IconProps } from '@customTypes/icons'
import React from 'react'
export const NavBarActionPicture: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  stroke = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 8.5A1.5 1.5 0 0 1 4 7h16a1.5 1.5 0 0 1 1.5 1.5v10.167a1.5 1.5 0 0 1-1.5 1.5H4a1.5 1.5 0 0 1-1.5-1.5V8.5z"
        stroke={stroke}
      />
      <circle cx="11.999" cy="13.583" r="4.083" stroke={stroke} />
      <path d="M7.5 6A1.5 1.5 0 0 1 9 4.5h6A1.5 1.5 0 0 1 16.5 6v.833h-9V6z" stroke={stroke} />
    </svg>
  )
}
