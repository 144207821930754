import { IconProps } from '@customTypes/icons'
import React from 'react'

export const CartIcon: React.FC<IconProps> = ({ width = '1em', height = '1em', fill = 'none' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.953 3.418a.358.358 0 0 0-.355-.296H1.493V2.1h1.105c.66 0 1.23.471 1.359 1.12l.566 2.83h17.036a.945.945 0 0 1 .93 1.134l-1.572 7.84a.951.951 0 0 1-.93.762H6.446l-.574 2.01a.36.36 0 0 0 .348.46h13.764v1.023h-1.06l.056.138a1.758 1.758 0 0 1 .12.862c-.1.91-.874 1.62-1.81 1.62a1.824 1.824 0 0 1-1.822-1.82c0-.236.048-.458.13-.662l.056-.138h-5.9l.056.138a1.758 1.758 0 0 1 .12.862c-.101.91-.874 1.62-1.811 1.62a1.824 1.824 0 0 1-1.821-1.82c0-.236.048-.458.13-.662l.056-.138h-.264a1.4 1.4 0 0 1-.731-.207 1.392 1.392 0 0 1-.6-1.557l.52-1.825L3.234 4.608l-.28-1.189zM4.731 7.05l1.52 7.736h13.694l1.55-7.736H4.732zm2.84 12.207-.217.535a.758.758 0 0 0-.057.286c0 .453.369.822.821.822.452 0 .821-.37.821-.822a.76.76 0 0 0-.057-.287l-.216-.534H7.57zm9.17 0h1.095l.216.535c.038.094.058.189.058.286 0 .453-.37.822-.822.822a.823.823 0 0 1-.821-.822c0-.097.02-.192.057-.286l.217-.535z"
        fill="#fff"
      />
    </svg>
  )
}
