const colorMap: Record<string, string> = {
  sunglasshut: '#000',
  sgh: '#000',
  to: '#2a2f57',
  lc: '#2a2f57',
  opsm: '#2a2f57',
  oo: '#2a2f57',
  rb: '#2a2f57',
  sv: '#2a2f57',
  mk: 'rgba(42, 47, 88, .6)',
  avenir: 'rgba(42, 47, 88, .6)',
  m4c: 'rgba(42, 47, 88, .6)',
}

export const getColorFromStyle = (style: string) => ({
  infoColor: colorMap[style] || '#000',
})
