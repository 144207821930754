import { trackClickComponentData } from './componentTracking'
import { trackAttribute, valueTypes } from './helpers'
import { executeOnce } from './utils'

// Close button
export const trackClickButtonClose = () => {
  const label = 'X_X_VirtualMirror_Close'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Menu Try on
export const trackClickMenuTryOn = () => {
  const label = 'X_X_VirtualMirror_Menu_TryOn'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Menu Video mode
export const trackClickMenuVideo = () => {
  const label = 'X_X_VirtualMirror_Menu_Video'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Menu Picture mode
export const trackClickMenuPicture = () => {
  const label = 'X_X_VirtualMirror_Menu_Picture'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Take a picture in picture mode
export const trackClickTakePicture = () => {
  const label = 'X_X_VirtualMirror_TakePic'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Upload a picture in picture mode
export const trackClickUploadPicture = () => {
  const label = 'X_X_VirtualMirror_UploadPic'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Save img
export const trackClickSaveImg = () => {
  const label = 'X_X_VirtualMirror_Share_Img'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Share social
export const trackClickShareSocial = () => {
  const label = 'X_X_VirtualMirror_Share_Social'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Transition view
export const trackClickTransitionView = () => {
  const label = 'X_X_VirtualMirror_Transitions'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Transition view - Lens
export const trackClickTransitionLens = () => {
  const label = 'X_X_VirtualMirror_Transitions_Lens'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Transition view - Color
export const trackClickTransitionColor = () => {
  const label = 'X_X_VirtualMirror_Transitions_Color'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Transition view - Frame
export const trackClickTransitionFrame = () => {
  const label = 'X_X_VirtualMirror_Transitions_Frame'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Add to wishlist click
export const trackClickAddToWishlist = () => {
  const label = 'X_X_VirtualMirror_AddFav'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Add to cart click
export const trackClickAddToCart = () => {
  const label = 'X_X_VirtualMirror_AddCart'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  trackClickComponentData(label)
}

// Brightness bar
export const trackClickBrightnessBar = () => {
  const label = 'X_X_VirtualMirror_Transitions_Brightness'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  executeOnce(trackClickComponentData, label)(label)
}

// Lens carousel
export const trackClickLensCarousel = () => {
  const label = 'X_X_VirtualMirror_Transitions_Lens_Carousel'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  executeOnce(trackClickComponentData, label)(label)
}

// Lens color carousel
export const trackClickColorCarousel = () => {
  const label = 'X_X_VirtualMirror_Transitions_Color_Carousel'
  trackAttribute({
    data_element_id: label,
    data_analytics_available_call: valueTypes.Positive,
  })
  executeOnce(trackClickComponentData, label)(label)
}
