const fontMap: Record<string, string> = {
  sgh: 'HelveticaNeue',
  sunglasshut: 'HelveticaNeue',
  oo: 'Avenir, sans-serif',
  rb: 'Avenir, sans-serif',
  sv: 'Avenir, sans-serif',
  to: 'Avenir, sans-serif',
  lc: 'Avenir, sans-serif',
  opsm: 'Avenir, sans-serif',
  m4c: 'Montserrat',
  mk: 'Gotham',
  avenir: 'Avenir, sans-serif',
}

export const getFontFromStyle = (style: string) => fontMap[style] || 'sans-serif'

export const getDefaultFont = () => 'sans-serif'
