import { IconProps } from '@customTypes/icons'
import React from 'react'

export const RotateIcon: React.FC<IconProps> = ({
  width = '32px',
  height = '36px',
  fill = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#rueufnrexb)" clipPath="url(#2pxpfbxvha)">
        <path
          d="M23.353 10.408a1.891 1.891 0 0 0-.955-.265c-.35 0-.673.1-.955.265v-.265c0-1.054-.857-1.911-1.911-1.911-.573 0-1.082.259-1.433.66-.35-.401-.86-.66-1.433-.66-.35 0-.673.101-.955.266V5.366h7.56l-1.211 1.21.675.676L25.1 4.888l-2.364-2.364-.676.676 1.211 1.21h-7.56c0-1.053-.857-1.91-1.911-1.91s-1.91.857-1.91 1.91H4.328L5.54 3.2l-.676-.676L2.5 4.888l2.364 2.364.676-.675-1.211-1.211h7.56v9.297a3.067 3.067 0 0 0-2.866 3.069c0 1.698 2.095 5.856 2.816 7.24a.849.849 0 0 0 .756.456h9.117c.34 0 .647-.2.784-.51l1.739-3.942a.855.855 0 0 0 .073-.347v-8.576c0-.704-.386-1.313-.955-1.645zm0 2.623V20.61l-1.705 3.863h-8.992c-1.627-3.126-2.677-5.767-2.677-6.74 0-1.094.846-2.005 1.91-2.104v2.157h.956V4.411a.956.956 0 0 1 1.91 0v10.508h.956v-4.776a.956.956 0 0 1 1.91 0v4.776h.956v-4.776a.956.956 0 0 1 1.91 0v4.776h.956v-2.866a.956.956 0 0 1 1.91 0v.978z"
          fill="#fff"
        />
        <path
          d="M23.353 10.408a1.891 1.891 0 0 0-.955-.265c-.35 0-.673.1-.955.265v-.265c0-1.054-.857-1.911-1.911-1.911-.573 0-1.082.259-1.433.66-.35-.401-.86-.66-1.433-.66-.35 0-.673.101-.955.266V5.366h7.56l-1.211 1.21.675.676L25.1 4.888l-2.364-2.364-.676.676 1.211 1.21h-7.56c0-1.053-.857-1.91-1.911-1.91s-1.91.857-1.91 1.91H4.328L5.54 3.2l-.676-.676L2.5 4.888l2.364 2.364.676-.675-1.211-1.211h7.56v9.297a3.067 3.067 0 0 0-2.866 3.069c0 1.698 2.095 5.856 2.816 7.24a.849.849 0 0 0 .756.456h9.117c.34 0 .647-.2.784-.51l1.739-3.942a.855.855 0 0 0 .073-.347v-8.576c0-.704-.386-1.313-.955-1.645zm0 2.623V20.61l-1.705 3.863h-8.992c-1.627-3.126-2.677-5.767-2.677-6.74 0-1.094.846-2.005 1.91-2.104v2.157h.956V4.411a.956.956 0 0 1 1.91 0v10.508h.956v-4.776a.956.956 0 0 1 1.91 0v4.776h.956v-4.776a.956.956 0 0 1 1.91 0v4.776h.956v-2.866a.956.956 0 0 1 1.91 0v.978z"
          stroke="#fff"
          strokeWidth=".6"
        />
      </g>
      <defs>
        <clipPath id="2pxpfbxvha">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
        <filter
          id="rueufnrexb"
          x="-1.924"
          y="-1.9"
          width="31.449"
          height="31.628"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_533" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_533" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
