import React from 'react'

export const AlertIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 114.4 114.4"
      fill="#e8415a"
      width="20px"
      height="20px"
    >
      <path d="M14.34 109.1h85.7c8.7 0 14.3-6.5 14.3-14.4 0-2.3-.6-4.8-1.9-7.1l-42.9-75.2c-2.7-4.8-7.5-7.2-12.3-7.2-4.8 0-9.7 2.5-12.3 7.2l-43 75.3c-1.3 2.2-1.9 4.6-1.9 7 0 7.9 5.6 14.4 14.3 14.4zm.1-6.7c-4.5 0-7.5-3.6-7.5-7.7 0-1.1.2-2.4.8-3.8l42.9-75.2c1.4-2.5 3.9-3.6 6.5-3.6 2.5 0 5 1.2 6.5 3.6l42.8 75.3c.7 1.2 1 2.6 1 3.8 0 4-3.1 7.7-7.5 7.7h-85.5zm42.8-30.1c2.1 0 3.4-1.3 3.4-3.6l.7-29.6c.1-2.3-1.7-4-4-4-2.4 0-4.1 1.7-4 4l.6 29.6c-.1 2.3 1.1 3.6 3.3 3.6zm0 17.2c2.8 0 5.1-2.3 5.1-5 0-2.8-2.3-5-5.1-5s-5.1 2.3-5.1 5c-.1 2.7 2.3 5 5.1 5z" />
    </svg>
  )
}

