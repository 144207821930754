import { useState, useEffect } from 'react'

export const useDeviceOrientation = () => {
  const [isLandscape, setLandscape] = useState(
    window.matchMedia('(orientation: landscape)').matches
  )

  const handleOrientationChange = () => {
    setLandscape(window.matchMedia('(orientation: landscape)').matches)
  }

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange)
    return () => window.removeEventListener('resize', handleOrientationChange)
  }, [])

  return {
    isLandscape,
  }
}
