import { Layout } from '@components/Layout'
import { Menu } from '@components/Menu'
import { useConfig } from '@providers/configProvider'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { VirtualMirror } from '@luxottica/virtual-mirror'

import { RightSideActions } from '@components/RightSIdeActions'
import { VMInitialize } from '@components/VMInitialize'
import { useDispatch } from 'react-redux'
import { StyledContainer } from '@components/VMInitialize/styles'
import { TransitionView } from '@components/TransitionView'
import { useVirtualMirrorApp } from '@hooks/useVirtualMirrorApp'
import { FooterActions } from '@components/FooterActions'
import { Drawer } from '@components/core/Drawer'
import { useScreenshot } from '@hooks/useScreenshot'
import { ScreenshotPreview } from '@components/ScreenshotPreview'
import { useFitGlasses, useTrackInitVM } from '@libs/analytics'
import { QRCodeModal } from '@components/QRCodeModal'
import { setQRCodeModalVisibility, setVtoPage } from '@store/actions/ui'
import { useDimensions } from '@hooks/useDimensions'
import { AddPrescriptionModal } from '@components/AddPrescriptionModal'
import { default as appConfig } from '@config/index'

export const CONTAINER_ID_LIVE_TRY_ON = 'vm-app-widget'

const LiveTryOn: React.FC = () => {
  const config = useConfig()
  const { fromStore } = config
  const { image, setImage } = useScreenshot()
  const dispatch = useDispatch()
  const container = useRef<HTMLDivElement | null>(null)
  const { width, height } = useDimensions(container.current)

  const {
    isReady,
    isVMInitialized,
    isPreparingScreenshot,
    currentProduct,
    currentColor,
    colorsCatalog,
    currentLens,
    lensCatalog,
    lensActivation,
    isTransitionActive,
    vmProducts,
    setIsReady,
    setIsVMInitialized,
    setIsPreparingScreenshot,
    changeUpc,
    setCurrentColor,
    setColors,
    changeLens,
    setLensActivation,
    toggleTransitionActive,
  } = useVirtualMirrorApp()

  const isEvolveProduct = currentProduct.transitionLens?.isEvolve

  const trackFitGlasses = useFitGlasses(currentProduct)
  const trackInitVM = useTrackInitVM(vmProducts)

  useEffect(() => {
    if (isReady) {
      trackFitGlasses()
    }
  }, [isReady, trackFitGlasses])

  useEffect(() => {
    if (isVMInitialized) {
      trackInitVM()
    }
  }, [trackInitVM, isVMInitialized])

  useEffect(() => {
    if (!isVMInitialized) return
    if (isTransitionActive || isEvolveProduct) {
      VirtualMirror.setTransitionOpacity({
        opacity: lensActivation,
      })
    }
  }, [isTransitionActive, lensActivation, isEvolveProduct, isVMInitialized])

  const renderParams = useMemo(
    () => ({
      target: CONTAINER_ID_LIVE_TRY_ON,
      upc: currentProduct.upc,
      options: {
        ...(currentColor &&
          (isTransitionActive || isEvolveProduct) && { transitionColor: currentColor }),
        ...(width && { width: width }),
        ...(height && { height: height }),
        environment: {
          // Use any due to impossible to import GlassesEnvironment type, required for env variable
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(config.vmInit.glassesEnv && { glasses: config.vmInit.glassesEnv as any }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          video: (config.vmInit.environment || appConfig.vmEnv) as any,
        },
      },
    }),
    [
      currentProduct.upc,
      currentColor,
      width,
      height,
      isTransitionActive,
      isEvolveProduct,
      config.vmInit.glassesEnv,
      config.vmInit.environment,
    ]
  )

  useEffect(() => {
    const render = async () => {
      if (isVMInitialized) {
        try {
          await VirtualMirror.renderMirror(renderParams)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`Error while rendering vm: ${e}`)
        }
      }
    }
    render()
  }, [isVMInitialized, renderParams])

  useEffect(() => {
    if (isVMInitialized) {
      dispatch(setVtoPage('live-try-on'))
    }
  }, [dispatch, isVMInitialized])

  const getScreenshot = useCallback(async () => {
    try {
      setIsPreparingScreenshot(true)
      const screenshot = await VirtualMirror.getScreenshot()
      setImage(screenshot)
      setIsPreparingScreenshot(false)
    } catch (error) {
      setIsPreparingScreenshot(false)
      // eslint-disable-next-line no-console
      console.error(`Error generating the image: ${error}`)
    }
  }, [setIsPreparingScreenshot, setImage])

  return (
    <Layout ref={container}>
      <VMInitialize
        setIsReady={setIsReady}
        setIsVMInitialized={setIsVMInitialized}
        setColors={setColors}
      >
        <>
          <Menu fromStore={fromStore} hasMirrorRendered={isReady} />
          <StyledContainer id={CONTAINER_ID_LIVE_TRY_ON} />
          {isReady && (
            <RightSideActions
              product={currentProduct}
              enableWishlistCb={!isTransitionActive && fromStore}
              onQRCb={() => dispatch(setQRCodeModalVisibility(true))}
            />
          )}

          <TransitionView
            isTransitionActive={isTransitionActive}
            toggleTransitionActive={toggleTransitionActive}
            isReady={isReady}
            isTransition={currentProduct.isTransition || false}
            products={vmProducts || []}
            selectedUpc={currentProduct.upc}
            onChangeUpc={changeUpc}
            colors={colorsCatalog}
            onChangeColor={setCurrentColor}
            selectedColor={currentColor}
            lenses={lensCatalog}
            selectedLens={currentLens || ''}
            onChangeLens={changeLens}
            lensActivation={lensActivation}
            onChangeLensOpacity={setLensActivation}
          />
          <FooterActions
            lensType={currentLens}
            isTransitionActive={isTransitionActive}
            isPreparingScreenshot={isPreparingScreenshot}
            isReady={isReady}
            product={currentProduct}
            getScreenshot={getScreenshot}
            showScreenshotIcon={true}
          />

          <Drawer visible={!!image} className={'vmmv-drawer-screenshot'}>
            <ScreenshotPreview image={image} setImage={setImage} product={currentProduct} />
          </Drawer>

          <QRCodeModal productName={currentProduct.name} />
          <AddPrescriptionModal product={currentProduct} />
        </>
      </VMInitialize>
    </Layout>
  )
}

export default LiveTryOn
