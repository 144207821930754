import { IconProps } from '@customTypes/icons'
import React from 'react'
export const InfoIcon: React.FC<IconProps> = ({ width = '1em', height = '1em', fill = '#fff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#f3zn0lrhfa)">
        <path
          d="M18 6C11.383 6 6 11.383 6 18c0 6.616 5.383 12 12 12 6.616 0 12-5.384 12-12 0-6.617-5.384-12-12-12zm0 23c-6.066 0-11-4.934-11-11S11.934 7 18 7s11 4.934 11 11-4.934 11-11 11z"
          fill={fill}
        />
        <path
          d="M18 6C11.383 6 6 11.383 6 18c0 6.616 5.383 12 12 12 6.616 0 12-5.384 12-12 0-6.617-5.384-12-12-12zm0 23c-6.066 0-11-4.934-11-11S11.934 7 18 7s11 4.934 11 11-4.934 11-11 11z"
          stroke={fill}
          strokeWidth=".7"
        />
      </g>
      <g filter="url(#oqt0ptf82b)" fill="#fff" stroke="#fff" strokeWidth=".7">
        <path d="M18 15h-1v9h1v-9zM18 12h-1v1h1v-1z" />
      </g>
      <defs>
        <filter
          id="f3zn0lrhfa"
          x=".65"
          y=".65"
          width="34.7"
          height="34.7"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_500" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_500" result="shape" />
        </filter>
        <filter
          id="oqt0ptf82b"
          x="11.65"
          y="6.65"
          width="11.7"
          height="22.7"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_500" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_500" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
