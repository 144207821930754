import { Product, ProductPrice } from '@customTypes/product'
import { trackEventComponentData } from './componentTracking'
import {
  lensTypes,
  trackEvent,
  valueTypes,
  TakeMode,
  getFrameType,
  getCategory,
  formatAnalyticsProducts,
} from './helpers'

export const retrieveDecimalValue = (str?: string) => {
  if (!str) return ''
  const regex = /[+-]?([0-9]*[.])?[0-9]+/
  return str.match(regex)?.[0] || ''
}

const trackEventAddToCart = (product: Product, price: string, priceFull: string) => {
  const id = product.isCustom ? product.code : product.upc
  trackEvent({
    Events_CartAdd: valueTypes.Positive,
    Products: {
      [id]: {
        Category: getCategory(product),
        FrameType: getFrameType(product),
        LensType: lensTypes.Plano,
        Price: price,
        PriceFull: priceFull,
      },
    },
  })
}

const trackEventAddToWishlist = (product: Product, takeMode: TakeMode) => {
  const formattedProduct = formatAnalyticsProducts([product])
  trackEvent({
    Events_ProdFavAdd: valueTypes.Positive,
    Take_Mode: takeMode,
    Products: {
      ...formattedProduct,
    },
  })
}

const trackEventTakePhoto = (product: Product, takeMode: TakeMode) => {
  const formattedProduct = formatAnalyticsProducts([product])
  trackEvent({
    Events_VMScreenshot: valueTypes.Positive,
    Take_Mode: takeMode,
    Products: {
      ...formattedProduct,
    },
  })
}

const trackEventSharePhoto = (product: Product, takeMode: TakeMode) => {
  const formattedProduct = formatAnalyticsProducts([product])
  trackEvent({
    Events_SocialShare: valueTypes.Positive,
    Take_Mode: takeMode,
    Products: {
      ...formattedProduct,
    },
  })
}

const trackEventFitGlasses = (product: Product, takeMode: TakeMode) => {
  const formattedProduct = formatAnalyticsProducts([product])
  trackEvent({
    Events_VMFit: valueTypes.Positive,
    Take_Mode: takeMode,
    Products: {
      ...formattedProduct,
    },
  })
}

export const trackAddToCart = (product: Product, takeMode: TakeMode, price?: ProductPrice) => {
  const formattedPrice = retrieveDecimalValue(price?.current.text)
  const formattedPriceFull = retrieveDecimalValue(price?.previous?.text)
  trackEventAddToCart(product, formattedPrice, formattedPriceFull)
  trackEventComponentData([product], takeMode, 'CartAdd')
}

export const trackAddToWishlist = (product: Product, takeMode: TakeMode) => {
  trackEventAddToWishlist(product, takeMode)
  trackEventComponentData([product], takeMode, 'FavAdd')
}

export const trackTakePhoto = (product: Product, takeMode: TakeMode) => {
  trackEventTakePhoto(product, takeMode)
  trackEventComponentData([product], takeMode, 'TakeShare')
}

export const trackSharePhoto = (product: Product, takeMode: TakeMode) => {
  trackEventSharePhoto(product, takeMode)
  trackEventComponentData([product], takeMode, 'SocialShare')
}

export const trackFitGlasses = (product: Product, takeMode: TakeMode) => {
  trackEventFitGlasses(product, takeMode)
  trackEventComponentData([product], takeMode, 'FitGlasses')
}
