import {
  createStore,
  compose,
  StoreEnhancer,
  StoreEnhancerStoreCreator,
  applyMiddleware,
  Middleware,
} from 'redux'
import rootReducer from './reducers'
import { persistReducers, loadState } from './localStorage'

const initialState = loadState()

const enhancers: StoreEnhancer[] = []
const middleware: Middleware[] = []

// eslint-disable-next-line @typescript-eslint/ban-types
let composeWithDevTools: <I extends unknown[]>(...args: I) => StoreEnhancer<unknown, {}>
if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  composeWithDevTools = require('redux-devtools-extension/logOnlyInProduction').composeWithDevTools
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  composeWithDevTools = require('redux-devtools-extension').composeWithDevTools
}

const composedEnhancers = compose<StoreEnhancerStoreCreator>(
  applyMiddleware(...middleware),
  ...enhancers
)

const initStore = (initState = initialState) => {
  return createStore(rootReducer, initState, composeWithDevTools(composedEnhancers))
}

export const store = initStore(initialState)

persistReducers(store, 'animationApp')

export type AppStore = ReturnType<typeof initStore>
export type AppState = ReturnType<typeof rootReducer>
