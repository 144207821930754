import { IconProps } from '@customTypes/icons'
import React from 'react'
export const RefreshIcon: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#vrrdjk4r7a)">
        <path
          d="M19.5 21.688h4.262A9.555 9.555 0 0 1 16 25.625c-5.307 0-9.625-4.318-9.625-9.625H5.5c0 5.79 4.71 10.5 10.5 10.5a10.42 10.42 0 0 0 8.313-4.09v4.09h.875v-5.688H19.5v.875z"
          fill={fill}
        />
        <path
          d="M19.5 21.688h4.262A9.555 9.555 0 0 1 16 25.625c-5.307 0-9.625-4.318-9.625-9.625H5.5c0 5.79 4.71 10.5 10.5 10.5a10.42 10.42 0 0 0 8.313-4.09v4.09h.875v-5.688H19.5v.875z"
          stroke="#fff"
          strokeWidth=".7"
        />
      </g>
      <g filter="url(#pwkaz0fkvb)">
        <path
          d="M16 5.5a10.421 10.421 0 0 0-8.313 4.09V5.5h-.875v5.688H12.5v-.876H8.238A9.556 9.556 0 0 1 16 6.376c5.307 0 9.625 4.318 9.625 9.625h.875c0-5.79-4.71-10.5-10.5-10.5z"
          fill="#fff"
        />
        <path
          d="M16 5.5a10.421 10.421 0 0 0-8.313 4.09V5.5h-.875v5.688H12.5v-.876H8.238A9.556 9.556 0 0 1 16 6.376c5.307 0 9.625 4.318 9.625 9.625h.875c0-5.79-4.71-10.5-10.5-10.5z"
          stroke="#fff"
          strokeWidth=".7"
        />
      </g>
      <defs>
        <filter
          id="vrrdjk4r7a"
          x=".15"
          y="10.65"
          width="30.388"
          height="21.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_517" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_517" result="shape" />
        </filter>
        <filter
          id="pwkaz0fkvb"
          x="1.462"
          y=".15"
          width="30.388"
          height="21.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_517" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_517" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
