import {
  CatalogContainer,
  CatalogItem,
  CatalogItemInner,
  CatalogItemWrapper,
  Image,
  StyledLabelColor,
  CarouselWrapper,
} from '../styles'
import React, { useEffect, useRef, useState } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import { SwiperOptions } from 'swiper'
import { debounce } from 'lodash'
import { Product } from '@customTypes/product'

const TIME_TO_HIDE_TOOLTIP = 2000

const swiperParams: SwiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 5,
  centeredSlides: true,
  slideToClickedSlide: true, // see: LVM-283
}

interface Catalog {
  items: Product[]
  selectedUpc: string
  onChange: (upc: string) => void
}
export const FrameCatalog: React.FC<Catalog> = ({ items, selectedUpc, onChange }) => {
  const selectedIndex = items.length > 0 ? items.findIndex(el => el.upc === selectedUpc) : 0

  const [currentSlide, setCurrentSlide] = useState(selectedIndex || 0)
  const [isTooltipVisible, setTooltipVisibility] = useState(true)
  const swiper = useRef<SwiperInstance>(null)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const setSwiper = (swiperInstance: SwiperInstance) => {
    swiper.current = swiperInstance
  }

  const changeUpc = debounce((upc: string) => {
    onChange(upc)
  }, 800)

  useEffect(() => {
    if (items.length > 0 && selectedUpc !== items[currentSlide].upc) {
      changeUpc(items[currentSlide].upc)
    }
  }, [items, selectedUpc, currentSlide, changeUpc])

  useEffect(() => {
    setTooltipVisibility(true)
    timer.current = setTimeout(() => {
      setTooltipVisibility(false)
      timer.current && clearTimeout(timer.current)
      timer.current = null
    }, TIME_TO_HIDE_TOOLTIP)

    swiper.current?.on('slideChangeTransitionEnd', () => {
      if (swiper.current) {
        setCurrentSlide(swiper.current.realIndex)
      }
    })

    return function cleanup() {
      swiper.current?.off('click')
      swiper.current?.off('slideChangeTransitionEnd')
    }
  }, [swiper, currentSlide])
  const frameColorLabel = items[currentSlide]?.frameColorLabel
  const lensColorLabel = items[currentSlide]?.lensColorLabel

  return (
    <CarouselWrapper>
      <CatalogContainer>
        {items.length > 0 && (
          <Swiper {...swiperParams} initialSlide={currentSlide} getSwiper={setSwiper}>
            {items.map(({ upc, thumbnailUrl }, index) => (
              <CatalogItemWrapper key={`${upc}-${index}`}>
                <CatalogItemInner>
                  <CatalogItem key={upc} isActive={upc === items[currentSlide].upc}>
                    <Image src={thumbnailUrl} crossOrigin="anonymous" />
                  </CatalogItem>
                </CatalogItemInner>
              </CatalogItemWrapper>
            ))}
          </Swiper>
        )}

        <StyledLabelColor className={isTooltipVisible ? 'show' : 'hide'}>
          {frameColorLabel && <span>{frameColorLabel}</span>}
          {lensColorLabel && <span>{lensColorLabel}</span>}
        </StyledLabelColor>
      </CatalogContainer>
    </CarouselWrapper>
  )
}
