import { Config } from './types/config'
import React, { Suspense } from 'react'
import { createRoot, Root } from 'react-dom/client'
import pkgVersion from './version'
import { VMWidgetQRCode } from './qrCodeWidget'
import { clearPictureVideoIds } from '@utils/sessionStorage'

import { store } from './store/index'
import { closeVirtualMirror } from '@components/Menu/MenuList'
import { closeVideoMode } from '@components/VideoMode/VideoModeView'
import { closePictureMode } from '@components/PictureMode/PictureModeView'
import { setVtoPage } from '@store/actions/ui'
import { FrameAdvisorCapture } from '@luxottica/frame-advisor-capture'

import(/* webpackPrefetch: true */ '@luxottica/frame-advisor-capture')
import(/* webpackPrefetch: true */ '@luxottica/vto-core')
import(/* webpackPrefetch: true */ '@luxottica/virtual-mirror')

const VMWidget = React.lazy(() => import('./VMWidget'))

interface IVMWidgetApp {
  config: Config
  init: () => void
  unmount: () => void
}

class VMWidgetApp implements IVMWidgetApp {
  public config: Config
  private root: Root | null
  constructor(config: Config) {
    this.config = config
    const container = document.querySelector(this.config.selector)
    this.root = container ? createRoot(container) : null
  }

  async init() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    if (!this.root) {
      throw new Error(
        `You requested Virtual mirror to render inside the element with the selector ${this.config.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
      )
    }

    this.root.render(
      <Suspense fallback={null}>
        <VMWidget config={this.config} />
      </Suspense>
    )
  }

  async unmount() {
    return new Promise<void>(async resolve => {
      if (this.root) {
        try {
          const {
            ui: { vtoPage },
          } = store.getState()

          if (vtoPage === 'live-try-on') {
            await closeVirtualMirror()
          } else if (vtoPage === 'picture-mode') {
            await closePictureMode()
          } else if (vtoPage === 'video-mode') {
            await closeVideoMode()
          } else if (vtoPage === 'fa-vto-capture') {
            FrameAdvisorCapture.close()
            FrameAdvisorCapture.closeVtoCapture()
          } else if (vtoPage === 'fa-upload') {
            FrameAdvisorCapture.close()
            FrameAdvisorCapture.closeUploadImage()
          }

          store.dispatch(setVtoPage('off'))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }

        /* useEffect(() => {
          return () => {
            // cleanup, remove VTO
            // this technique is not working to close VTO in LiveTryOn.tsx if we add this code there the camera is still not closed!
            // so that's why we have the code above to workaround this
          }
        }, []) */

        this.root.unmount()
      }

      resolve()
    })
  }

  static new(config: Config) {
    return new VMWidgetApp(config)
  }

  static version() {
    return pkgVersion
  }
}

export { VMWidgetApp, VMWidgetQRCode, clearPictureVideoIds }
