import { Layout } from '@components/Layout'
import { trackGenericError } from '@libs/analytics'
import React from 'react'
import { ErrorBoundaryWrapper } from './styles'

interface IErrorBoundaryComponentState {
  error: Error | null
}

interface IErrorBoundary {
  children?: React.ReactNode
}

export class ErrorBoundary extends React.Component<IErrorBoundary, IErrorBoundaryComponentState> {
  state: IErrorBoundaryComponentState = { error: null }

  componentDidCatch(error: Error) {
    trackGenericError('400', error.message)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      // TODO: create error widget component
      return (
        <Layout>
          <ErrorBoundaryWrapper>{this.state.error.message}</ErrorBoundaryWrapper>
        </Layout>
      )
    }
    return this.props.children
  }
}
