import { Config } from './types/config'
import { createRoot, Root } from 'react-dom/client'
import pkgVersion from './version'
import { QRCode } from '@components/QRCodeModal/QRCode'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme'
import { ConfigContext } from '@providers/configProvider'
import { ErrorBoundary } from '@components/ErrorBoundary'
import { store } from './store'
import { Provider } from 'react-redux'

interface IVMWidgetQrCode {
  config?: Config
  init: () => void
  unmount: () => void
}

class VMWidgetQRCode implements IVMWidgetQrCode {
  public config: Config
  private root: Root | null
  constructor(config: Config) {
    this.config = config
    const container = document.querySelector(this.config.selector)
    this.root = container ? createRoot(container) : null
  }

  async init() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    if (!this.root) {
      throw new Error(
        `You requested Virtual mirror QR code to render inside the element with the selector ${this.config.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
      )
    }

    this.root.render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ConfigContext.Provider value={this.config}>
              <QRCode />
            </ConfigContext.Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    )
  }

  async unmount() {
    if (this.root) {
      this.root.unmount()
    }
  }

  static new(config: Config) {
    return new VMWidgetQRCode(config)
  }

  static version() {
    return pkgVersion
  }
}

export { VMWidgetQRCode }
