import React from 'react'
import { FrameCatalog } from '@components/Carousel/FrameCatalog'
import { ColorsCatalog } from '@components/Carousel/ColorsCatalog'
import { TransitionViewProps } from '../TransitionView/index'
import { LensCatalog } from '@components/Carousel/LensCatalog'
import { ActivePanelName } from '@customTypes/transition'

interface CarouselProps extends TransitionViewProps {
  activeName: ActivePanelName | null
}

export const Carousel: React.FC<CarouselProps> = ({
  activeName,

  // Frame Carousel
  products,
  selectedUpc,
  onChangeUpc,

  // Color Carousel
  colors,
  selectedColor,
  onChangeColor,

  // Lens Carousel
  lenses,
  selectedLens,
  onChangeLens,
}) => {
  const renderItems = () => {
    switch (activeName) {
      case 'lens':
        return (
          <LensCatalog
            lenses={lenses}
            selectedLens={selectedLens}
            onChangeLens={onChangeLens}
          />)
      case 'color':
        return (
          <ColorsCatalog
            colors={colors}
            selectedColor={selectedColor}
            onChangeColor={onChangeColor}
          />
        )
      case 'frame':
        return (
          <FrameCatalog
            items={products || []}
            selectedUpc={selectedUpc}
            onChange={onChangeUpc}
          />)
      default:
        return null
    }
  }

  return (
    <>
      {renderItems()}
    </>
  )
}
