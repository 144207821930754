import {
  Boolean,
  Number,
  String,
  Literal,
  Array,
  Record,
  Union,
  Undefined,
  Optional,
  Null,
} from 'runtypes'

const Analytics = Record({
  adobeSessionId: Optional(Union(String, Undefined)),
  pagePlatform: Optional(Union(String, Undefined)),
  pageBrand: Optional(Union(String, Undefined)),
  storeId: Optional(Union(String, Undefined)),
  sourcePosition: Optional(Union(String, Undefined)),
  pageEnvironment: Optional(Union(String, Undefined)),
  storeRegion: Optional(Union(String, Undefined)),
  storeCompany: Optional(Union(String, Undefined)),
  storeGlobalId: Optional(Union(String, Undefined)),
  source: Optional(Union(String, Undefined)),
  style: Optional(Union(String, Undefined)),
})

const AvailableColor = Union(
  Literal('SIGNATURE-GEN8-AMBER'),
  Literal('SIGNATURE-GEN8-AMETHYST'),
  Literal('SIGNATURE-GEN8-BROWN'),
  Literal('SIGNATURE-GEN8-EMERALD'),
  Literal('SIGNATURE-GEN8-GRAY'),
  Literal('SIGNATURE-GEN8-GGREEN'),
  Literal('SIGNATURE-GEN8-SAPPHIRE'),
  Literal('XTRACTIVE-NEWGEN-BROWN'),
  Literal('XTRACTIVE-NEWGEN-GRAY'),
  Literal('XTRACTIVE-NEWGEN-GREEN'),
  Literal('XTRACTIVE-GOLD-MIRROR'),
  Literal('XTRACTIVE-PINK-MIRROR'),
  Literal('XTRACTIVE-SILVER-MIRROR'),
  Literal('XTRACTIVE-RED-MIRROR'),
  Literal('XTRACTIVE-NEWGEN-GREEN-MIRROR'),
  Literal('XTRACTIVE-NEWGEN-BLUE-MIRROR'),
  Literal('XTRACTIVE-VANTAGE-POLARIZED'),
  Literal('SIGNATURE-GRAY'),
  Literal('SIGNATURE-BROWN'),
  Literal('SIGNATURE-GREEN'),
  Literal('SIGNATURE-SAPPHIRE'),
  Literal('SIGNATURE-AMETHYST'),
  Literal('SIGNATURE-EMERALD'),
  Literal('SIGNATURE-AMBER'),
  Literal('XTRACTIVE-GRAY'),
  Literal('XTRACTIVE-BROWN'),
  Literal('XTRACTIVE-GREEN'),
  Literal('0835C'),
  Literal('0834C'),
  Literal('0836C'),
  Literal('0838C'),
  Literal('0837C'),
  Literal('XTRACTIVE-POL-GRAY')
)

const Brand = Record({
  name: String,
  logoUrl: String,
})

const TransitionLens = Record({
  isEvolve: Optional(Boolean),
  defaultColor: Optional(String),
  defaultType: Optional(String),
  defaultAlphaLevel: Optional(Number),
  availableColors: Array(AvailableColor),
})

const Product = Record({
  upc: String,
  code: String,
  name: String,
  colorCode: String,
  thumbnailUrl: String,
  brand: Brand,
  lensColorLabel: Optional(String),
  frameColorLabel: Optional(String),
  styleName: Optional(String),
  size: Optional(String),
  category: Optional(String),
  isCustom: Optional(Boolean),
  isTransition: Optional(Boolean),
  transitionLens: Optional(TransitionLens),
})

const Store = Record({
  id: String,
  storeId: String,
  type: String,
  name: Optional(String),
  address: Optional(String),
  standAlone: Optional(Boolean),
  region: Optional(String),
  countryId: Optional(String),
  globalStoreId: Optional(String),
})

const VtoMode = Record({
  isTryOnEnabled: Boolean,
})

export const VMData = Record({
  store: Store,
  style: String,
  products: Array(Product),
  analytics: Analytics,
  glassesEnv: Optional(Union(String, Undefined, Null)),
  vmmvModes: Optional(VtoMode),
})
