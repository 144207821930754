import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'
import styled from 'styled-components'
import { GlassesIcon } from './GlassesIcon'

const StyledContainer = styled.div`
  position: relative;
`

const NoLine = styled.div`
  width: ${pxToEm(1)};
  height: ${pxToEm(30)};
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.palette.black};
  position: absolute;
  top: 0;
  left: 50%;
`

export const NoGlassesIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <StyledContainer>
      <GlassesIcon width={width} height={height} fill={fill} />
      <NoLine />
    </StyledContainer>
  )
}
