import styled from 'styled-components'

export const StyledContainer = styled.div`
  .loading-text,
  .loading-fraction,
  #notification {
    font: ${({ theme }) => theme.fonts.headingMediumBold};
  }

  .vm-swipeable-container #notification {
    bottom: 30%;

    ${({ theme }) => theme.media.small} {
      bottom: 25%;
    }
  }
`
