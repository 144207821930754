import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { Button } from '../core/Button'
import React from 'react'
import { setVisibilityPrivacyPolicyModal } from '@store/actions/ui'
import { useTranslation } from 'react-i18next'
import { ButtonsWrapper, StyledContent } from './styles'
import { PrivacyPolicyWrapper } from './PrivacyPolicyWrapper'

export const PrivacyPolicyModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isPrivacyPolicyModalVisible = useSelector((s: AppState) => s.ui.isPrivacyPolicyModalVisible)

  if (!isPrivacyPolicyModalVisible) {
    return null
  }

  const renderCtaContent = () => (
    <ButtonsWrapper>
      <Button
        onClick={() => {
          dispatch(setVisibilityPrivacyPolicyModal(false))
        }}
      >
        {t('RETURN')}
      </Button>
    </ButtonsWrapper>
  )

  return (
    <PrivacyPolicyWrapper
      title={t('PRIVACY_AGREEMENT_TITLE')}
      ctaContent={renderCtaContent()}
      content={
        <>
          <StyledContent> {t('PRIVACY_FIRST_AGREEMENT')}</StyledContent>
          <StyledContent
            dangerouslySetInnerHTML={{ __html: t('PRIVACY_AGREEMENT_TEXT') }}
          ></StyledContent>
        </>
      }
      className="vmmv-privacy-policy-modal"
    />
  )
}
