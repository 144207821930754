import { ChooseFrameIcon, ChooseColorIcon, ChooseLensIcon } from '@components/core/Icons'
import { ActivePanelName } from '@customTypes/transition'
import { useAnimation } from '@hooks/useAnimationApp'
import {
  trackClickTransitionColor,
  trackClickTransitionFrame,
  trackClickTransitionLens,
} from '@libs/analytics'
import { delay } from 'lodash'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionsWrapper, OptionStyled, ActionContainer, Label } from './styles'

type OptionValue = {
  name: ActivePanelName
  logo: JSX.Element
  label: string
  analytics: () => void
}

const useLeftSideOptions = (): OptionValue[] => {
  const { t } = useTranslation()

  return [
    {
      name: 'lens',
      logo: <ChooseLensIcon width="2em" height="2em" />,
      label: t('transitions.choose-lens'),
      analytics: trackClickTransitionLens,
    },
    {
      name: 'color',
      logo: <ChooseColorIcon width="2em" height="2em" />,
      label: t('transitions.choose-lens-color'),
      analytics: trackClickTransitionColor,
    },
    {
      name: 'frame',
      logo: <ChooseFrameIcon width="2em" height="2em" />,
      label: t('transitions.choose-frame'),
      analytics: trackClickTransitionFrame,
    },
  ]
}

interface OptionProps {
  option: OptionValue
  isActive?: boolean
  onChange: (name: ActivePanelName) => void
}

const TIME_TO_HIDE_TOOLTIP = 2000
const Option: React.FC<OptionProps> = ({ option, onChange, isActive }) => {
  const [expand, setExpand] = useState(false)
  const timer = useRef<number | null>(null)

  useEffect(() => {
    if (isActive) {
      setExpand(true)
      timer.current = delay(() => setExpand(false), TIME_TO_HIDE_TOOLTIP)
    } else {
      setExpand(false)
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [isActive])

  const cbOnStart = useCallback(() => {
    setExpand(true)
  }, [])

  const cbOnEnd = useCallback(() => {
    setExpand(false)
  }, [])

  useAnimation(true, !expand, cbOnStart, cbOnEnd, 'transitionLabel')

  return (
    <OptionStyled
      onClick={() => {
        option.analytics()
        onChange(option.name)
      }}
      isActive={isActive}
      expand={expand}
    >
      {option.logo}
      <Label toggleText={expand}>{option.label}</Label>
    </OptionStyled>
  )
}

interface LeftSideActionsProps {
  optionActiveName: ActivePanelName | null
  setActiveName: (name: ActivePanelName) => void
}

export const LeftSideActions: React.FC<LeftSideActionsProps> = ({
  optionActiveName,
  setActiveName,
}) => {
  const options = useLeftSideOptions()

  return (
    <ActionContainer className="vmmv-left-side-actions">
      <OptionsWrapper>
        {options.map(o => {
          return (
            <Fragment key={o.name}>
              <Option isActive={optionActiveName === o.name} onChange={setActiveName} option={o} />
            </Fragment>
          )
        })}
      </OptionsWrapper>
    </ActionContainer>
  )
}
