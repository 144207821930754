import { Product } from '@customTypes/product'
import { useCallback, useMemo } from 'react'
import { useConfig } from '@providers/configProvider'
import { useAddToCart as useAddToCartAnalytics } from '@libs/analytics'

interface UseAddToCart {
  product: Product
}

export const useAddToCart = ({ product }: UseAddToCart) => {
  const { prices, onAddToBag } = useConfig()

  const currentProductPrice = useMemo(() => {
    return prices?.find(p => p.upc === product.upc)
  }, [product.upc, prices])

  const trackAddToCart = useAddToCartAnalytics(product, currentProductPrice)

  const addToCart = useCallback(() => {
    onAddToBag && onAddToBag(product)
    trackAddToCart()
  }, [product, onAddToBag, trackAddToCart])

  return { addToCart, currentProductPrice }
}
