import { IconProps } from '@customTypes/icons'
import React from 'react'
export const SaveIcon: React.FC<IconProps> = ({ width = '1em', height = '1em', fill = '#000' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16.962 10.295-.59-.59-5.955 5.956V0h-.833v15.66L3.628 9.706l-.589.59L10 17.255l6.962-6.96z"
        fill={fill}
      />
      <path
        d="M19.167 14.584v2.083c0 1.38-1.122 2.5-2.5 2.5H3.333a2.502 2.502 0 0 1-2.5-2.5v-2.083H0v2.083a3.337 3.337 0 0 0 3.333 3.334h13.334A3.337 3.337 0 0 0 20 16.667v-2.083h-.833z"
        fill={fill}
      />
    </svg>
  )
}
