import { useCallback, useEffect, useMemo } from 'react'
import { useConfig } from '@providers/configProvider'
import { VirtualMirrorCatalogue } from '@luxottica/virtual-mirror'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { setVmProducts } from '@store/actions/products'

export const useTransitionSupport = () => {
  const dispatch = useDispatch()

  const { products, vmInit } = useConfig()
  const vmProducts = useSelector((s: AppState) => s.products.vmProducts)

  const upcs = useMemo(() => {
    return products.filter(p => p.isTransition).map(p => p.upc)
  }, [products])

  const getIsUpcSupported = useCallback(async () => {
    try {
      const catalogueUpcs = VirtualMirrorCatalogue.build({
        // Use any due to impossible to import GlassesEnvironment type, required for env variable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...((vmInit.glassesEnv && { catalogueEnvironment: vmInit.glassesEnv }) as any),
        key: vmInit.key,
      }).isUpcSupported(...upcs)

      const updatedProducts = products.map(async p => {
        return {
          ...p,
          isTransition: p.isTransition ? (await catalogueUpcs?.[p.upc]).isTransition() : false,
        }
      })

      dispatch(setVmProducts(await Promise.all(updatedProducts)))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error checking if upc is supported ${error}`)
    }
  }, [dispatch, products, upcs, vmInit])

  useEffect(() => {
    if (upcs.length && !vmProducts.length) {
      getIsUpcSupported()
    } else {
      dispatch(setVmProducts(products))
    }
  }, [getIsUpcSupported, upcs, vmProducts.length, products, dispatch])
}
