import { Product } from '@customTypes/product'
import { SET_VM_PRODUCTS, ProductsActionTypes } from '../actions/products'

export interface ProductsState {
  vmProducts: Product[]
}

const initialState: ProductsState = {
  vmProducts: [],
}

const products = (state = initialState, action: ProductsActionTypes) => {
  switch (action.type) {
    case SET_VM_PRODUCTS: {
      return {
        ...state,
        vmProducts: action.payload.vmProducts,
      }
    }
    default:
      return state
  }
}

export default products
