import { useState, useEffect } from 'react'
import { Color, Product } from '@customTypes/product'
import { uniq } from 'lodash'
import { notUndefined } from '@libs/utils'
import { useTransitionSupport } from './useTransitionSupport'
import { useConfig } from '@providers/configProvider'
import { AppState } from '@store/index'
import { useSelector } from 'react-redux'

export const useVirtualMirrorApp = () => {
  // App
  const [isReady, setIsReady] = useState(false)
  const [isVMInitialized, setIsVMInitialized] = useState(false)
  const [isPreparingScreenshot, setIsPreparingScreenshot] = useState(false)

  // Products
  useTransitionSupport()
  const { products } = useConfig()
  const vmProducts: Product[] = useSelector((s: AppState) => s.products.vmProducts)
  const [currentProduct, setCurrentProduct] = useState(products[0])
  const changeUpc = (upc: string) => {
    const product = vmProducts.find(p => p.upc === upc)
    if (product) {
      setCurrentProduct(product)
    }
  }

  // Colors
  const [colors, setColors] = useState<Color[]>([])
  const [currentColor, setCurrentColor] = useState<string>('')
  const [colorsCatalog, setColorsCatalog] = useState<Color[]>([])

  // Lenses
  const [currentLens, setCurrentLens] = useState<string>()
  const [lensCatalog, setLensCatalog] = useState<string[]>([])
  const [lensActivation, setLensActivation] = useState(1)

  const changeLens = (lens: string) => {
    setCurrentLens(lens)
    if (colorsCatalog.length > 0) {
      const colorsFiltered = colors
        .filter(color => color.lensName === lens)
        .filter(({ colorCode }) =>
          currentProduct.transitionLens?.availableColors.includes(colorCode)
        )
      if (colorsFiltered.length > 0) {
        setColorsCatalog(colorsFiltered)
        setCurrentColor(colorsFiltered[0].colorCode)
      }
    }
  }

  // UserId
  const [userId, setUserID] = useState('')

  // Transition view
  const [isTransitionActive, setTransitionActive] = useState(false)

  const toggleTransitionActive = () => {
    setTransitionActive(c => !c)
  }

  useEffect(() => {
    const selectedUpc = vmProducts.find(i => i.isSelected) || vmProducts[0]
    if (selectedUpc) {
      setCurrentProduct(selectedUpc)
    }
  }, [vmProducts])

  useEffect(() => {
    const debounce = setTimeout(() => {
      const lenses = uniq(colors.map(c => c.lensName))
      const availColors = colors.filter(c =>
        currentProduct?.transitionLens?.availableColors.includes(c?.colorCode)
      )

      const filteredLenses = lenses
        .filter(lens => availColors.some(color => color.lensName === lens))
        .filter(notUndefined)

      const currentLens = filteredLenses[0]

      const filteredColors = availColors.filter(color => color.lensName === currentLens)
      const color = filteredColors.length > 0 ? filteredColors[0]?.colorCode : ''
      setCurrentColor(color)
      setCurrentLens(currentLens)
      setColorsCatalog(filteredColors)
      setLensCatalog(filteredLenses)
    }, 300)

    return () => {
      clearTimeout(debounce)
    }
  }, [colors, currentProduct])

  return {
    isReady,
    isPreparingScreenshot,

    isVMInitialized,
    currentProduct,

    colors,
    currentColor,
    colorsCatalog,

    currentLens,
    lensCatalog,
    lensActivation,

    userId,

    isTransitionActive,

    vmProducts,

    setIsReady,
    setIsVMInitialized,
    setIsPreparingScreenshot,

    setCurrentProduct,
    changeUpc,

    setColors,
    setCurrentColor,
    setColorsCatalog,

    setLensCatalog,
    setLensActivation,
    changeLens,

    setUserID,

    toggleTransitionActive,
  }
}
