import {
  CatalogContainer,
  CatalogItem,
  CatalogItemInner,
  CatalogItemWrapper,
  StyledLabelColor,
  CarouselWrapper,
} from '../styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'

import { SwiperOptions } from 'swiper'
import { Color } from '@customTypes/product'
import { trackClickColorCarousel } from '@libs/analytics'

const TIME_TO_HIDE_TOOLTIP = 2000

const swiperParams: SwiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 5,
  centeredSlides: true,
  slideToClickedSlide: true, // see: LVM-283
}

interface ColorsCatalogProps {
  colors: Color[]
  selectedColor: string
  onChangeColor: (color: string) => void
}

export const ColorsCatalog: React.FC<ColorsCatalogProps> = ({
  colors,
  selectedColor,
  onChangeColor,
}) => {
  const selectedIndex = colors.findIndex(el => el.colorCode === selectedColor)
  const [currentSlide, setCurrentSlide] = useState(selectedIndex || 0)
  const [isTooltipVisible, setTooltipVisibility] = useState(true)
  const swiper = useRef<SwiperInstance>(null)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const setSwiper = (swiperInstance: SwiperInstance) => {
    swiper.current = swiperInstance
  }

  const changeColor = useCallback(
    (color: string) => {
      onChangeColor(color)
      trackClickColorCarousel()
    },
    [onChangeColor]
  )

  useEffect(() => {
    setTooltipVisibility(true)
    timer.current = setTimeout(() => {
      setTooltipVisibility(false)
      timer.current && clearTimeout(timer.current)
      timer.current = null
    }, TIME_TO_HIDE_TOOLTIP)

    swiper.current?.on('slideChangeTransitionStart', () => {
      if (swiper.current) {
        setCurrentSlide(swiper.current.realIndex)
      }
    })

    if (selectedColor != colors[currentSlide]?.colorCode) {
      changeColor(colors[currentSlide]?.colorCode)
    }

    return function cleanup() {
      swiper.current?.off('click')
      swiper.current?.off('slideChangeTransitionEnd')
    }
  }, [swiper, colors, selectedColor, currentSlide, changeColor])

  const selectedColorLabel = colors.find(color => color?.colorCode === selectedColor)?.colorName

  return (
    <CarouselWrapper>
      <CatalogContainer>
        <Swiper {...swiperParams} initialSlide={currentSlide} getSwiper={setSwiper}>
          {colors.map(({ colorCode, colorValue }) => (
            <CatalogItemWrapper key={colorCode}>
              <CatalogItemInner>
                <CatalogItem
                  backgroundColor={colorValue}
                  isActive={colorCode === colors[currentSlide]?.colorCode}
                />
              </CatalogItemInner>
            </CatalogItemWrapper>
          ))}
        </Swiper>
        {
          <StyledLabelColor className={isTooltipVisible ? 'show' : 'hide'}>
            {selectedColorLabel}
          </StyledLabelColor>
        }
      </CatalogContainer>
    </CarouselWrapper>
  )
}
