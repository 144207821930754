import { SpinnerWrapper, Spinner } from '@components/PictureMode/TakePicture/styles'
import { Screenshot } from '@customTypes/screenshot'
import { useState } from 'react'
import { StyledImage, ImageWrapper } from './styles'

interface ScreenshotImageProps {
  image?: Screenshot
}

export const ScreenshotImage: React.FC<ScreenshotImageProps> = ({ image }) => {
  const [imageLoading, setImageLoading] = useState(true)

  return (
    <ImageWrapper>
      {imageLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <StyledImage src={image?.imgURI} onLoad={() => setImageLoading(false)} />
    </ImageWrapper>
  )
}
