import { CloseScreenshotIcon } from '@components/core/Icons'
import React, { useCallback } from 'react'
import { Modal } from '@components/core/Modal'
import {
  ModalCloseButton,
  ModalWindowContainer,
  AddPrescriptionLensesButton,
  AddToCartButton,
  ButtonsContainer,
  ModalContainer,
  Text,
  Title,
  TitleContainer,
} from './styles'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { useConfig } from '@providers/configProvider'
import { Product } from '@customTypes/product'
import { useAddToCart } from '@hooks/useAddToCart'

interface AddPrescriptionModalProps {
  product: Product
}

export const AddPrescriptionModal: React.FC<AddPrescriptionModalProps> = ({ product }) => {
  const { onAddPrescriptionLenses, addToCartText } = useConfig()
  const { addToCart } = useAddToCart({ product })
  const { isModalVisible, toggleModalVisibility } = useModal()
  const { t } = useTranslation()

  const handleAddPrescriptionLenses = useCallback(() => {
    onAddPrescriptionLenses && onAddPrescriptionLenses(product)
  }, [onAddPrescriptionLenses, product])

  const handleAddToCart = useCallback(() => {
    addToCart && addToCart()
  }, [addToCart])

  return (
    <Modal
      visible={isModalVisible}
      onOverlayClick={toggleModalVisibility}
      className={'vmmv-modal-container'}
    >
      <ModalWindowContainer className={'vmmv-add-prescription-modal-container'}>
        <ModalCloseButton
          onClick={toggleModalVisibility}
          className={'vmmv-add-prescription-modal-close-modal-button'}
        >
          <CloseScreenshotIcon fill={'#000'} stroke={'#000'} width={'24'} height={'24'} />
        </ModalCloseButton>
        <ModalContainer>
          <TitleContainer>
            <Title>
              <h2 className={'vmmv-add-prescription-modal-title'}>
                {t('live-try-on-ecommerce.choose-your-need')}
              </h2>
            </Title>
            <Text className={'vmmv-add-prescription-modal-text'}>
              {t('live-try-on-ecommerce.choose-your-prescription-lenses')}
            </Text>
          </TitleContainer>
          <ButtonsContainer>
            <AddPrescriptionLensesButton
              onClick={handleAddPrescriptionLenses}
              className={'vmmv-add-prescription-modal-prescription-button'}
            >
              {t('live-try-on-ecommerce.add-prescription-lenses')}
            </AddPrescriptionLensesButton>
            <AddToCartButton
              onClick={handleAddToCart}
              className={'vmmv-add-prescription-modal-add-to-cart-button'}
            >
              {addToCartText || t('general.add-to-cart')}
            </AddToCartButton>
          </ButtonsContainer>
        </ModalContainer>
      </ModalWindowContainer>
    </Modal>
  )
}
