import React from 'react'

export const TransitionsIcon: React.FC = () => {
  return (
    <svg
      width="75"
      height="20"
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: '3px', marginRight: '3px' }}
    >
      <path
        d="M51.7933 15.488C54.3736 15.488 56.4653 13.3963 56.4653 10.8161C56.4653 9.09227 55.5318 7.58654 54.1427 6.77686L49.4888 14.881C50.1688 15.2674 50.9553 15.488 51.7933 15.488Z"
        fill="white"
      />
      <circle cx="51.7931" cy="10.8155" r="4.17196" stroke="white" />
      <path
        d="M45.6056 20.4951L56.7135 1.25554L57.9696 1.24052L46.8617 20.4801L45.6056 20.4951Z"
        fill="url(#paint0_linear_3584_19130)"
      />
      <path
        d="M16.9253 10.0037C16.9097 9.60675 16.9561 9.22857 16.8732 8.85402C16.7221 8.17516 16.2958 7.77824 15.6198 7.64299C15.1424 7.54727 14.6661 7.58733 14.1929 7.68097C13.8098 7.76221 13.437 7.88574 13.0813 8.04927C13.0584 8.05915 13.0365 8.07216 13.013 8.08048C12.9255 8.11169 12.8812 8.09192 12.8525 8.00089C12.8046 7.85055 12.7613 7.69865 12.716 7.54727C12.6993 7.49161 12.6826 7.43595 12.667 7.38029C12.6216 7.22163 12.6336 7.18781 12.79 7.12019C12.9932 7.03435 13.1965 6.94696 13.4055 6.87829C14.101 6.64105 14.8351 6.53693 15.5693 6.57137C15.9603 6.5885 16.3468 6.66191 16.7168 6.78934C17.5481 7.08585 18.075 7.6638 18.3126 8.50601C18.4086 8.85963 18.4553 9.22479 18.4512 9.59115C18.4512 11.3203 18.4512 13.0496 18.4512 14.7791C18.4512 14.7916 18.4512 14.8041 18.4512 14.8166C18.4481 14.9425 18.434 14.9576 18.3043 14.9581H17.3662C17.2505 14.9581 17.2453 14.9523 17.213 14.8374L17.1359 14.5601C17.1217 14.5629 17.108 14.5676 17.0952 14.5742C16.6736 14.8863 16.1941 15.0574 15.6844 15.1464C14.9632 15.2762 14.2206 15.2189 13.5279 14.9799C12.8973 14.7614 12.4002 14.3775 12.0948 13.7736C11.8759 13.3408 11.8003 12.8757 11.8139 12.3956C11.8305 11.7984 12.0103 11.2605 12.4106 10.8069C12.7498 10.4224 13.1788 10.1753 13.6613 10.0141C14.2049 9.83252 14.7657 9.77426 15.3358 9.78518C15.8231 9.80129 16.3075 9.86577 16.782 9.97766C16.8211 9.98494 16.8633 9.99222 16.9253 10.0037ZM16.9175 10.951C16.8966 10.9426 16.8857 10.9369 16.8742 10.9333C16.8539 10.927 16.8341 10.9213 16.8138 10.9166C16.2164 10.7765 15.5989 10.7425 14.9898 10.8162C14.7076 10.8451 14.4334 10.9261 14.1809 11.055C14.0155 11.1399 13.8703 11.2595 13.7553 11.4054C13.6403 11.5514 13.5581 11.7204 13.5144 11.9008C13.4499 12.164 13.4335 12.4366 13.4659 12.7056C13.5227 13.2778 13.7927 13.7028 14.3404 13.9218C14.7782 14.0971 15.2383 14.1362 15.7032 14.0914C16.1248 14.0508 16.5073 13.8865 16.8711 13.6753C16.8873 13.6644 16.9028 13.6526 16.9175 13.6399V10.951Z"
        fill="white"
      />
      <path
        d="M21.6277 7.40291C21.6474 7.39423 21.6665 7.38432 21.685 7.37326C22.1931 6.99039 22.7701 6.7615 23.3881 6.63249C24.0381 6.48917 24.7129 6.50272 25.3565 6.67203C25.9058 6.81976 26.378 7.09391 26.7339 7.54596C27.0045 7.89212 27.1789 8.30338 27.2394 8.73827C27.2724 8.95174 27.2898 9.16733 27.2916 9.38332C27.2954 11.1631 27.2954 12.9427 27.2916 14.7221V14.7596C27.2916 14.937 27.2863 14.9422 27.1092 14.9422H25.8839C25.8589 14.9422 25.8318 14.9422 25.8089 14.9422C25.7245 14.9354 25.7015 14.9125 25.6963 14.8251C25.6963 14.7882 25.6963 14.7502 25.6963 14.7128C25.6963 12.967 25.6963 11.2208 25.6963 9.47435C25.6995 9.2454 25.6718 9.01707 25.614 8.79549C25.4529 8.20402 25.0543 7.85913 24.4628 7.75197C23.533 7.58134 22.6773 7.78266 21.8924 8.30286C21.8486 8.32864 21.813 8.36624 21.7897 8.41134C21.7664 8.45644 21.7563 8.50719 21.7606 8.55776C21.7644 10.6157 21.7653 12.6738 21.7632 14.732C21.7632 14.9427 21.7632 14.9432 21.5459 14.9432H20.3702C20.3368 14.9451 20.3034 14.9451 20.2701 14.9432C20.1982 14.936 20.1752 14.9131 20.1659 14.8423C20.1627 14.8095 20.1659 14.7757 20.1659 14.7424C20.1659 12.1647 20.1659 9.58689 20.1659 7.00912V6.98415C20.1659 6.81092 20.1773 6.8 20.3504 6.79948C20.6922 6.79948 21.0336 6.79948 21.3755 6.79948C21.5031 6.79948 21.5172 6.813 21.5401 6.93525C21.5683 7.08455 21.5964 7.2354 21.6277 7.40291Z"
        fill="white"
      />
      <path
        d="M59.4769 7.42098C59.5097 7.40225 59.5342 7.39132 59.5555 7.37624C60.1101 6.9684 60.7375 6.73587 61.4108 6.61882C62.0157 6.50852 62.6377 6.53555 63.2307 6.69789C63.8008 6.85395 64.284 7.15099 64.6378 7.63426C64.8912 7.98759 65.0507 8.3992 65.1016 8.83072C65.128 9.03271 65.141 9.23619 65.1407 9.43988C65.1439 11.2023 65.1439 12.9651 65.1407 14.7282C65.1407 14.941 65.1407 14.9415 64.925 14.9415H63.7373C63.7039 14.9415 63.6706 14.9415 63.6372 14.9415C63.6246 14.9417 63.6121 14.9392 63.6005 14.9341C63.589 14.929 63.5787 14.9215 63.5703 14.9121C63.5619 14.9026 63.5557 14.8915 63.552 14.8794C63.5484 14.8674 63.5474 14.8547 63.5491 14.8422C63.5491 14.8047 63.5491 14.7673 63.5491 14.7298C63.5491 12.992 63.5491 11.2542 63.5491 9.51635C63.5529 9.25836 63.5177 9.00128 63.4449 8.75373C63.2724 8.19764 62.8821 7.87823 62.3223 7.77419C61.3983 7.602 60.5442 7.79396 59.7619 8.31052C59.7307 8.33081 59.7025 8.3563 59.6718 8.37711C59.6499 8.38942 59.6323 8.40802 59.6213 8.43049C59.6102 8.45295 59.6062 8.47822 59.6097 8.50299C59.6116 8.53626 59.6116 8.5696 59.6097 8.60287V14.7007C59.6097 14.9436 59.6296 14.9441 59.3612 14.9426C58.9776 14.9426 58.594 14.9426 58.211 14.9426C58.1818 14.9441 58.1526 14.9441 58.1234 14.9426C58.0432 14.9353 58.0254 14.916 58.0192 14.8323C58.0192 14.8037 58.0192 14.774 58.0192 14.7454V7.01106C58.0192 6.99441 58.0192 6.97776 58.0192 6.95904C58.0223 6.83263 58.0369 6.81702 58.1662 6.81702C58.5205 6.81702 58.8747 6.81702 59.2288 6.81702C59.357 6.81702 59.3643 6.82274 59.3898 6.95435C59.4195 7.10677 59.4466 7.25711 59.4769 7.42098Z"
        fill="white"
      />
      <path
        d="M3.45523 4.89263H0.229307C0.195953 4.89263 0.162599 4.89263 0.129245 4.89263C0.0250153 4.88743 0.0020846 4.86662 0.00156345 4.76726C-0.000521151 4.47179 -0.000521151 4.177 0.00156345 3.88292C0.00156345 3.77888 0.0239731 3.75859 0.125076 3.75391C0.150092 3.75391 0.177191 3.75391 0.200122 3.75391H8.53854C8.66049 3.75703 8.68133 3.7768 8.68185 3.90268C8.68185 4.18567 8.68185 4.46832 8.68185 4.75061C8.68185 4.86558 8.65684 4.88951 8.54375 4.89419C8.51039 4.89419 8.47704 4.89419 8.44369 4.89419H5.10832V14.7322C5.10832 14.9403 5.10832 14.9403 4.891 14.9403H3.64024C3.60689 14.9403 3.57353 14.9403 3.54018 14.9403C3.52863 14.9407 3.51713 14.9387 3.50639 14.9344C3.49566 14.9301 3.48593 14.9237 3.47781 14.9155C3.46969 14.9073 3.46335 14.8975 3.4592 14.8867C3.45504 14.876 3.45316 14.8645 3.45367 14.8529C3.45367 14.8113 3.45367 14.7697 3.45367 14.7281V4.89263H3.45523Z"
        fill="white"
      />
      <path
        d="M69.2579 15.2384C68.1817 15.2223 67.2108 14.9086 66.3728 14.2209C66.305 14.1658 66.2404 14.1065 66.1784 14.0451C66.1163 13.9837 66.1106 13.9411 66.1653 13.8703C66.3422 13.6442 66.5209 13.4188 66.7016 13.194C66.7605 13.1207 66.7892 13.1217 66.8632 13.194C67.0793 13.4083 67.3228 13.593 67.5876 13.7434C68.1385 14.0554 68.7701 14.1965 69.4017 14.1486C69.6268 14.1344 69.847 14.0766 70.05 13.9785C70.7192 13.6534 70.7572 12.8965 70.4669 12.4611C70.3611 12.3062 70.2305 12.1698 70.0802 12.0574C69.7884 11.8322 69.459 11.6709 69.1302 11.5086C68.7237 11.3083 68.3135 11.1143 67.9117 10.9041C67.5568 10.7268 67.2351 10.49 66.9606 10.2039C66.3352 9.52767 66.1789 8.75153 66.4989 7.89527C66.6933 7.37247 67.0987 7.03694 67.6037 6.81846C67.9968 6.65497 68.4177 6.56826 68.8435 6.56304C69.8666 6.52221 70.8772 6.79822 71.737 7.35322C71.8037 7.39588 71.8683 7.44166 71.9314 7.48952C71.9944 7.53738 72.0121 7.57951 71.9684 7.64558C71.8172 7.87967 71.6642 8.11237 71.5092 8.34369C71.4665 8.40715 71.4258 8.41391 71.3597 8.37854C71.3299 8.36293 71.3039 8.34213 71.2763 8.32652C70.7588 7.98787 70.2048 7.73661 69.5924 7.62841C69.2564 7.56119 68.91 7.56509 68.5757 7.63985C68.5553 7.64453 68.5355 7.6513 68.5157 7.65702C67.9326 7.83753 67.6986 8.39727 67.98 8.9388C68.0699 9.10367 68.1916 9.24917 68.338 9.36692C68.5944 9.57262 68.8743 9.74724 69.1719 9.88713C69.544 10.0728 69.9202 10.2513 70.2918 10.438C70.633 10.6033 70.9568 10.8022 71.2585 11.0316C71.5504 11.2594 71.8136 11.5174 71.971 11.8556C72.3577 12.6879 72.2545 13.4755 71.7104 14.2027C71.3643 14.6662 70.8797 14.9367 70.3299 15.0954C69.9813 15.1945 69.6202 15.2427 69.2579 15.2384V15.2384Z"
        fill="white"
      />
      <path
        d="M31.4551 15.2377C30.4154 15.2241 29.4716 14.9287 28.6471 14.2841C28.5585 14.215 28.4746 14.1395 28.3928 14.0631C28.311 13.9866 28.3026 13.945 28.3761 13.8508C28.5432 13.6382 28.7116 13.4265 28.8811 13.2156C28.9567 13.1215 28.9822 13.1194 29.0687 13.2042C29.2813 13.4161 29.5216 13.5983 29.7832 13.7457C30.334 14.0582 30.9657 14.1995 31.5973 14.1515C31.8266 14.1374 32.0507 14.0778 32.2566 13.9762C32.8981 13.6557 32.956 12.9311 32.6772 12.4858C32.563 12.3111 32.4172 12.1592 32.2472 12.0379C31.9721 11.8428 31.6791 11.674 31.3722 11.5338C30.9949 11.3497 30.6139 11.1697 30.2397 10.9803C29.9073 10.8152 29.5975 10.6082 29.3178 10.3644C28.9567 10.0435 28.6971 9.66007 28.5939 9.18304C28.36 8.09894 28.8717 7.1657 29.9145 6.77451C30.2753 6.6434 30.6553 6.57308 31.0392 6.56643C32.0239 6.52734 32.998 6.7808 33.8383 7.29471C33.9373 7.35557 34.0332 7.42268 34.127 7.49135C34.2052 7.54805 34.2104 7.57874 34.1593 7.65833C34.0124 7.88514 33.8638 8.11056 33.7137 8.33459C33.6559 8.42095 33.6178 8.42407 33.5157 8.3554C33.2365 8.16581 32.9386 8.00527 32.6266 7.8763C32.132 7.67342 31.6208 7.54909 31.0814 7.59227C30.9397 7.60263 30.7997 7.62987 30.6645 7.67342C30.1741 7.83572 29.9531 8.28257 30.1079 8.77625C30.1644 8.94681 30.2598 9.10193 30.3867 9.22934C30.5417 9.39294 30.7214 9.53126 30.9193 9.63926C31.2664 9.82705 31.6182 10.0076 31.972 10.1839C32.3478 10.3717 32.7282 10.5507 33.0847 10.7754C33.2999 10.9103 33.5026 11.0642 33.6903 11.2352C34.6424 12.105 34.5278 13.3915 33.9415 14.1655C33.5767 14.6462 33.0889 14.9375 32.5161 15.1019C32.1708 15.1975 31.8134 15.2432 31.4551 15.2377Z"
        fill="white"
      />
      <path
        d="M40.5372 6.81842H42.6437C42.6728 6.81842 42.7025 6.81842 42.7312 6.81842C42.8245 6.8231 42.8464 6.8439 42.8479 6.94066C42.8506 7.0858 42.8479 7.23146 42.8479 7.37711C42.8479 7.4978 42.8479 7.61797 42.8479 7.73865C42.8479 7.85934 42.8297 7.87235 42.7078 7.87599H40.6404C40.6357 7.91916 40.6289 7.9509 40.6289 7.98263C40.6289 9.53318 40.6289 11.0841 40.6289 12.6353C40.6226 12.9146 40.6713 13.1925 40.7722 13.4531C40.9749 13.9467 41.3502 14.198 41.8791 14.2282C42.2309 14.2485 42.565 14.1652 42.8912 14.0435C43.0595 13.9811 43.084 13.9915 43.1179 14.1652C43.1527 14.349 43.1888 14.5325 43.2263 14.7156C43.2508 14.8373 43.244 14.8514 43.1283 14.9076C42.8327 15.0515 42.5161 15.1479 42.1903 15.1931C41.6477 15.2691 41.1094 15.2483 40.5836 15.0797C39.7325 14.8087 39.2306 14.2276 39.0743 13.3558C39.0392 13.1552 39.0218 12.9519 39.0222 12.7482C39.0197 11.3433 39.021 9.93876 39.0258 8.53456C39.0279 7.34954 39.0312 6.16452 39.0357 4.9795C39.0357 4.88014 39.0357 4.78026 39.0394 4.68039C39.043 4.58051 39.0587 4.55814 39.1655 4.55762C39.5074 4.55762 39.8491 4.55762 40.1906 4.55762C40.3037 4.55762 40.3209 4.57166 40.3324 4.68611C40.3553 4.91344 40.373 5.14128 40.3933 5.36913C40.4354 5.83662 40.4776 6.3048 40.52 6.77368C40.5246 6.78898 40.5304 6.80393 40.5372 6.81842V6.81842Z"
        fill="white"
      />
      <path
        d="M8.93979 7.73669L8.99711 7.64981C9.37078 7.05938 9.91434 6.72281 10.5944 6.59693C11.0146 6.51973 11.4477 6.55253 11.8515 6.69212C11.9729 6.7327 11.9739 6.73426 11.9437 6.86067C11.8815 7.11938 11.8195 7.37775 11.7577 7.63577C11.7264 7.76478 11.716 7.76946 11.5867 7.73357C11.211 7.62953 10.8305 7.60092 10.4516 7.706C9.82626 7.87974 9.4255 8.29903 9.18942 8.88841C9.09571 9.12928 9.04935 9.3859 9.05288 9.64427C9.05288 11.3401 9.05288 13.0362 9.05288 14.7324C9.05288 14.9867 9.02682 14.9404 8.83973 14.9404C8.45616 14.9404 8.07311 14.9404 7.68955 14.9404C7.66036 14.9404 7.63118 14.9404 7.60199 14.9404C7.51548 14.9342 7.49099 14.9108 7.48786 14.8265C7.48525 14.7745 7.48786 14.7183 7.48786 14.6642C7.48786 12.6937 7.48786 10.7233 7.48786 8.75316C7.48786 8.1669 7.48786 7.58115 7.49099 6.99488C7.48968 6.96993 7.48968 6.94493 7.49099 6.91997C7.4988 6.84766 7.52121 6.82582 7.59209 6.81957C7.60877 6.81957 7.62544 6.81957 7.64421 6.81957H8.70683C8.8366 6.81957 8.84911 6.83258 8.86005 6.96003C8.87829 7.16811 8.89601 7.37619 8.91529 7.58115C8.9205 7.6264 8.92936 7.67322 8.93979 7.73669Z"
        fill="white"
      />
      <path
        d="M44.068 10.8862V7.00859C44.068 6.8208 44.068 6.8208 44.2551 6.8208H45.4537C45.6033 6.8208 45.6174 6.83433 45.6174 6.98519V14.7778C45.6174 14.9261 45.6012 14.9422 45.4522 14.9422H44.2285C44.2035 14.9438 44.1785 14.9438 44.1535 14.9422C44.1419 14.9423 44.1305 14.94 44.1199 14.9355C44.1093 14.931 44.0998 14.9244 44.0919 14.916C44.0839 14.9077 44.0778 14.8978 44.0739 14.887C44.07 14.8762 44.0683 14.8647 44.069 14.8532C44.069 14.8158 44.069 14.7783 44.069 14.7409L44.068 10.8862Z"
        fill="white"
      />
      <path
        d="M37.17 10.8877V14.7528C37.17 14.9386 37.1664 14.9422 36.9824 14.9422H35.8072C35.7744 14.9422 35.7405 14.9422 35.7077 14.9422C35.6961 14.9422 35.6846 14.9399 35.6739 14.9354C35.6633 14.9309 35.6536 14.9243 35.6455 14.916C35.6375 14.9077 35.6312 14.8979 35.627 14.8871C35.6228 14.8763 35.6208 14.8648 35.6211 14.8532C35.6211 14.8121 35.6211 14.7705 35.6211 14.7289V7.03356C35.6211 6.8208 35.6211 6.8208 35.8353 6.8208H37.0105C37.1669 6.8208 37.1711 6.82808 37.1716 6.98467L37.17 10.8877Z"
        fill="white"
      />
      <path
        d="M44.8534 4.14413C45.0244 4.14618 45.1909 4.19865 45.332 4.29495C45.4732 4.39125 45.5826 4.52706 45.6466 4.68531C45.7107 4.84355 45.7263 5.01716 45.6917 5.18428C45.6571 5.3514 45.5738 5.50457 45.4521 5.62452C45.3305 5.74447 45.1761 5.82583 45.0083 5.85838C44.8404 5.89092 44.6667 5.87319 44.509 5.80742C44.3512 5.74165 44.2165 5.63077 44.1217 5.48874C44.0269 5.34671 43.9763 5.17988 43.9763 5.00922C43.9761 4.89468 43.9987 4.78123 44.0428 4.67549C44.087 4.56976 44.1517 4.47384 44.2334 4.39333C44.315 4.31282 44.4119 4.24932 44.5183 4.20654C44.6248 4.16375 44.7387 4.14254 44.8534 4.14413V4.14413Z"
        fill="white"
      />
      <path
        d="M35.5348 4.99796C35.5366 4.8256 35.5899 4.6577 35.6878 4.5157C35.7857 4.3737 35.9238 4.26405 36.0844 4.20074C36.245 4.13743 36.4209 4.12334 36.5896 4.16027C36.7582 4.19719 36.9121 4.28346 37.0314 4.40805C37.1507 4.53265 37.2302 4.68991 37.2596 4.85977C37.2889 5.02964 37.2669 5.20438 37.1964 5.36171C37.1258 5.51903 37.0098 5.65179 36.8633 5.74303C36.7168 5.83428 36.5464 5.87987 36.3738 5.87398C36.1463 5.86839 35.9303 5.77308 35.7731 5.6089C35.6158 5.44472 35.5301 5.22505 35.5348 4.99796Z"
        fill="white"
      />
      <path
        d="M74.4604 7.90993V7.32731L74.4349 7.32003L74.1889 7.70394L73.9418 7.31794L73.9168 7.32471V7.90837H73.7151V6.96733C73.7574 6.96421 73.7975 6.96369 73.8371 6.95901C73.8697 6.9527 73.9035 6.95779 73.9329 6.97342C73.9622 6.98905 73.9852 7.01426 73.9981 7.04484C74.0539 7.14576 74.1196 7.24147 74.191 7.35696C74.2544 7.26618 74.3132 7.17225 74.3671 7.07553C74.3833 7.03284 74.4147 6.99758 74.4552 6.97639C74.4957 6.95521 74.5426 6.94958 74.587 6.96057C74.6074 6.96369 74.6277 6.96421 74.6459 6.96525C74.6751 7.05888 74.6808 7.77312 74.6548 7.9115L74.4604 7.90993Z"
        fill="white"
      />
      <path
        d="M72.722 7.14363V6.96729H73.5037V7.14468H73.2327C73.216 7.40478 73.2358 7.65707 73.2207 7.91093H73.0122C73.0029 7.7892 73.0091 7.66644 73.0081 7.54679C73.007 7.42714 73.0081 7.29866 73.0081 7.1608L72.722 7.14363Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3584_19130"
          x1="46.7227"
          y1="20.6537"
          x2="57.8258"
          y2="1.14817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02A5A7" />
          <stop offset="0.527651" stopColor="#FFD315" />
          <stop offset="1" stopColor="#F39446" />
        </linearGradient>
      </defs>
    </svg>
  )
}
