import { Product } from '@customTypes/product'

export const SET_VM_PRODUCTS = 'vmmv-ui/products/SET_VM_PRODUCTS'

export interface SetVmProducts {
  type: typeof SET_VM_PRODUCTS
  payload: { vmProducts: Product[] }
}

export const setVmProducts = (vmProducts: Product[]): SetVmProducts => {
  return {
    type: SET_VM_PRODUCTS,
    payload: {
      vmProducts,
    },
  }
}

export type ProductsActionTypes = SetVmProducts
