export const availableMode = () => '/'
export const liveTryOn = () => '/live-try-on'
export const pictureMode = (stepId?: string) =>
  stepId ? `/picture-mode/${stepId}` : '/picture-mode'
export const videoMode = (stepId?: string) => (stepId ? `/video-mode/${stepId}` : '/video-mode')
export const videoModeView = () => 'video-mode-view'
export const videoScan = () => 'video-scan'
export const uploadInstructions = () => 'upload-instructions'
export const takePicture = () => 'take-picture'
export const takePictureInstructions = () => 'take-picture-instructions'
export const pictureModeView = () => 'picture-mode-view'
export const privacyPolicy = () => '/privacy-policy'
export const pictureVideoModeError = () => 'error'
