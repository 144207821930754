import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const CameraIcon: React.FC<IconProps> = ({
  width = pxToEm(41),
  height = pxToEm(41),
  fill = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.643 7.322a2.196 2.196 0 0 0-2.197 2.197v2.196H8.52a3.36 3.36 0 0 0-2.407 1.022 3.503 3.503 0 0 0-.987 2.444v15.033c0 .913.353 1.792.987 2.444a3.36 3.36 0 0 0 2.407 1.021H32.48a3.36 3.36 0 0 0 2.408-1.022c.634-.65.987-1.53.987-2.443V15.18c0-.913-.353-1.793-.987-2.444a3.36 3.36 0 0 0-2.407-1.022h-3.927V9.52a2.196 2.196 0 0 0-2.197-2.197H14.643zm12.446 4.393V9.52a.732.732 0 0 0-.732-.732H14.643a.732.732 0 0 0-.732.732v2.196h13.178zM8.52 13.18c-.505 0-.994.206-1.358.58a2.039 2.039 0 0 0-.572 1.422v15.033c0 .537.208 1.048.572 1.422.364.373.853.58 1.358.58H32.48c.506 0 .995-.207 1.359-.58.364-.374.572-.885.572-1.422V15.18c0-.537-.208-1.048-.572-1.422a1.895 1.895 0 0 0-1.358-.58H8.518zM20.5 17.572a5.125 5.125 0 1 0 0 10.25 5.125 5.125 0 0 0 0-10.25zm-6.59 5.125a6.59 6.59 0 1 1 13.18 0 6.59 6.59 0 0 1-13.18 0z"
        fill={fill}
      />
    </svg>
  )
}
