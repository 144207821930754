import {
  CloseIcon,
  TransitionIconReduced,
  TransitionsIcon,
  // TryTransitionIcon,
  // TryTransitionMobileIcon,
} from '@components/core/Icons'
import { useAnimation } from '@hooks/useAnimationApp'
import { trackClickTransitionView } from '@libs/analytics'
// import { isMobileView } from '@libs/utils'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { IconWrapper, ToggleBtn, TransitionIconWrapper } from './styles'
interface ToggleProps {
  changeMenuVisibility: () => void
  isTransitionActive: boolean
}

export const Toggle: React.FC<ToggleProps> = ({ changeMenuVisibility, isTransitionActive }) => {
  const [expand, setExpand] = useState(false)
  const cbOnStart = useCallback(() => {
    setExpand(true)
  }, [])

  const cbOnEnd = useCallback(() => {
    setExpand(false)
  }, [])

  // const { t } = useTranslation()

  useAnimation(true, !expand, cbOnStart, cbOnEnd, 'transitionBtn')

  const onToggle = useCallback(() => {
    if (!isTransitionActive) {
      trackClickTransitionView()
    }
    changeMenuVisibility()
  }, [isTransitionActive, changeMenuVisibility])

  // const TryTransirionIcon = isMobileView() ? TryTransitionMobileIcon : TryTransitionIcon

  return (
    <ToggleBtn onClick={onToggle} expand={expand}>
      {expand ? (
        <TransitionIconWrapper withText={true}>
          {/* {t('general.try-with')}&nbsp;
          <TransitionsIcon /> */}
          <Trans
            i18nKey="general.try-with-transitions"
            // eslint-disable-next-line react/jsx-key
            components={[<TransitionsIcon />]}
          />
        </TransitionIconWrapper>
      ) : (
        <TransitionIconWrapper>
          <TransitionIconReduced />
        </TransitionIconWrapper>
      )}
      {isTransitionActive && (
        <IconWrapper>
          <CloseIcon width="0.8em" height="0.8em" />
        </IconWrapper>
      )}
    </ToggleBtn>
  )
}
