import { IconProps } from '@customTypes/icons'
import React from 'react'

export const LightLessIcon: React.FC<IconProps> = ({ width = '1em', height = '1em' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#y822wyoixa)">
        <path
          d="m7 5.4 18 18"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 15c0-1.65-1.35-3-3-3-.784 0-1.5.305-2.037.802l-1.417-1.417a5.002 5.002 0 0 1 7.068 7.068l-1.416-1.416A2.988 2.988 0 0 0 18 15zm-5.861-.9c-.09.284-.139.587-.139.9 0 1.65 1.35 3 3 3 .313 0 .616-.049.9-.139l1.517 1.516a5.002 5.002 0 0 1-6.795-6.795L12.14 14.1zm-2.014-4.136-2.46-2.46a.999.999 0 0 1 1.325.076l1.06 1.06c.362.353.38.931.075 1.324zm12.37 12.37a.995.995 0 0 0-.075-1.324l-1.06-1.06a.999.999 0 0 0-1.326-.077l2.462 2.462zM7 16H5c-.55 0-1-.45-1-1s.45-1 1-1h2c.55 0 1 .45 1 1s-.45 1-1 1zm18 0h-2c-.55 0-1-.45-1-1s.45-1 1-1h2c.55 0 1 .45 1 1s-.45 1-1 1zM14 7V5c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1s-1-.45-1-1zm0 18v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1s-1-.45-1-1zm8.42-17.42c.39.38.39 1.02 0 1.41l-1.06 1.06c-.38.39-1.02.38-1.41 0a.996.996 0 0 1 0-1.41l1.06-1.06a.996.996 0 0 1 1.41 0zM10.05 19.95c.39.38.39 1.02 0 1.41l-1.06 1.06c-.38.39-1.02.38-1.41 0a.996.996 0 0 1 0-1.41l1.06-1.06a.996.996 0 0 1 1.41 0z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="y822wyoixa"
          x="0"
          y="0"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1987_61404" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1987_61404" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
