const ML_PROCESS_IMAGE_CONSENT = 'MlProcessImageConsent'
const BASIC_IMAGE_CONSENT = 'BasicImageConsent'

//This function is needed to turn off the default privacy policy modal from FrameAdvisor capture app
export const setPrivacyAcceptedCookie = () => (document.cookie = `${ML_PROCESS_IMAGE_CONSENT}=true`)

const getCookie = (field: string) => {
  const cookieArr = document.cookie.split(';')

  const cookieObj: Record<string, string> = {}
  cookieArr.forEach(el => {
    const newl = el.split('=')
    cookieObj[newl[0].trim()] = newl[1]
  })

  return cookieObj[field]
}

export const isPrivacyCookieAccepted = () =>
  getCookie(BASIC_IMAGE_CONSENT) === 'true' || isPrivacyCookieAcceptedFromFa()

// to check if privacy policy in FrameAdvisor capture is accepted
export const isPrivacyCookieAcceptedFromFa = () => getCookie(ML_PROCESS_IMAGE_CONSENT) === 'true'
