import { useConfig } from '@providers/configProvider'
import { useEffect } from 'react'

export const useSetDocumentHeight = () => {
  const { selector } = useConfig()
  useEffect(() => {
    let isEffectRunning = true
    const container = document.querySelector<HTMLDivElement>(selector)
    const setHeight = () => {
      if (container) {
        container.style.height = `${window.innerHeight}px`
      }
      if (isEffectRunning) {
        window.requestAnimationFrame(setHeight)
      }
    }
    setHeight()

    return () => {
      isEffectRunning = false
    }
  }, [selector])
}
