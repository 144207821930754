import styled from 'styled-components'
import { pxToEm } from '@libs/styled'
import { Button } from '@components/core/Button'
import { resetButtonStyles } from '@components/Menu/styles'

export const ModalWindowContainer = styled.div`
  z-index: 1001;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.white[100]};
  border-radius: ${pxToEm(16)};
  width: ${pxToEm(290)};
  height: ${pxToEm(335)};

  ${({ theme }) => theme.media.medium} {
    border-radius: ${pxToEm(16)};
    width: ${pxToEm(545)};
    height: ${pxToEm(360)};
  }
  ${({ theme }) => theme.media.large} {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
`

export const ModalCloseButton = styled.button`
  ${resetButtonStyles};
  position: absolute;
  right: ${pxToEm(11)};
  top: ${pxToEm(11)};
`

export const ModalContainer = styled.div`
  max-width: ${pxToEm(290)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${pxToEm(56)} 0 ${pxToEm(24)} 0;
  height: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.media.medium} {
    padding: ${pxToEm(47)} 0 ${pxToEm(32)} 0;
  }
  ${({ theme }) => theme.media.large} {
    height: unset;
    padding: ${pxToEm(88)} 0 0 0;
  }
`

export const TitleContainer = styled.div`
  text-align: center;

  ${({ theme }) => theme.media.large} {
    margin-bottom: ${pxToEm(80)};
  }
`
export const Title = styled.div`
  margin-bottom: ${pxToEm(24)};
  font-weight: 600;
  h2 {
    font: ${({ theme }) => theme.fonts.titleSmall};
  }
`

export const Text = styled.span`
  font: ${({ theme }) => theme.fonts.paragraph};
  line-height: 1.25;
`

export const AddPrescriptionLensesButton = styled(Button)`
  font: ${({ theme }) => theme.fonts.paragraph};
  font-size: ${pxToEm(14)};
  background: ${({ theme }) => theme.palette.black};
  text-transform: capitalize !important;
  padding: 0;
  width: 100%;
  margin: 0 0 ${pxToEm(16)} 0;
  height: 48px;
`
export const AddToCartButton = styled(Button)`
  font: ${({ theme }) => theme.fonts.buttonTextLink};
  font-size: ${pxToEm(14)};
  color: ${({ theme }) => theme.palette.black};
  border-color: ${({ theme }) => theme.palette.black};
  padding: 0;
  width: 100%;
  margin: 0;
  height: 48px;
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxToEm(242)};
`
