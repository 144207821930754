import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const ImageWrapper = styled.div`
  position: relative;
  width: 70%;
  height: 40%;
  ${({ theme }) => theme.media.medium} {
    height: 50%;
  }

  ${({ theme }) => theme.media.large} {
    min-height: ${pxToEm(390)};
    max-height: ${pxToEm(390)};
    max-width: ${pxToEm(310)};
  }
`

export const StyledImage = styled.img`
  width: 100%;
  max-height: 100%;
  object-fit: cover;

  ${({ theme }) => theme.media.large} {
    height: 100%;
  }
`
