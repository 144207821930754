import React from 'react'
import { usePortalDiv } from '@hooks/usePortalDiv'
import ReactDOM from 'react-dom'
import { ModalWindowOverlay } from '@components/core/Modal/styles'

interface ModalWindowProps {
  visible?: boolean
  onOverlayClick?: () => void
  children: React.ReactNode
  className?: string
}
const modalWindowClassName = 'modal__container'

const ModalBaseContainer: React.FC<ModalWindowProps> = ({ visible, onOverlayClick, children }) => {
  if (!visible) return null

  return (
    <>
      <ModalWindowOverlay onClick={onOverlayClick} />
      {children}
    </>
  )
}

export const Modal: React.FC<ModalWindowProps> = ({ children, className, ...props }) => {
  const divEl = usePortalDiv(modalWindowClassName)

  if (!divEl) return null

  return ReactDOM.createPortal(
    <ModalBaseContainer className={className} {...props}>
      {children}
    </ModalBaseContainer>,
    divEl
  )
}
