import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { Button } from '../core/Button'
import React from 'react'
import { setVisibilityBipaModal } from '@store/actions/ui'
import { useTranslation } from 'react-i18next'
import { ButtonsWrapper } from './styles'
import { PrivacyPolicyWrapper } from './PrivacyPolicyWrapper'
import { useBipaContent } from '@hooks/useBipaContent'
import { Loading } from '@components/Loader'

export const BipaModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isBipaModalVisible = useSelector((s: AppState) => s.ui.isBipaModalVisible)

  const { loading, bipa } = useBipaContent(!isBipaModalVisible)

  if (!isBipaModalVisible) {
    return null
  }

  const renderCtaContent = () => (
    <ButtonsWrapper>
      <Button
        onClick={() => {
          dispatch(setVisibilityBipaModal(false))
        }}
      >
        {t('RETURN')}
      </Button>
    </ButtonsWrapper>
  )

  return (
    <PrivacyPolicyWrapper
      title={t('NOTICE_OF_BIOMETRIC_USE')}
      ctaContent={renderCtaContent()}
      content={
        loading || !bipa ? (
          <Loading />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: bipa['BIPA_AGREEMENT'] }} />
        )
      }
      className="vmmv-bipa-modal"
    />
  )
}
