import { Queue } from '@customTypes/animationApp'
import { setCompleteAnimation, setCurrentAnimation } from '@store/actions/animationApp'
import { AppState } from '@store/index'
import { useCallback, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

export const useAnimationApp = () => {
  const { currentAnimation, isComplete, animationQueue } = useSelector(
    (s: AppState) => s.animationApp,
    shallowEqual
  )

  const dispatch = useDispatch()

  const setAnimation = useCallback(
    (animation: Queue | null) => {
      dispatch(setCurrentAnimation(animation))
    },
    [dispatch]
  )

  const completeAnimation = useCallback(() => {
    dispatch(setCompleteAnimation())
  }, [dispatch])

  const setNextAnimation = useCallback(() => {
    const next = animationQueue[0]
    if (!next) {
      completeAnimation()
      setAnimation(null)
      return
    }
    setAnimation(next)
  }, [animationQueue, setAnimation, completeAnimation])

  return {
    currentAnimation,
    isComplete,
    setAnimation,
    setNextAnimation,
    animationQueue,
  }
}

export const useInitAnimationApp = () => {
  const { setAnimation, currentAnimation, animationQueue, isComplete } = useAnimationApp()

  useEffect(() => {
    if (!isComplete && !currentAnimation) {
      const firstAnimation = animationQueue[0]
      setAnimation(firstAnimation)
    }
  }, [currentAnimation, isComplete, animationQueue, setAnimation])
}

export const TIME_TO_SHOW = 3000 // Due to requirements

export const useAnimation = (
  conditionStart: boolean,
  conditionEnd: boolean,
  cbOnStart: () => void,
  cbOnEnd: () => void,
  animation: Queue,
  time = TIME_TO_SHOW
) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const { currentAnimation, setNextAnimation } = useAnimationApp()

  const isAnimationMatch = currentAnimation === animation

  useEffect(() => {
    if (conditionStart && isAnimationMatch) {
      cbOnStart()
    }
  }, [conditionStart, cbOnStart, isAnimationMatch])

  useEffect(() => {
    if (conditionEnd && isAnimationMatch) {
      timer.current = setTimeout(() => {
        cbOnEnd()
        setNextAnimation()
      }, time)
    }
  }, [conditionEnd, setNextAnimation, cbOnEnd, isAnimationMatch, time])

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
      timer.current = null
    }
  }, [])
}
