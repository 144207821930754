import { VirtualMirror, VirtualMirrorCatalogue } from '@luxottica/virtual-mirror'
import { useCallback, useEffect } from 'react'

import { Color } from '@customTypes/product'
import colorsData from '../../colors.json'
import { useConfig } from '@providers/configProvider'
import { useRefValue } from '@hooks/useRefValue'
import { useVmInitParams } from '@hooks/useVmInitParams'

interface VMInitializeProps {
  setIsReady: (value: boolean) => void
  setIsVMInitialized: (value: boolean) => void
  setColors: (colors: Color[]) => void
  children: React.ReactNode
}

export const VMInitialize: React.FC<VMInitializeProps> = ({
  children,
  setIsReady,
  setIsVMInitialized,
  setColors,
}) => {
  const config = useConfig()
  const vmInitParams = useVmInitParams(config, setIsReady)

  const initializeVirtualMirror = useCallback(async () => {
    try {
      await VirtualMirror.initialize(vmInitParams)
      setIsVMInitialized(true)
      VirtualMirrorCatalogue.build({
        ...((config.vmInit.glassesEnv && {
          catalogueEnvironment: config.vmInit.glassesEnv,
          // Use any due to impossible to import GlassesEnvironment type, required for env variable
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any),
        key: vmInitParams.options.key,
      })
        .downloadTransitionColorCatalogue()
        .then(transitionColors => {
          const colors: Color[] = Object.keys(transitionColors).map(colorCode => {
            const colorData = colorsData.find(color => color.colorCode === colorCode)
            return {
              colorCode,
              colorName: colorData?.colorName,
              lensName: colorData?.lensName,
              colorValue: colorData?.color,
            }
          })
          setColors(colors)
        })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error initializing virtual mirror: ${error}`)
    }
  }, [vmInitParams, setIsVMInitialized, setColors, config.vmInit.glassesEnv])

  const initVMRef = useRefValue(initializeVirtualMirror)

  useEffect(() => {
    initVMRef.current()
  }, [initVMRef])

  return <>{children}</>
}
