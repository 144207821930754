import { useCallback, useEffect, useRef, useState } from 'react'
import { FooterActions } from '@components/FooterActions'
import { Menu } from '@components/Menu'
import { RightSideActions } from '@components/RightSIdeActions'
import { TransitionView } from '@components/TransitionView'
import { VtoCore } from '@luxottica/vto-core'
import { VtoInitialize } from '@components/VtoInitialize'
import styled from 'styled-components'
import { useConfig } from '@providers/configProvider'
import { useDimensions } from '@hooks/useDimensions'
import { useNavigate } from 'react-router'
import { useScreenshot } from '@hooks/useScreenshot'
import { useDispatch } from 'react-redux'
import { useVirtualMirrorApp } from '@hooks/useVirtualMirrorApp'
import { QRCodeModal } from '@components/QRCodeModal'
import { setQRCodeModalVisibility, setVtoPage } from '@store/actions/ui'
import { isDesktopView } from '@libs/utils'
import { RotateIcon, RotateIconDesktop } from '@components/core/Icons'
import { useFitGlasses } from '@libs/analytics'
import { Drawer } from '@components/core/Drawer'
import { ScreenshotPreview } from '@components/ScreenshotPreview'
import { pxToEm } from '@libs/styled'
import { useTranslation } from 'react-i18next'
import { getVideoId, clearVideoId } from '@utils/sessionStorage'
import { AddPrescriptionModal } from '@components/AddPrescriptionModal'

export const CONTAINER_ID_VIDEO_MODE = 'vm-video-mode-view'
const ROTATE_ICON_SHOW_TIMES = 2
const REPEAT_ROTATE_ICON_INTERVAL = 30000
const ROTATE_ICON_SHOW_DURATION = 3000
const nosePositionTouches = isDesktopView() ? 1 : 2

const RotationTip = styled.p`
  color: ${({ theme }) => theme.palette.white[100]};
  font: ${({ theme }) => theme.fonts.paragraphSmall};
`

const RotationIconContainer = styled.div`
  position: absolute;
  top: 60%;
  width: 80%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pxToEm(5)} ${pxToEm(15)};
  border-radius: ${pxToEm(30)};
  background-color: rgba(0, 0, 0, 0.4);

  ${({ theme }) => theme.media.medium} {
    width: auto;
    top: 70%;
  }
`

export const closeVideoMode = () => {
  return new Promise<void>((resolve, reject) => {
    VtoCore.closeRender({ divId: CONTAINER_ID_VIDEO_MODE }, resolve, reject)
  })
}

export const VideoModeView = () => {
  const { fromStore } = useConfig()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const pictureId = getVideoId()
  const [isRotateIconVisible, setRotateIconVisible] = useState(false)
  const [iconShowTimes, setIconShowTimes] = useState(0)

  const onRefresh = useCallback(() => {
    clearVideoId()
    VtoCore.closeRender({ divId: CONTAINER_ID_VIDEO_MODE }, () => {
      dispatch(setVtoPage('off'))
      navigate(-1)
    })
  }, [dispatch, navigate])

  const {
    isReady,
    isPreparingScreenshot,
    setIsPreparingScreenshot,
    currentProduct,
    currentColor,
    changeUpc,
    colorsCatalog,
    currentLens,
    changeLens,
    lensCatalog,
    lensActivation,
    setIsReady,
    setColors,
    setCurrentColor,
    setLensActivation,
    setIsVMInitialized,
    setUserID,
    isVMInitialized,
    isTransitionActive,
    toggleTransitionActive,
    vmProducts,
  } = useVirtualMirrorApp()

  const { image, setImage } = useScreenshot()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useDimensions()
  const trackFitGlasses = useFitGlasses(currentProduct)

  const isEvolveProduct = currentProduct.transitionLens?.isEvolve

  useEffect(() => {
    if (isReady) {
      trackFitGlasses()
    }
  }, [isReady, trackFitGlasses])

  const renderVto = useCallback(() => {
    VtoCore.render(
      {
        divId: CONTAINER_ID_VIDEO_MODE,
        videoId: pictureId,
        upc: currentProduct.upc,
        showRotateBar: false,
        width: dimensions.width,
        height: dimensions.height,
        nosePositionTouches,
      },
      () => {
        setIsReady(true)
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    )
  }, [currentProduct.upc, dimensions.height, dimensions.width, pictureId, setIsReady])

  useEffect(() => {
    if (!currentProduct.upc && !isVMInitialized) {
      return
    }
    renderVto()
  }, [currentProduct, isVMInitialized, renderVto])

  useEffect(() => {
    if (isTransitionActive || isEvolveProduct) {
      VtoCore.setTransitionOpacity(lensActivation)
      renderVto()
    }
  }, [isTransitionActive, lensActivation, renderVto, isEvolveProduct])

  useEffect(() => {
    if (!isTransitionActive && !isEvolveProduct) {
      VtoCore.setTransitionColorName('')
      VtoCore.setTransitionOpacity(0)
      renderVto()
    }
  }, [isTransitionActive, renderVto, isEvolveProduct])

  useEffect(() => {
    if (isTransitionActive || isEvolveProduct) {
      VtoCore.setTransitionColorName(currentColor)
      renderVto()
    }
  }, [isTransitionActive, isEvolveProduct, currentColor, renderVto])

  useEffect(() => {
    if (isRotateIconVisible) {
      const timer = setTimeout(() => {
        setIconShowTimes(s => s + 1)
        setRotateIconVisible(false)
      }, ROTATE_ICON_SHOW_DURATION)
      return () => clearTimeout(timer)
    }
  }, [isRotateIconVisible])

  useEffect(() => {
    if (!isRotateIconVisible && iconShowTimes && iconShowTimes <= ROTATE_ICON_SHOW_TIMES) {
      const timer = setTimeout(() => {
        setRotateIconVisible(true)
      }, REPEAT_ROTATE_ICON_INTERVAL)
      return () => clearTimeout(timer)
    }
  }, [isRotateIconVisible, iconShowTimes])

  const getScreenshot = useCallback(async () => {
    try {
      setIsPreparingScreenshot(true)
      const img = await VtoCore.getScreenshot()
      setImage({
        imgURI: img.dataUrl,
        width: img.w,
        height: img.h,
      })
      setIsPreparingScreenshot(false)
    } catch (error) {
      setIsPreparingScreenshot(false)
      // eslint-disable-next-line no-console
      console.error(`Error generating the image: ${error}`)
    }
  }, [setIsPreparingScreenshot, setImage])

  const observerCallback = useCallback((_: MutationRecord[], observer: MutationObserver) => {
    const debugOverlay = document.querySelector('.render-debug-overlay')
    if (debugOverlay) {
      setRotateIconVisible(true)
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const observer = new MutationObserver(observerCallback)
    if (containerRef.current) {
      observer.observe(containerRef.current, { childList: true, subtree: true })
    }
    return () => {
      observer.disconnect()
    }
  }, [observerCallback])

  useEffect(() => {
    if (isVMInitialized) {
      dispatch(setVtoPage('video-mode'))
    }
  }, [dispatch, isVMInitialized])

  return (
    <VtoInitialize
      setIsVMInitialized={setIsVMInitialized}
      setUserID={setUserID}
      setColors={setColors}
      onRefresh={onRefresh}
    >
      <>
        {isRotateIconVisible && (
          <RotationIconContainer>
            {isDesktopView() ? <RotateIconDesktop /> : <RotateIcon />}
            <RotationTip>
              {isDesktopView()
                ? t('CORE.RENDER_VTO.CLICK_AND_MOVE')
                : t('CORE.RENDER_VTO.SWIPE_TWO_FINGERS')}
            </RotationTip>
          </RotationIconContainer>
        )}
        <Menu fromStore={fromStore} />
        {isReady && (
          <RightSideActions
            product={currentProduct}
            enableWishlistCb={!isTransitionActive && fromStore}
            onRefresh={onRefresh}
            onQRCb={() => dispatch(setQRCodeModalVisibility(true))}
          />
        )}
        <div ref={containerRef} className="vmmv-video-mode-view" id={CONTAINER_ID_VIDEO_MODE} />
        <TransitionView
          isTransitionActive={isTransitionActive}
          toggleTransitionActive={toggleTransitionActive}
          isReady={isReady}
          isTransition={currentProduct.isTransition || false}
          products={vmProducts || []}
          selectedUpc={currentProduct.upc}
          onChangeUpc={changeUpc}
          colors={colorsCatalog}
          onChangeColor={setCurrentColor}
          selectedColor={currentColor}
          lenses={lensCatalog}
          selectedLens={currentLens || ''}
          onChangeLens={changeLens}
          lensActivation={lensActivation}
          onChangeLensOpacity={setLensActivation}
        />
        <FooterActions
          lensType={currentLens}
          isTransitionActive={isTransitionActive}
          isPreparingScreenshot={isPreparingScreenshot}
          isReady={isReady}
          product={currentProduct}
          getScreenshot={getScreenshot}
        />

        <Drawer visible={!!image} className={'vmmv-drawer-screenshot'}>
          <ScreenshotPreview image={image} setImage={setImage} product={currentProduct} />
        </Drawer>

        <QRCodeModal productName={currentProduct.name} />
        <AddPrescriptionModal product={currentProduct} />
      </>
    </VtoInitialize>
  )
}
