import config from '@config/index'
import { Product } from '@customTypes/product'
import { getVmVersion } from './utils'

export type Sources = typeof sources[keyof typeof sources]
export type Ids = typeof ids[keyof typeof ids]
export type ValueTypes = typeof valueTypes[keyof typeof valueTypes]
export type LensTypes = typeof lensTypes[keyof typeof lensTypes]
export type ProductStatus = typeof productStatus[keyof typeof productStatus]
export type FrameTypes = typeof frameTypes[keyof typeof frameTypes]
export type Category = typeof category[keyof typeof category]
export type TakeMode = typeof takeMode[keyof typeof takeMode]
export type Sections = typeof sections[keyof typeof sections]

export interface ErrorData {
  id?: Ids
  Error_Source?: Sources // "Server" if error generated or managed by server side, "Client" if error generated by code in page usually for a catched JS error or unexpected error/behavior of page/content
  Error_Code: string
  Error_Details: string // alphanumeric - Error cause/source. Must specify details to drive IT through an exact fix
}

interface ClickData {
  id?: Ids
  data_element_id: string
  data_analytics_available_call?: ValueTypes
}

interface BaseEventData {
  id?: Ids
  Take_Mode?: TakeMode
  Vm_Release?: string
}

interface DefaultImpressionData {
  id?: Ids
  Page_Section1?: Sections
  Page_Section2: string
}

type ProductUpc = {
  Category: Category // SUN, OPTICS
  FrameType: FrameTypes
}

type CartProductUpc = ProductUpc & {
  LensType: LensTypes
  Price: string // i.e. "113.60". Displayed final price per unit (-product discount, -prod insurance)
  PriceFull: string // i.e. "142.00". Displayed list price per unit before any discount
  Status?: ProductStatus // PIM stock status properties
}

export type DefaultProduct = Record<string, ProductUpc> // UPC Number
type CartProduct = Record<string, CartProductUpc>

interface CartEventData extends BaseEventData {
  Events_CartAdd: ValueTypes
  Products: CartProduct
}

interface WishlistEventData extends BaseEventData {
  Events_ProdFavAdd: ValueTypes
  Products: DefaultProduct
}

interface ShareEventData extends BaseEventData {
  Events_SocialShare: ValueTypes
}

interface ScreenshotEventData extends BaseEventData {
  Events_VMScreenshot: ValueTypes
}

interface FitGlassesEventData extends BaseEventData {
  Events_VMFit: ValueTypes
}

interface VirtualMirrorImpressionData extends DefaultImpressionData {
  Events_VMRender: ValueTypes
  Products: DefaultProduct
}

export type ImpressionData = VirtualMirrorImpressionData

export type EventData =
  | CartEventData
  | WishlistEventData
  | ShareEventData
  | ScreenshotEventData
  | FitGlassesEventData

export type Data = ErrorData | ClickData | EventData | ImpressionData

declare global {
  interface Window {
    tealium_data2track: Array<Data>
    utag_data: {
      tealium_visitor_id?: string
      tealium_session_id?: string
      Page_Environment?: string
      Page_Platform?: string
      Page_Brand?: string
      Page_Country?: string
      Store_Id?: string
      utag_env?: string
      utag_plt?: string
      utag_brnd?: string
      utag_strid?: string
      utag_strrgn?: string
    }
  }
}

export const takeMode = {
  vto: 'vto',
  video: 'video',
  picture: 'picture',
  pictureUpload: 'picture upload',
} as const

export const valueTypes = {
  Positive: '1',
  Negative: '0',
} as const

export const ids = {
  Click: 'Click',
  Impression: 'Impression',
  Event: 'Event',
  Error: 'Error',
} as const

export const sources = {
  Client: 'Client',
  User: 'User',
  Server: 'Server',
} as const

export const category = {
  Sun: 'SUN',
  Optics: 'OPTICS',
} as const

export const frameTypes = {
  Std: 'STD',
  Cp: 'CP',
} as const

export const lensTypes = {
  Plano: 'PLANO',
} as const

export const productStatus = {
  Available: 'Available',
} as const

export const sections = {
  VirtualMirror: 'VirtualMirror',
} as const

export const getCategory = (product: Product) => {
  return product.category?.toLowerCase()?.includes('sun') ? category.Sun : category.Optics
}

export const getFrameType = (product: Product) => {
  return product.isCustom ? frameTypes.Cp : frameTypes.Std
}

export const formatAnalyticsProducts = (products: Product[]): DefaultProduct => {
  return products.reduce((acc, pr) => {
    const id = pr.isCustom ? pr.code : pr.upc
    return { ...acc, [id]: { Category: getCategory(pr), FrameType: getFrameType(pr) } }
  }, {})
}

const track = (data: Data) => {
  if (config.debugAnalytics) {
    // eslint-disable-next-line no-console
    console.trace(data)
  }
  if (window.tealium_data2track) {
    window.tealium_data2track.push(data)
  }
}

export const trackAttribute = (data: ClickData) => track({ ...data, id: ids.Click })

export const trackClientError = (data: ErrorData) =>
  track({ ...data, id: ids.Error, Error_Source: sources.Client, Vm_Release: getVmVersion() })

export const trackUserError = (data: ErrorData) =>
  track({ ...data, id: ids.Error, Error_Source: sources.User, Vm_Release: getVmVersion() })

export const trackEvent = (data: EventData) =>
  track({ ...data, id: ids.Event, Vm_Release: getVmVersion() })

export const trackOnload = (data: ImpressionData) =>
  track({
    ...data,
    id: ids.Impression,
    Page_Section1: sections.VirtualMirror,
    Vm_Release: getVmVersion(),
  })

export const trackServerError = (data: ErrorData) =>
  track({ ...data, id: ids.Error, Error_Source: sources.Server, Vm_Release: getVmVersion() })
