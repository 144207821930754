import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const HoldDeviceIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m27.34 13.556-3.59-3.264V3.125A3.129 3.129 0 0 0 20.625 0H9.375A3.129 3.129 0 0 0 6.25 3.125v7.167l-3.59 3.264A8.14 8.14 0 0 0 0 19.568V30h1.25V19.568a6.89 6.89 0 0 1 2.25-5.087l2.75-2.5v8.426l-1.223 1.107.885.885 4.89-4.425c.45-.408.934-.276 1.237-.017.303.26.508.717.176 1.226l-3.492 5.34c-.31.473-.524 1.032-.657 1.708A3.128 3.128 0 0 1 5 28.75V30a4.374 4.374 0 0 0 3.957-2.543c.138.02.275.043.418.043h11.25c.143 0 .28-.024.418-.043A4.374 4.374 0 0 0 25 30v-1.25a3.13 3.13 0 0 1-3.066-2.518c-.133-.677-.348-1.236-.658-1.708l-3.492-5.34c-.332-.51-.127-.968.176-1.227.303-.258.786-.39 1.238.018l4.89 4.424.884-.885-1.222-1.107V11.98l2.75 2.5a6.888 6.888 0 0 1 2.25 5.087V30H30V19.568c0-2.284-.97-4.476-2.66-6.012zm-7.303 3.492c-.84-.76-2.028-.777-2.889-.041-.861.736-1.03 1.912-.41 2.86l3.492 5.341c.186.285.324.629.424 1.04l-.029.002H9.375l-.03-.003c.1-.41.239-.755.424-1.04l3.492-5.34c.62-.948.452-2.125-.41-2.86-.861-.736-2.049-.72-2.889.04L7.5 19.277V3.124c0-1.034.841-1.875 1.875-1.875h11.25c1.034 0 1.875.841 1.875 1.875v16.151l-2.463-2.229z"
        fill="#222"
      />
      <path
        d="M17.5 3.75h-5V5h5V3.75zM15 23.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"
        fill="#222"
      />
    </svg>
  )
}
