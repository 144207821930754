import { LeftSideActions } from '@components/LeftSideActions'
import { LensActivation } from '@components/LensActivation'
import { OverlayWrapper } from '@components/core/Overlay'
import { Color, Product } from '@customTypes/product'
import { ActivePanelName } from '@customTypes/transition'
import { useAnimationApp } from '@hooks/useAnimationApp'
import { useConfig } from '@providers/configProvider'
import { setTransitionViewCarousel } from '@store/actions/ui'
import { AppState } from '@store/index'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from '../Carousel'
import { Toggle } from './Toggle'

export interface TransitionViewProps {
  isReady: boolean
  isTransition: boolean
  products: Product[]
  selectedUpc: string
  onChangeUpc: (upc: string) => void

  colors: Color[]
  selectedColor: string
  onChangeColor: (color: string) => void

  lenses: string[]
  selectedLens: string
  onChangeLens: (lens: string) => void

  lensActivation: number
  onChangeLensOpacity: (opacity: number) => void
  toggleTransitionActive: () => void
  isTransitionActive: boolean
}

export const TransitionView: React.FC<TransitionViewProps> = props => {
  const {
    isTransition,
    onChangeLensOpacity,
    lensActivation,
    isReady,
    toggleTransitionActive,
    isTransitionActive,
    products,
    selectedUpc,
  } = props

  const dispatch = useDispatch()
  const { activeName, isMenuVisible } = useSelector((s: AppState) => s.ui)
  const setActiveName = useCallback(
    (activeName: ActivePanelName) => {
      dispatch(setTransitionViewCarousel(activeName))
    },
    [dispatch]
  )

  const { isComplete } = useAnimationApp()
  const { transitionFeature } = useConfig()

  const currentProduct = products.find(p => p.upc === selectedUpc)
  const isEvolve = currentProduct?.transitionLens?.isEvolve || false

  useEffect(() => {
    if (isComplete && isTransition) {
      setActiveName(transitionFeature?.activePanel || 'lens')
    }
  }, [isComplete, isTransition, transitionFeature?.activePanel, setActiveName])

  useEffect(() => {
    if (!isTransition) {
      setActiveName('frame')
    }
  }, [isTransition, setActiveName])

  useEffect(() => {
    if (isEvolve && isTransitionActive) {
      toggleTransitionActive()
    }
  }, [isEvolve, isTransitionActive, toggleTransitionActive])

  const transitionProducts = products.filter(p => p.isTransition && !p.transitionLens?.isEvolve)

  const showTransitionToggler = useMemo(() => {
    return isTransition && transitionFeature?.enabled && !isEvolve && !isMenuVisible
  }, [isTransition, isEvolve, isMenuVisible, transitionFeature])

  if (!isReady) {
    return null
  }

  return (
    <>
      {showTransitionToggler && (
        <Toggle
          changeMenuVisibility={toggleTransitionActive}
          isTransitionActive={isTransitionActive && !isEvolve}
        />
      )}
      {isTransitionActive && (
        <LeftSideActions optionActiveName={activeName} setActiveName={setActiveName} />
      )}
      {isTransitionActive && (
        <Carousel activeName={activeName} {...props} products={transitionProducts} />
      )}
      {!isTransitionActive && <Carousel activeName={'frame'} {...props} />}
      {isTransition && (isTransitionActive || isEvolve) && (
        <LensActivation
          opacity={lensActivation}
          onChangeLensOpacity={onChangeLensOpacity}
          isEvolve={isEvolve}
        />
      )}
      {isTransitionActive && <OverlayWrapper />}
    </>
  )
}
