import { useEffect, useState } from 'react'
import { LAYOUT_ID } from './useDimensions'

export const usePortalDiv = (className = '') => {
  const [divEl, setDivEl] = useState<Element>()

  useEffect(() => {
    const layout = document.getElementById(LAYOUT_ID)
    const portalElement =
      document.querySelector(`.${className}`) ||
      layout?.appendChild(document.createElement('div', {}))
    portalElement?.classList.add(className)

    setDivEl(portalElement)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return divEl
}
