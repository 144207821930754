import React from 'react'

export const TransitionIconReduced: React.FC = () => {
  return (
    <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.70615 16.058C9.50258 16.058 11.7695 13.7866 11.7695 10.9846C11.7695 9.11275 10.7578 7.47764 9.25229 6.59839L4.2085 15.3989C4.94551 15.8184 5.7979 16.058 6.70615 16.058Z"
        fill="white"
      />
      <path
        d="M11.2694 10.9843C11.2694 13.511 9.22534 15.5577 6.70598 15.5577C4.18661 15.5577 2.14258 13.511 2.14258 10.9843C2.14258 8.45753 4.18661 6.41089 6.70598 6.41089C9.22534 6.41089 11.2694 8.45753 11.2694 10.9843Z"
        stroke="white"
      />
      <path
        d="M0 21.4954L12.0387 0.602683L13.4 0.586364L1.36135 21.479L0 21.4954Z"
        fill="url(#paint0_linear_710_690)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_710_690"
          x1="1.21071"
          y1="21.6675"
          x2="13.2799"
          y2="0.506531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02A5A7" />
          <stop offset="0.527651" stopColor="#FFD315" />
          <stop offset="1" stopColor="#F39446" />
        </linearGradient>
      </defs>
    </svg>
  )
}
