import { useCallback, useEffect } from 'react'

import { Color } from '@customTypes/product'
import { VtoCore, VtoCoreEnvironment } from '@luxottica/vto-core'
import colorsData from '../../colors.json'
import { useConfig } from '@providers/configProvider'
import { useRefValue } from '@hooks/useRefValue'
import { useVtoInitParams } from '@hooks/useVtoInitParams'

interface VtoInitializeProps {
  setIsVMInitialized: (value: boolean) => void
  setUserID: (value: string) => void
  setColors: (colors: Color[]) => void
  children: React.ReactNode
  onRefresh: () => void
}

const getGlassesEnvironment = (vtoEnv: VtoCoreEnvironment) =>
  vtoEnv.toLowerCase() === 'production' ? 'PROD' : 'TEST'

export const VtoInitialize: React.FC<VtoInitializeProps> = ({
  children,
  setIsVMInitialized,
  setUserID,
  setColors,
  onRefresh,
}) => {
  const config = useConfig()
  const { options } = useVtoInitParams(config, setUserID, onRefresh)

  const initializeVto = useCallback(() => {
    try {
      // VtoCore.initialize(options, onSuccess)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
      VtoCore.initialize(options, ({ webglRendering }) => {})
      setIsVMInitialized(true)
      VtoCore.downloadTransitionColorCatalogue(
        // Use any due to impossible to import GlassesEnvironment type, required for env variable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getGlassesEnvironment(options.environment) as any
      ).then(transitionColors => {
        const colors: Color[] = transitionColors.map(colorCode => {
          const colorData = colorsData.find(color => color.colorCode === colorCode)
          return {
            colorCode,
            colorName: colorData?.colorName,
            lensName: colorData?.lensName,
            colorValue: colorData?.color,
          }
        })
        setColors(colors)
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error initializing vto: ${error}`)
    }
  }, [options, setIsVMInitialized, setColors])

  const initVMRef = useRefValue(initializeVto)

  useEffect(() => {
    initVMRef.current()
  }, [initVMRef])

  return <>{children}</>
}
