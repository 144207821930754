import { useAsync } from '@hooks/useAsync'
import QRCodeStyling from 'qr-code-styling'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { vmApiService } from '@services/vmApiService'
import config from '@config/index'
import { StyledQRCode } from './styles'
import { useConfig } from '@providers/configProvider'
import { VMData } from 'src/schemas/vmData'
import { Product } from '@customTypes/product'
import { AppState } from '@store/index'
import { useSelector } from 'react-redux'

export interface QRCodeProps {
  size?: number
  className?: string
}

export const QRCode: React.FC<QRCodeProps> = ({ size = 160 }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { store, style, analytics, fromStore, vmInit, products, isTryOnEnabled } = useConfig()
  const { run, data } = useAsync(vmApiService.generateHash)
  const vmProducts: Product[] = useSelector((s: AppState) => s.products.vmProducts)

  const vmData = useMemo(
    () => ({
      store,
      style,
      products: vmProducts.length ? vmProducts : products,
      analytics,
      glassesEnv: vmInit.glassesEnv,
      vmmvModes: { isTryOnEnabled: isTryOnEnabled || false },
    }),
    [store, style, vmProducts, products, analytics, vmInit.glassesEnv, isTryOnEnabled]
  )

  const validateVMData = useCallback(() => {
    try {
      VMData.check(vmData)
    } catch (error) {
      throw new Error(
        'Request payload data does not match the expected type. Check VMData and try again.'
      )
    }
  }, [vmData])

  useEffect(() => {
    if (!data) {
      validateVMData()
      run(vmData)
    }
  }, [data, run, validateVMData, vmData])

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: size,
        height: size,
        qrOptions: {
          mode: 'Byte',
          errorCorrectionLevel: 'L',
        },
        dotsOptions: {
          type: 'square',
          color: '#000000',
        },
        cornersDotOptions: {
          type: 'square',
          color: '#000000',
        },
        cornersSquareOptions: {
          type: 'square',
          color: '#000000',
        },
      }),
    [size]
  )
  const url = `${config.vmUrl}?hash=${encodeURIComponent(data?.hash || '')}&fromStore=${fromStore}`
  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current)
    }
  }, [qrCode])

  useEffect(() => {
    qrCode.update({
      data: data?.hash ? url : '',
      width: size,
      height: size,
    })
  }, [size, qrCode, data, url])

  return (
    <StyledQRCode url={url} data-test={url} size={size}>
      <div ref={ref} />
    </StyledQRCode>
  )
}
