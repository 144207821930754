import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const CaretLeftIcon: React.FC<IconProps & { filter?: boolean }> = ({
  width = pxToEm(32),
  height = pxToEm(34),
  fill = '#000',
  filter = true,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 34"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter={filter ? 'url(#idbnv5zrrb)' : ''} clipRule="evenodd" clipPath="url(#ihoydlxzba)">
        <path
          fillRule="evenodd"
          d="M19.657 3.19a.647.647 0 0 1 0 .914l-9.895 9.903 9.88 9.889a.647.647 0 1 1-.915.914L8.39 14.465a.647.647 0 0 1 0-.915L18.742 3.19a.647.647 0 0 1 .915 0z"
          fill={fill}
        />
        <path
          d="M19.657 3.19a.647.647 0 0 1 0 .914l-9.895 9.903 9.88 9.889a.647.647 0 1 1-.915.914L8.39 14.465a.647.647 0 0 1 0-.915L18.742 3.19a.647.647 0 0 1 .915 0z"
          stroke={fill}
          strokeWidth=".7"
        />
      </g>
      <defs>
        <clipPath id="ihoydlxzba">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
        <filter
          id="idbnv5zrrb"
          x="2.85"
          y="-2.35"
          width="22.347"
          height="32.7"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_640_537" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_640_537" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
