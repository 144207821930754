import { NavBarActionScan, NavBarActionPicture, NavBarActionVideo } from '@components/core/Icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { MenuListWrapper, MenuOption, MenuLogo, ListText } from './styles'
import { liveTryOn, pictureMode, pictureModeView, videoMode, videoModeView } from '@utils/routes'
import { useConfig } from '@providers/configProvider'
import { trackClickMenuTryOn, trackClickMenuVideo, trackClickMenuPicture } from '@libs/analytics'
import { VirtualMirror } from '@luxottica/virtual-mirror'
import { CONTAINER_ID_LIVE_TRY_ON } from '@pages/LiveTryOn'
import { VtoCore } from '@luxottica/vto-core'
import { CONTAINER_ID_VIDEO_MODE } from '@components/VideoMode/VideoModeView'
import { CONTAINER_ID_PICTURE_MODE } from '@components/PictureMode/PictureModeView'
import { Config } from '@customTypes/config'
import { useDispatch, useSelector } from 'react-redux'
import { setVtoPage } from '@store/actions/ui'
import { AppState } from '@store/index'

export const closeVirtualMirror = async () => {
  try {
    await VirtualMirror.closeMirror({ target: CONTAINER_ID_LIVE_TRY_ON })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error closing virtual mirror: ${error}`)
  }
}

export const closeVto = (onClose: () => void, divId: string) => {
  VtoCore.closeRender({ divId }, onClose)
}

type Option = {
  translationKey: string
  logo: JSX.Element
  route: string
  analytic: () => void
  active: string
}

const getOptions = (config: Config): Option[] => {
  const {
    isTryOnEnabled,
    isPictureModeEnabled,
    isVideoModeEnabled,
    isTakePictureEnabled,
    isUploadPictureEnabled,
  } = config
  return [
    ...(isTryOnEnabled
      ? [
          {
            translationKey: 'general.live-try-on',
            logo: <NavBarActionScan width="1.5em" height="1.5em" />,
            route: liveTryOn(),
            analytic: trackClickMenuTryOn,
            active: liveTryOn(),
          },
        ]
      : []),
    ...(isPictureModeEnabled && (isTakePictureEnabled || isUploadPictureEnabled)
      ? [
          {
            translationKey: 'general.picture-mode',
            logo: <NavBarActionPicture width="1.5em" height="1.5em" />,
            route: pictureMode(),
            analytic: trackClickMenuPicture,
            active: pictureMode(pictureModeView()),
          },
        ]
      : []),
    ...(isVideoModeEnabled
      ? [
          {
            translationKey: 'general.video-mode',
            logo: <NavBarActionVideo width="1.5em" height="1.5em" />,
            route: videoMode(),
            analytic: trackClickMenuVideo,
            active: videoMode(videoModeView()),
          },
        ]
      : []),
  ]
}

export const MenuList: React.FC<{ visible: boolean }> = ({ visible }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const config = useConfig()

  const options = getOptions(config)
  const dispatch = useDispatch()
  const vtoPage = useSelector((s: AppState) => s.ui.vtoPage)

  const onUnmount = async (activeRoute: string, fn: () => void) => {
    dispatch(setVtoPage('off'))
    switch (activeRoute) {
      case liveTryOn():
        if (vtoPage === 'live-try-on') await closeVirtualMirror()

        fn()
        return
      case videoMode(videoModeView()):
        if (vtoPage === 'video-mode') closeVto(fn, CONTAINER_ID_VIDEO_MODE)
        else fn()
        return

      case pictureMode(pictureModeView()):
        if (vtoPage === 'picture-mode') closeVto(fn, CONTAINER_ID_PICTURE_MODE)
        else fn()
        return

      default:
        fn()
        return
    }
  }
  return (
    <MenuListWrapper className="vmmv-menu-list" visible={visible}>
      {options.map(o => {
        return (
          <MenuOption
            key={o.translationKey}
            onClick={() => {
              o.analytic()
              pathname !== o.active &&
                onUnmount(pathname, () => navigate(o.route, { state: { from: pathname } }))
            }}
            className="vmmv-menu-option"
          >
            <MenuLogo>{o.logo}</MenuLogo>
            <ListText active={pathname === o.active}>{t(o.translationKey)}</ListText>
          </MenuOption>
        )
      })}
    </MenuListWrapper>
  )
}
