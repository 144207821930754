import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const SunIcon: React.FC<IconProps> = ({
  width = pxToEm(30),
  height = pxToEm(30),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.25c-4.825 0-8.75 3.925-8.75 8.75s3.925 8.75 8.75 8.75 8.75-3.925 8.75-8.75S19.825 6.25 15 6.25zm0 16.25c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5zM15.625 0h-1.25v3.75h1.25V0zM25.165 3.952l-2.652 2.652.884.883 2.651-2.651-.883-.884zM30 14.375h-3.75v1.25H30v-1.25zM23.396 22.513l-.884.883 2.652 2.652.884-.884-2.652-2.651zM15.625 26.25h-1.25V30h1.25v-3.75zM6.603 22.512 3.95 25.164l.884.884 2.652-2.652-.884-.884zM3.75 14.375H0v1.25h3.75v-1.25zM4.836 3.952l-.884.884 2.651 2.652.884-.884-2.651-2.652z"
        fill={fill}
      />
    </svg>
  )
}
