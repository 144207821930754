import { IconProps } from '@customTypes/icons'
import { pxToEm } from '@libs/styled'
import React from 'react'

export const FocusIcon: React.FC<IconProps> = ({
  width = pxToEm(48),
  height = pxToEm(48),
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23.5" stroke="none"></circle>
      <path
        d="M36 23.5h-2.526a9.497 9.497 0 00-8.974-8.975V12h-1v2.525a9.497 9.497 0 00-8.975 8.975H12v1h2.525a9.497 9.497 0 008.975 8.974V36h1v-2.526a9.497 9.497 0 008.974-8.974H36v-1zm-11.5 8.974V30h-1v2.474a8.497 8.497 0 01-7.975-7.974H18v-1h-2.475a8.497 8.497 0 017.975-7.975V18h1v-2.475a8.497 8.497 0 017.974 7.975H30v1h2.474a8.497 8.497 0 01-7.974 7.974z"
        fill={fill}
      ></path>
    </svg>
  )
}
