import React from 'react'

export const CheckIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Livello_1"
      width="150px"
      height="150px"
      viewBox="0 0 120 120"
    >
      <polyline
        fill="none"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  )
}
