import { WishlistActionTypes, TOGGLE_WISHLIST, SET_WISHLIST } from '../actions/wishlist'

export interface WishlistState {
  wishlist: string[]
}

const initialState: WishlistState = {
  wishlist: [],
}

const wishlist = (state = initialState, action: WishlistActionTypes) => {
  switch (action.type) {
    case SET_WISHLIST: {
      return {
        ...state,
        wishlist: action.payload.wishlist,
      }
    }
    case TOGGLE_WISHLIST: {
      const wishlisted = state.wishlist.includes(action.payload.upc)

      if (!wishlisted) {
        return {
          ...state,
          wishlist: Array.from(new Set([...state.wishlist, action.payload.upc])),
        }
      }
      const newWishlist = state.wishlist.filter(upc => upc !== action.payload.upc)
      return {
        ...state,
        wishlist: newWishlist,
      }
    }
    default:
      return state
  }
}

export default wishlist
