import { Button } from '@components/core/Button'
import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const FooterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${pxToEm(20)};
  z-index: 1;

  ${({ theme }) => theme.media.large} {
    bottom: 0;
  }
`

export const FooterInner = styled.div`
  padding: ${pxToEm(5)};
  border-radius: ${pxToEm(50)};
  backdrop-filter: blur(pxToEm(4));
  background-color: ${({ theme }) => theme.components.footer?.wrapper.medium.backgroundColor};
  max-width: ${pxToEm(300)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  ${({ theme }) => theme.media.large} {
    padding: 0 ${pxToEm(24)};
    max-width: unset;
    border-radius: 0;
    background-color: ${({ theme }) => theme.components.footer?.wrapper.large.backgroundColor};
    height: ${pxToEm(80)};
  }
`

export const IconWrapper = styled.div`
  width: ${pxToEm(40)};
  height: ${pxToEm(40)};
  line-height: 0;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  background-color: ${({ theme }) => theme.components.footer?.icon.medium.backgroundColor};
  svg,
  path {
    fill: ${({ theme }) => theme.components.footer?.icon.medium.fill};
  }
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.medium} {
    width: ${pxToEm(48)};
    height: ${pxToEm(48)};
  }

  ${({ theme }) => theme.media.large} {
    background-color: ${({ theme }) => theme.components.footer?.icon.large.backgroundColor};
    svg,
    path {
      fill: ${({ theme }) => theme.components.footer?.icon.large.fill};
    }
  }
`

export const FooterLabelWrapper = styled.div<{ isAlignedLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: ${({ isAlignedLeft }) => (isAlignedLeft ? 'flex-start' : 'center')};
  padding-left: ${({ isAlignedLeft }) => isAlignedLeft && pxToEm(10)};
  ${({ theme }) => theme.media.large} {
    align-items: center;
    ${({ isAlignedLeft }) => isAlignedLeft && `margin-left: ${pxToEm(48)}`};
  }
`

export const FooterLabel = styled.span`
  font: ${({ theme }) => theme.fonts.span};
  color: ${({ theme }) => theme.components.footer?.wrapper.medium.color};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${pxToEm(230)};

  ${({ theme }) => theme.media.medium} {
    max-width: ${pxToEm(260)};
    text-overflow: none;
  }

  ${({ theme }) => theme.media.large} {
    color: ${({ theme }) => theme.components.footer?.wrapper.large.color};
  }
`

export const ProductName = styled(FooterLabel)`
  font: ${({ theme }) => theme.fonts.spanBold};
`

export const PriceStyled = styled.span<{ cross?: boolean }>`
  text-decoration: ${({ cross }) => (cross ? 'line-through' : 'none')};
  font: ${({ theme }) => theme.fonts.paragraphSmall};
  color: ${({ theme }) => theme.components.footer?.wrapper.medium.color};

  ${({ theme }) => theme.media.large} {
    color: ${({ theme }) => theme.components.footer?.wrapper.large.color};
  }
`

export const AddToBagButton = styled(Button)`
  background: ${({ theme }) => theme.components.footer?.icon.large.backgroundColor};
  color: ${({ theme }) => theme.components.footer?.icon.large.fill};
  width: unset;
  height: unset;
  padding: ${pxToEm(16)};
  margin: 0;
`
