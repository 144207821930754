import React from 'react'
import { pxToEm } from '@libs/styled'
import styled from 'styled-components'
import { theme } from '../../theme'

type ButtonProps = {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disable?: boolean
  color?: string
  type?: 'button' | 'submit' | 'reset'
  children: React.ReactNode
}

const StyledButton = styled('button')<{ color?: string }>`
  margin: 0 ${pxToEm(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToEm(56)};
  border: 1px solid transparent;
  font: ${({ theme }) => theme.fonts.span};
  color: ${({ color }) => (color ? color : theme.palette.white[100])};
  line-height: normal;
  text-align: inherit;
  background: linear-gradient(to right, ${theme.palette.white[70]}, ${theme.palette.white[40]});
  outline: none;
  cursor: pointer;
  border-radius: ${pxToEm(100)};
`

export const Button: React.FC<ButtonProps> = ({ className, disable, children, type, ...props }) => (
  <StyledButton type={type} className={className} {...props} disabled={disable}>
    {children}
  </StyledButton>
)
