import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html {
    --vm-components-footer-desktop-bg: rgb(255 255 255);
    --vm-components-footer-desktop-text: rgba(0, 0, 0);

    --vm-components-footer-mobile-bg: rgba(0, 0, 0, 0.3);
    --vm-components-footer-mobile-text: rgb(255 255 255);

    --vm-default-font: HelveticaNeue;
    --vm-default-font-bold: HelveticaNeue-Bold;
    --vm-default-font-medium: HelveticaNeue-Medium;

    --vm-components-icon-mobile-bg: rgba(0, 0, 0);
    --vm-components-icon-mobile-fill: rgb(255 255 255);

    --vm-components-icon-desktop-bg: #000 ;
    --vm-components-icon-desktop-fill: rgb(255 255 255);

    --vm-components-action-photo-bg: rgb(255 255 255);
    --vm-components-action-photo-fill: rgba(0, 0, 0);

    --vm-components-action-video-bg: rgba(0, 0, 0);
    --vm-components-action-video-text: rgb(255 255 255);
  }
`

export const device = {
  large: '1224px',
  medium: '768px',
  small: '376px',
}

export const theme = {
  palette: {
    lightgray: '#cdcdcd',
    gray: {
      100: 'rgb(148, 148, 148)',
      70: 'rgba(148, 148, 148, .7)',
      50: 'rgba(148, 148, 148, .5)',
      40: 'rgba(148, 148, 148, .4)',
    },
    white: {
      100: 'rgb(255 255 255)',
      90: 'rgba(255, 255, 255, 0.89)',
      70: 'rgba(255, 255, 255, 0.7)',
      50: 'rgba(255, 255, 255, 0.55)',
      40: 'rgba(255, 255, 255, 0.4)',
    },
    black: '#000',
    pinkRed: '#e84159',
    blueberry: '#8f73ff',
    whiteSmoke: '#f6f6f6',
    dodgerblue: 'dodgerblue',
    abbey: '#515c62',
    darkGray: '#a4a4a4',
    silver: '#D0D0D0',
    error: '#c83430',
    primary: {
      main: 'var(--vm-primary-main)',
      light: 'var(--vm-primary-light)',
      text: 'var(--vm-primary-text)',
    },
    secondary: {
      main: 'var(--vm-secondary-main)',
    },
  },
  media: {
    large: `@media only screen and (min-width: ${device.large})`,
    medium: `@media only screen and (min-width: ${device.medium})`,
    small: `@media only screen and (min-width: ${device.small})`,
  },
  fonts: {
    title: 'bold normal 28px/normal var(--vm-default-font)',
    titleSmall: 'bold normal 20px/normal var(--vm-default-font)',
    heading: 'normal normal 500 32px/normal var(--vm-default-font-bold), sans-serif',
    headingSmall: 'normal normal 500 22px/normal var(--vm-default-font), sans-serif',
    headingMediumBold: 'normal normal bold 24px/normal var(--vm-default-font), sans-serif',
    paragraph: 'normal normal 200 16px/normal var(--vm-default-font), sans-serif',
    paragraphBold: 'normal normal bold 16px/normal var(--vm-default-font-bold), sans-serif',
    paragraphSmall: 'normal normal 200 12px/normal var(--vm-default-font), sans-serif',
    spanBold: 'normal normal bold 14px/normal var(--vm-default-font-bold), sans-serif',
    span: 'normal normal 200 14px/normal var(--vm-default-font), sans-serif',
    buttonText: 'normal normal 200 12px/18px var(--vm-default-font-medium), sans-serif',
    buttonTextLink: 'normal normal 200 12px/16px var(--vm-default-font), sans-serif;',
    qrCodeTitle: 'normal normal bold 20px / normal var(--vm-default-font);',
  },
  typography: {},
  components: {
    footer: {
      wrapper: {
        large: {
          backgroundColor: 'var(--vm-components-footer-desktop-bg)',
          color: 'var(--vm-components-footer-desktop-text)',
        },
        medium: {
          backgroundColor: 'var(--vm-components-footer-mobile-bg)',
          color: 'var(--vm-components-footer-mobile-text)',
        },
      },
      icon: {
        large: {
          backgroundColor: 'var(--vm-components-icon-desktop-bg)',
          fill: 'var(--vm-components-icon-desktop-fill)',
        },
        medium: {
          backgroundColor: 'var(--vm-components-icon-mobile-bg)',
          fill: 'var(--vm-components-icon-mobile-fill)',
        },
      },
    },
    actionPhoto: {
      backgroundColor: 'var(--vm-components-action-photo-bg)',
      fill: 'var(--vm-components-action-photo-fill)',
    },
    actionVideo: {
      backgroundColor: 'var(--vm-components-action-video-bg)',
      color: 'var(--vm-components-action-video-text)',
    },
  },
}
