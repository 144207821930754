import { IconProps } from '@customTypes/icons'
import React from 'react'
export const NavBarActionVideo: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = '#fff',
  stroke = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.907 15.585c.162 0 .329-.04.484-.13l6.52-3.74a.806.806 0 0 0 0-1.427l-6.52-3.74a.967.967 0 0 0-.484-.13c-.478 0-.919.35-.919.843v7.481c0 .493.441.843.919.843zm-.002-8.25h.002a.05.05 0 0 1 .027.007l6.378 3.66-6.378 3.658a.05.05 0 0 1-.027.008h-.002V7.335z"
        fill={fill}
      />
      <path
        d="M11 0C4.935 0 0 4.935 0 11s4.935 11 11 11 11-4.935 11-11S17.065 0 11 0zm0 21.083C5.44 21.083.917 16.56.917 11 .917 5.44 5.44.917 11 .917 16.56.917 21.083 5.44 21.083 11c0 5.56-4.523 10.083-10.083 10.083z"
        fill={fill}
      />
    </svg>
  )
}
