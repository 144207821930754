import { LightIcon, LightLessIcon } from '@components/core/Icons'
import { useAnimation } from '@hooks/useAnimationApp'
import { useInterval } from '@hooks/useInterval'
import { trackClickBrightnessBar } from '@libs/analytics'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Range, Direction } from 'react-range'
import {
  Wrapper,
  SliderWrapper,
  RangeThumbStyled,
  RangeTrackStyled,
  RangeTrackSelectedStyled,
  LabelStyled,
} from './styles'

interface LensActivationProps {
  onChangeLensOpacity: (opacity: number) => void
  opacity: number
  isEvolve: boolean
}

const MIN_VALUE = 0
const MAX_VALUE = 1
const STEP_VALUE = 0.1

const Label: React.FC = () => {
  const labelRef = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    setTimeout(() => {
      if (labelRef.current?.style) {
        labelRef.current.style.opacity = '0'
      }
    }, 3000)
  }, [])

  const { t } = useTranslation()
  return (
    <LabelStyled className="vmmv-lens-activation-label" ref={labelRef}>
      {t('transitions.change-brightness')}
    </LabelStyled>
  )
}

const LensActivationWithoutAnimation: React.FC<LensActivationProps> = ({
  opacity,
  onChangeLensOpacity,
  isEvolve,
}) => {
  const changeLensOpacity = useCallback(
    (numbers: number[]) => {
      onChangeLensOpacity(numbers[0])
      trackClickBrightnessBar()
    },
    [onChangeLensOpacity]
  )

  return (
    <Wrapper className="vmmv-lens-activation-wrapper">
      <Label />
      <SliderWrapper isEvolve={isEvolve}>
        <LightIcon width="2.7em" height="2.7em" />
        <Range
          direction={Direction.Up}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={STEP_VALUE}
          values={[opacity]}
          onChange={changeLensOpacity}
          renderTrack={({ props, children }) => (
            <RangeTrackStyled
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={props.style}
            >
              <RangeTrackSelectedStyled ref={props.ref}>{children}</RangeTrackSelectedStyled>
            </RangeTrackStyled>
          )}
          renderThumb={({ props }) => <RangeThumbStyled {...props} />}
        />
        <LightLessIcon width="2.7em" height="2.7em" />
      </SliderWrapper>
    </Wrapper>
  )
}

// Due to requirements animation time should be 4s.
// Change 40 times per 4s, so every 100ms should execute 1 action
// Change opacity from 1->0 and 0->1
// Step = 1 / 40 = 0.025
const OPACITY_STEP = 0.025
const INTERVAL = 100

export const LensActivation: React.FC<LensActivationProps> = props => {
  const { opacity, onChangeLensOpacity } = props
  const [animationName, setAnimationName] = useState<'increase' | 'decrease' | null>(null)

  const decreaseOpacity = useCallback(() => {
    setAnimationName('decrease')
  }, [])

  const onEnd = useCallback(() => {
    setAnimationName(null)
  }, [])

  const increaseOpacity = useCallback(() => {
    setAnimationName('increase')
  }, [])

  useAnimation(opacity === 1, opacity === 0, decreaseOpacity, onEnd, 'decreaseOpacity', 0)
  useAnimation(opacity === 0, opacity === 1, increaseOpacity, onEnd, 'increaseOpacity', 0)

  useInterval(() => {
    if (opacity === 0 || animationName !== 'decrease') return
    const nextOpacity = opacity - OPACITY_STEP >= 0 ? opacity - OPACITY_STEP : 0
    onChangeLensOpacity(nextOpacity)
  }, INTERVAL)

  useInterval(() => {
    if (opacity === 1 || animationName !== 'increase') return
    const nextOpacity = opacity + OPACITY_STEP <= 1 ? opacity + OPACITY_STEP : 1
    onChangeLensOpacity(nextOpacity)
  }, INTERVAL)

  return <LensActivationWithoutAnimation {...props} />
}
