import { IconProps } from '@customTypes/icons'
import React from 'react'

export const CloseScreenshotIcon: React.FC<IconProps> = ({
  width = '1em',
  height = '1em',
  fill = '#fff',
  stroke = '#fff',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M51.414 5.664L50 4.25L27.707 26.543L5.414 4.25L4 5.664L26.293 27.957L4 50.25L5.414 51.664L27.707 29.371L50 51.664L51.414 50.25L29.121 27.957L51.414 5.664Z"
        fill={fill}
      />
    </svg>
  )
}
